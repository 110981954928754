import React, { FunctionComponent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@shared/components/Button';
import Typography from '@shared/components/Typography';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import SelectField from '@shared/components/SelectField';
import Preloader from '@shared/components/Preloader';
import MessageDialog from '@shared/components/MessageDialog';
import CheckboxField from '@shared/components/CheckboxField';
import ControlButtons from '@shared/components/ControlButtons';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FilterIcon,
  EqualityIcon,
  NotEqualityIcon,
  InstructionIcon,
  AlertCircleIcon,
  XIcon,
} from '@shared/assets/images/icons';
import Accordion from '@components/Accordion';
import Flex from '@shared/components/Flex';
import { useLazyQuery } from '@apollo/client';
import { EMPLOYEES_QUERY } from '@/client/queries';
import clsx from 'clsx';
import { IntegrationStatus } from '@components/typings/interfaces/integrations';
import { useIntegrationFormStyles } from '../IntegrationForm.styles';
import {
  comparedEmployeeListType,
  crmEmployeeListType,
  compareStatus,
  employeeListType,
  ICRMIntegrationEmployeesProps,
  IEmployeeCompareOptions,
} from '../IntegrationForm.interfaces';
import {
  ALL_EMPLOYEE_FILTERS_ON,
  compareEmployeeFilterList,
  getComparedEmployeeIndex,
} from '../IntegrationForm.constants';
import { useCRMEmployee } from '../hooks';

export const CRMEmployees: FunctionComponent<ICRMIntegrationEmployeesProps> = ({
  APIName,
  integrationId,
  rawComparedEmployee,
  onChange,
  employeesError,
  integrationStatus = IntegrationStatus.Draft,
}) => {
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const formMethods = useForm();
  const { watch, setValue } = formMethods;
  const selectedEmployeeValues = watch('employee');
  const selectAll = watch('selectAll');
  const isCompared = watch(compareStatus.Compared);
  const isNotCompared = watch(compareStatus.NotCompare);
  const isNotSelected = watch(compareStatus.NotSelected);
  const filterOption = useMemo(
    () => [isCompared, isNotCompared, isNotSelected],
    [isCompared, isNotCompared, isNotSelected]
  );
  const [filterOptions, setFilterOptions] = useState<IEmployeeCompareOptions>({
    isOpen: false,
    isFilterOn: false,
    filter: ALL_EMPLOYEE_FILTERS_ON,
  });
  // TODO handle lazy query error
  const [getEmployeeList, { data: employeeData }] = useLazyQuery(EMPLOYEES_QUERY);
  const {
    integrationEmployee,
    error: integrationEmployeeError,
    refetch: getCRMEmployeeList,
  } = useCRMEmployee(APIName, integrationId);

  const employeeList = useMemo(() => {
    if (employeeData) {
      const list =
        employeeData?.employees.map((i: employeeListType) => ({ ...i.user, ext: i.ext })) || [];
      return [{ name: translate('DO_NOT_COMPARE'), id: '-1' }, ...list];
    }
    return null;
  }, [employeeData, translate]);

  const updateIntegrationEmployeeList = useCallback(() => {
    getCRMEmployeeList();
    getEmployeeList();
  }, [getCRMEmployeeList, getEmployeeList]);

  useEffect(() => {
    if (filterOptions.isOpen) {
      setTimeout(() => {
        filterOptions.filter.forEach((filter) => {
          if (filter !== '') {
            setValue(filter, true);
          }
        });
      });
    }
  }, [filterOptions, setValue]);

  useEffect(() => {
    if (filterOption) {
      let isAllSelected = true;
      filterOption.forEach((filter: boolean) => {
        if (!filter) {
          isAllSelected = false;
        }
      });
      if (isAllSelected && !selectAll) {
        setValue('selectAll', true);
      }
      if (!isAllSelected && selectAll) {
        setValue('selectAll', false);
      }
    }
  }, [filterOption, setValue, selectAll]);

  useEffect(() => {
    if (integrationId && !integrationEmployee) {
      updateIntegrationEmployeeList();
    }
  }, [integrationEmployee, integrationId, updateIntegrationEmployeeList]);

  useEffect(() => {
    if (onChange && integrationEmployee && selectedEmployeeValues && employeeList) {
      const comparedEmployeeList = selectedEmployeeValues.reduce(
        (result: comparedEmployeeListType, ci: string, ind: number) => {
          if (ci === '') {
            return result;
          }
          const { name, ext } = employeeList.find((emp) => Number(emp.id) === Number(ci)) || {};
          if (!ext) {
            const prevNotMatch = (result.notmatch as Array<string>) || [];
            return {
              ...result,
              notmatch: [...prevNotMatch, integrationEmployee[ind].id],
            };
          }
          return {
            ...result,
            [ext]: {
              id: ci,
              crmId: integrationEmployee[ind].id,
              ext,
              name,
            },
          };
        },
        {}
      );
      onChange(comparedEmployeeList, integrationEmployee);
    }
  }, [selectedEmployeeValues, integrationEmployee, onChange, employeeList, translate]);

  useEffect(() => {
    if (!integrationEmployee || !employeeList) {
      return;
    }
    if (!selectedEmployeeValues) {
      if (rawComparedEmployee) {
        setTimeout(() => {
          const aicallEmployees = Object.keys(rawComparedEmployee);
          const integrationEmployees = Object.values(rawComparedEmployee);
          const comparedValues: Array<string | number> = [];
          integrationEmployees.forEach((integrationUser, index) => {
            if (Array.isArray(integrationUser)) {
              integrationUser.forEach((rawEmployee) => {
                const emIndex = getComparedEmployeeIndex(integrationEmployee, rawEmployee);
                comparedValues[emIndex] = '-1';
              });
            } else {
              const emIndex = getComparedEmployeeIndex(integrationEmployee, integrationUser);
              const { id: emValue } =
                employeeList.find((rEm) => rEm.ext === aicallEmployees[index]) || {};
              comparedValues[emIndex] = emValue || '';
            }
          });
          setValue('employee', comparedValues);
        });
      } else {
        // use auto compare only first time
        setTimeout(() => {
          const comparedValues: Array<string | number> = [];
          integrationEmployee.forEach((user: crmEmployeeListType, index: number) => {
            const isEqualEmployee = employeeList.find((employee) => {
              const { name, email } = user;
              return employee.email === email || employee.name === name;
            });
            if (isEqualEmployee) {
              comparedValues[index] = isEqualEmployee.id;
            }
          });
          if (comparedValues.length > 0) {
            setValue('employee', comparedValues);
          }
        });
      }
    }
  }, [rawComparedEmployee, setValue, integrationEmployee, employeeList, selectedEmployeeValues]);

  const handleConfirmFilterOptions = useCallback(() => {
    setFilterOptions({
      isOpen: false,
      isFilterOn: !selectAll,
      filter: !selectAll
        ? filterOption.map((filter: boolean, index: number) =>
            filter ? compareEmployeeFilterList[index].value : ''
          )
        : ALL_EMPLOYEE_FILTERS_ON,
    });
  }, [filterOption, selectAll]);

  function handleFilterCheckAll() {
    setValue(compareStatus.Compared, !selectAll);
    setValue(compareStatus.NotCompare, !selectAll);
    setValue(compareStatus.NotSelected, !selectAll);
  }

  function handleUpdateEmployeeList() {
    updateIntegrationEmployeeList();
  }

  function handleOpenFilter() {
    setFilterOptions((prevState) => ({
      ...prevState,
      isOpen: true,
    }));
  }

  function handleCloseFilter() {
    setFilterOptions({
      isOpen: false,
      isFilterOn: false,
      filter: ALL_EMPLOYEE_FILTERS_ON,
    });
  }

  const renderAccordionSummary = () => {
    const filteredEmployees = selectedEmployeeValues?.filter(
      (employee: number | string) => !!employee
    );
    const isHasNotCompare =
      !integrationEmployeeError && selectedEmployeeValues?.length !== filteredEmployees?.length;
    return (
      <Flex
        className={classes.CRMEmployeeSummaryHeading}
        fullWidth
        alignItems={'center'}
        justifyContent={'spaceBetween'}
      >
        <Typography type={'text3'} color={'tertiary900'}>
          {translate('USERS')}
        </Typography>
        {(employeesError || isHasNotCompare) && (
          <Typography type={'text4'} color={employeesError ? 'danger600' : 'warning600'}>
            {translate('HAS_NOT_COMPARE_EMPLOYEE')}
          </Typography>
        )}
      </Flex>
    );
  };

  const renderEmployeeHeader = () => {
    if (integrationEmployee && employeeList) {
      return (
        <Flex justifyContent={'spaceBetween'} alignItems={'center'}>
          <div className={classes.CRMEmployeeList}>
            <Typography bold color={'tertiary900'} type={'text3'}>
              {translate('CRM_MANAGERS')}
            </Typography>
          </div>
          <div>
            {filterOptions.isFilterOn ? (
              <Button
                onClick={() => handleCloseFilter()}
                className={classes.CRMEmployeeFilterButton}
                clear
              >
                <XIcon />
              </Button>
            ) : null}
            <Button
              onClick={() => handleOpenFilter()}
              className={classes.CRMEmployeeFilterButton}
              clear
            >
              <FilterIcon
                className={filterOptions.isFilterOn ? classes.selectedFilterButton : ''}
              />
            </Button>
          </div>
          <Flex justifyContent={'spaceBetween'} className={classes.CRMEmployeeList}>
            <Typography bold color={'tertiary900'} type={'text3'}>
              {translate('CRM_EMPLOYEES')}
            </Typography>
          </Flex>
        </Flex>
      );
    }
    return null;
  };

  const equalIcon = (status: string) => {
    if (selectedEmployeeValues) {
      if (status === compareStatus.NotCompare) {
        return <NotEqualityIcon className={classes.CRMEmployeeEqualIcon} />;
      }
      if (status === compareStatus.Compared) {
        return <EqualityIcon className={classes.CRMEmployeeEqualIcon} />;
      }
    }
    return <InstructionIcon className={classes.CRMEmployeeInstructionIcon} />;
  };

  const renderComparisonList = () => {
    if (integrationEmployee && employeeList) {
      const getStatus = (valueIndex: number) => {
        if (selectedEmployeeValues) {
          if (selectedEmployeeValues[valueIndex] === '-1') {
            return compareStatus.NotCompare;
          }
          if (
            selectedEmployeeValues[valueIndex] !== '' &&
            selectedEmployeeValues[valueIndex] !== undefined
          ) {
            return compareStatus.Compared;
          }
        }
        return compareStatus.NotSelected;
      };

      return integrationEmployee.map((item: crmEmployeeListType, index: number) => {
        const { name: employeeName } = item || {};
        const status = getStatus(index);
        const isItemFiltered = filterOptions.filter.includes(status);

        return (
          <Flex
            className={!isItemFiltered ? classes.CRMEmployeeListHide : ''}
            key={`employee-${index}`}
            alignItems={'center'}
            justifyContent={'spaceBetween'}
          >
            <div className={classes.CRMEmployeeList}>
              <FormFieldRhfUncontrolled
                name={`employeeBitrix[${index}]`}
                InputProps={{ readOnly: true }}
                value={employeeName}
              />
            </div>
            <div className={classes.CRMEmployeeIcon}>{equalIcon(status)}</div>
            <div className={classes.CRMEmployeeList}>
              <SelectField
                name={`employee[${index}]`}
                valueKey={'id'}
                titleKey={'name'}
                defaultValue={''}
                disabledOptions={
                  selectedEmployeeValues
                    ? selectedEmployeeValues.filter((i: string | number) => i !== '-1' && i !== '')
                    : []
                }
                optionsClassName={classes.CRMEmployeesList}
                data={employeeList}
              />
            </div>
          </Flex>
        );
      });
    }
    if (integrationEmployeeError) {
      const errorMessage =
        integrationEmployeeError.graphQLErrors[0]?.extensions?.exception.errorDescription;
      let message = 'SOMETHING_WENT_WRONG';
      if (errorMessage === 'REST is available only on commercial plans.') {
        message = 'BITRIX_IS_NOT_COMMERCIAL';
      }

      return (
        <Flex justifyContent={'center'} alignItems={'center'} className={classes.CRMEmployeeError}>
          <AlertCircleIcon className={classes.CRMEmployeeAlertIcon} />
          <Typography type={'text3'} color={'inherit'}>
            {translate(message)}
          </Typography>
        </Flex>
      );
    }
    return (
      <Flex justifyContent={'center'}>
        <Preloader />
      </Flex>
    );
  };

  const renderFilterContent = () => (
    <>
      <div className={classes.integrationSettingFilterAll}>
        <CheckboxField
          indeterminate={
            filterOption && filterOption.filter((f: boolean) => f).length !== 0 && !selectAll
          }
          name={'selectAll'}
          label={translate('SELECT_ALL')}
          onChange={handleFilterCheckAll}
        />
      </div>
      {compareEmployeeFilterList.map((filter, filterIndex) => (
        <Flex
          key={`filter-${filterIndex}`}
          className={classes.integrationSettingFilter}
          justifyContent={'spaceBetween'}
          alignItems={'center'}
        >
          <CheckboxField name={filter.value} label={translate(filter.title)} />
          {equalIcon(filter.value)}
        </Flex>
      ))}
      <div className={classes.integrationSettingFilterButtons}>
        <ControlButtons
          confirmTitle={'APPLY'}
          cancelTitle={'CANCEL'}
          cancelVariant="secondary"
          justifyContent={'start'}
          flexDirection={'row-reverse'}
          onConfirmClick={handleConfirmFilterOptions}
          onCancelClick={handleCloseFilter}
          small
        />
      </div>
    </>
  );

  return (
    <FormProvider {...formMethods}>
      <Accordion
        renderSummary={renderAccordionSummary()}
        className={clsx({ [classes.CRMEmployeesError]: employeesError })}
        defaultExpand={integrationStatus === IntegrationStatus.Draft}
        forceExpand={!!integrationEmployeeError?.graphQLErrors?.length}
      >
        <>
          <div className={classes.CRMEmployeesUpdateListWrapper}>
            <Button
              className={classes.CRMEmployeesUpdateList}
              onClick={() => handleUpdateEmployeeList()}
              clear
            >
              <Typography underline color={'link500'} type={'text3'}>
                {translate('UPDATE_LIST')}
              </Typography>
            </Button>
          </div>
          <div className={classes.CRMEmployeesWrapper}>
            {renderEmployeeHeader()}
            {renderComparisonList()}
          </div>
        </>
      </Accordion>
      <MessageDialog
        isOpen={filterOptions.isOpen}
        contentClass={classes.defaultElementWidth21}
        title={translate('FILTER_BY')}
        onCancel={() => handleCloseFilter()}
        renderContent={renderFilterContent()}
      />
    </FormProvider>
  );
};

export default memo(CRMEmployees);
