import React, { FunctionComponent, useEffect } from 'react';
import Translate from '@shared/components/Translate';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { DEPARTMENT_QUERY, EMPLOYEE_QUERY } from '@/client/queries';
import { waitingTimeOptions as WTO } from '@components/utils';
import { IConfirmData } from '../../IntegrationForm.interfaces';

export const ScenarioResponsibleLine: FunctionComponent<{
  responsibleData: IConfirmData | null;
  size?: 'text4' | 'text3';
  color?: 'tertiary600' | 'tertiary900';
}> = ({ responsibleData, size = 'text4', color = 'tertiary600' }) => {
  const [translate] = useTranslation();
  // TODO handle lazy query error
  const [getEmployeeName, { data: { employee: { user: redirectEmployee = null } = {} } = {} }] =
    useLazyQuery(EMPLOYEE_QUERY);
  // TODO handle lazy query error
  const [getDepartmentName, { data: { department: redirectDepartment = null } = {} }] =
    useLazyQuery(DEPARTMENT_QUERY);

  const employeeName = redirectEmployee?.name || null;
  const departmentName = redirectDepartment?.name || null;

  useEffect(() => {
    if (responsibleData) {
      const { next, nextId } = responsibleData;
      const optionVariable = { variables: { id: nextId } };
      if (next === 'EMPLOYEE') {
        getEmployeeName(optionVariable);
      }
      if (next === 'DEPARTMENT') {
        getDepartmentName(optionVariable);
      }
    }
  }, [responsibleData, getEmployeeName, getDepartmentName]);

  if (!responsibleData) return null;

  const getRedirectMessage = (next: string) => {
    if (next === 'EMPLOYEE' && employeeName) {
      return `${translate('TO_EMPLOYEE').toLowerCase()} ${employeeName}`;
    }
    if (next === 'DEPARTMENT' && departmentName) {
      return `${departmentName}`;
    }
    return translate('TO_SCRIPTED_NUMBER');
  };

  const timeTitle = WTO.filter((i) => i.key === responsibleData?.dialTimeout)[0].timeValue;
  const redirectText = getRedirectMessage(responsibleData.next);
  const prefix = responsibleData.next !== 'DEFAULT' ? translate('TO_PREFIX') : undefined;

  return (
    <Translate
      i18nKey={'RESPONSIBLE_INFORMATION'}
      values={{
        waitTime: translate(timeTitle),
        redirect: redirectText,
        toPrefix: prefix,
      }}
      components={{
        t: <Typography type={size} color={color} />,
        s: <Typography bold type={size} color={'primary700'} />,
      }}
    />
  );
};

export default ScenarioResponsibleLine;
