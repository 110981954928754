import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IOutgoingCall } from '@components/typings/interfaces';
import Typography from '@shared/components/Typography';
import { useOutgoingCallsStyles } from '@/features/OutgoingCalls/OutgoingCalls.styles';
import { pluralForm } from '@components/utils/helpers';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';

export const OutgoingCallNameCell: FunctionComponent<ICellProps<IOutgoingCall>> = ({ item }) => {
  const { department, employee } = item || {};
  const classes = useOutgoingCallsStyles();
  const [translate] = useTranslation();
  const name = employee?.user?.name || department?.name || '';
  const employeesCount = department?.employees?.length || 0;
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;

  const description = () => {
    if (department) {
      return translate(pluralForm(employeesCount, 'COUNT_EMPLOYEES_IN_DEPARTMENT'), {
        count: employeesCount,
      });
    }
    return '';
  };

  return (
    <div className={clsx(classes.defaultElementWidthFull, classes.rowContent)}>
      <HighlightedText type={'text'} text={name} query={searchText} />
      <Typography type={'text4'} color={'tertiary600'} className={classes.secondaryText}>
        {description()}
      </Typography>
    </div>
  );
};
