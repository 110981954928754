import React, { ChangeEvent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { DEPARTMENTS_QUERY, EMPLOYEES_QUERY } from '@/client/queries';
import { Card } from '@material-ui/core';
import PhoneField from '@shared/components/PhoneField';
import CheckboxField from '@shared/components/CheckboxField';
import { useFormErrors } from '@/common/hooks/formErrors.hooks';
import SelectField from '@shared/components/SelectField';
import CallDistributionFields from '@components/CallDistribution';
import { normalizePhone } from '@shared/utils';
import SwitchField from '@shared/components/SwitchField';
import Typography from '@shared/components/Typography';
import Translate from '@shared/components/Translate';
import { CallDistributionTypes, deserializeDepartment } from '@components/typings/interfaces';
import ComboBoxField from '@shared/components/ComboBoxField';
import Flex from '@shared/components/Flex';
import clsx from 'clsx';
import HighlightedText from '@components/HighlightedText';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import MessageFields from '@/components/MessageFields/MessageFields';
import { ScenariosTypes } from '../../IncomingNumber.interfaces';
import { useRedirectScenariosStyles } from './RedirectScenarios.styles';
import {
  callDistributionTimeoutOptions,
  defaultMessages,
  redirectDepartmentTimeoutOptions,
  redirectEmployeeTimeoutOptions,
  redirectOptions,
} from '../../IncomingNumber.constants';
import { IRedirectScenarioProps, IRedirectSettingsFormData } from './RedirectScenarios.interfaces';

export const RedirectScenarios = ({
  onSave,
  settings,
  onCancel,
  initial,
  onChanges,
  setIsSaveButtonLoading,
}: IRedirectScenarioProps) => {
  const classes = useRedirectScenariosStyles();
  const [translate] = useTranslation();

  const { data: { employees = [] } = { employees: [] } } = useQuery<{
    employees: { id: string | number; ext: string; user?: { name: string } }[];
  }>(EMPLOYEES_QUERY, { fetchPolicy: 'no-cache' });

  const { data: { departments = [] } = { departments: [] } } = useQuery<{
    departments: { id: number; name: string; ext: string }[];
  }>(DEPARTMENTS_QUERY, { fetchPolicy: 'no-cache' });

  const handleChanges = (e: ChangeEvent<HTMLInputElement>, changeType?: boolean) => {
    onChanges?.(changeType ? (e.target.value as ScenariosTypes) : undefined);
  };

  const formMethods = useForm<IRedirectSettingsFormData>({
    defaultValues: {
      ...(settings || {}),
      type: initial || settings?.type || ScenariosTypes.RedirectToNumber,
      message: settings?.id
        ? settings?.message
        : translate(
            defaultMessages.get(initial || settings?.type || ScenariosTypes.RedirectToNumber) || '',
            ''
          ),
      messageType: settings?.messageType || 'TEXT',
      isMessageActive: Boolean(settings?.isMessageActive),
      fileName: settings?.fileName,
      fileUrl: settings?.fileUrl,
      fileHash: settings?.fileHash,
      externalNumber: settings?.externalNumber,
      redirectTimeout: settings?.redirectTimeout,
      redirectEmployee: settings?.redirectEmployee,
      redirectDepartment: settings?.redirectDepartment,
      notifyPosition: settings?.notifyPosition,
      notifyTime: settings?.notifyTime,
      callDistributionType: settings?.callDistributionType || CallDistributionTypes.Multicall,
      timeout: settings?.timeout,
    },
  });

  const { handleSubmit, watch, clearErrors, getValues, setValue } = formMethods;
  const type = watch('type');
  const isMessageActive = watch('isMessageActive');
  const redirectDepartment = watch('redirectDepartment');

  useFormErrors(undefined, formMethods);

  useEffect(() => {
    if (!isMessageActive) {
      clearErrors('message');
      clearErrors('fileHash');
    }
  }, [isMessageActive, clearErrors]);

  const handleFormSubmit = (data: IRedirectSettingsFormData) => {
    const redirectTimeoutNumber = Number(data?.redirectTimeout);
    const redirectEmployeeNumber = Number(data?.redirectEmployee);
    const redirectDepartmentNumber = Number(data?.redirectDepartment);
    const timeoutNumber = Number(data?.timeout);
    onSave({
      ...data,
      externalNumber: data?.externalNumber
        ? normalizePhone(data?.externalNumber)
        : data?.externalNumber,
      fileUrl: undefined,
      redirectTimeout: Number.isFinite(redirectTimeoutNumber) ? redirectTimeoutNumber : 10 || 15,
      redirectEmployee: Number.isFinite(redirectEmployeeNumber)
        ? redirectEmployeeNumber
        : undefined,
      redirectDepartment: Number.isFinite(redirectDepartmentNumber)
        ? redirectDepartmentNumber
        : undefined,
      timeout: Number.isFinite(timeoutNumber) ? timeoutNumber : 0,
      notifyPosition: data.notifyPosition,
      notifyTime: data.notifyTime,
    });
    onCancel();
  };

  useEffect(() => {
    setValue('redirectDepartment', settings.redirectDepartment);
    setValue('redirectEmployee', settings.redirectEmployee);
    const timeout = getValues('redirectTimeout');
    const options =
      type === ScenariosTypes.RedirectToDepartment
        ? redirectDepartmentTimeoutOptions
        : redirectEmployeeTimeoutOptions;
    if (!options.find((o) => o.value === timeout)) {
      setValue('redirectTimeout', options[0].value);
    } else {
      setValue('redirectTimeout', timeout);
    }
  }, [getValues, setValue, settings.redirectDepartment, settings.redirectEmployee, type]);

  const renderRedirectToEmployeeOptions = (
    options: {
      ext: string;
      name: string;
    },
    { inputValue }: AutocompleteRenderOptionState
  ) => (
    <Flex className={classes.redirectCustomLine} justifyContent={'spaceBetween'}>
      <Typography
        className={clsx(classes.redirectTitle, classes.textOverflow)}
        color={'tertiary900'}
        type={'text3'}
      >
        {options.name}
      </Typography>
      <div className={classes.redirectExt}>
        <HighlightedText
          type={'text'}
          text={options.ext}
          query={inputValue}
          typographyType={'text3'}
          typographyColor={'tertiary900'}
        />
      </div>
    </Flex>
  );

  const renderRedirectToDepartmentOptions = (
    options: {
      ext: string;
      name: string;
    },
    { inputValue }: AutocompleteRenderOptionState
  ) => (
    <Flex className={classes.redirectCustomLine} justifyContent={'spaceBetween'}>
      <Typography
        className={clsx(classes.redirectTitle, classes.textOverflow)}
        color={'tertiary900'}
        type={'text3'}
      >
        {options.name}
      </Typography>
      <div className={classes.redirectExt}>
        <HighlightedText
          type={'text'}
          text={options.ext}
          query={inputValue}
          typographyType={'text3'}
          typographyColor={'tertiary900'}
        />
      </div>
    </Flex>
  );

  const renderControls = () => {
    switch (type) {
      case ScenariosTypes.RedirectToNumber: {
        return (
          <>
            <div className={classes.formContent}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('NUMBER')}
              </Typography>
            </div>
            <div className={classes.formContent}>
              <PhoneField
                label={translate('PHONE')}
                name={'externalNumber'}
                required
                onChange={() => handleChanges}
              />
            </div>
            <div className={classes.formContent}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('RESPONSE_TIMEOUT')}
              </Typography>
            </div>
          </>
        );
      }
      case ScenariosTypes.RedirectToEmployee: {
        const options = employees.map(({ user: { name = '' } = {}, id, ext }) => ({
          title: name || '',
          value: Number(id),
          ext,
          name,
        }));
        return (
          <>
            <div className={classes.formContent}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('CHOOSE_EMPLOYEE')}
              </Typography>
            </div>
            <div className={classes.formContent}>
              <ComboBoxField
                data={options}
                name={'redirectEmployee'}
                placeholder={translate('CHOOSE_EMPLOYEE')}
                valueKey={'value'}
                titleKey={'title'}
                renderOption={renderRedirectToEmployeeOptions}
                onChange={() => handleChanges}
                validate={(value: string) => {
                  if (!value) {
                    return translate('CHOOSE_EMPLOYEE_REQUIRED') as string;
                  }
                  return true;
                }}
                filterOptions={(opts, state) => {
                  if (!state.inputValue) return opts;
                  const inputValueLowerCased = state.inputValue.toLowerCase();
                  return opts.filter(
                    (opt) =>
                      opt.name.toLowerCase().includes(inputValueLowerCased) ||
                      opt.ext.toLowerCase().includes(inputValueLowerCased)
                  );
                }}
              />
            </div>
            <div className={classes.formContent}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('EMPLOYEE_RESPONSE_TIMEOUT')}
              </Typography>
            </div>
          </>
        );
      }
      case ScenariosTypes.RedirectToDepartment: {
        const foundDepartment = departments.find(({ id }) => id === Number(redirectDepartment));
        const serializedDepartment = foundDepartment
          ? deserializeDepartment(foundDepartment)
          : undefined;
        return (
          <>
            <div className={classes.formContent}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('CHOOSE_DEPARTMENT')}
              </Typography>
            </div>
            <div className={classes.formContent}>
              <ComboBoxField
                data={departments}
                name={'redirectDepartment'}
                placeholder={translate('CHOOSE_DEPARTMENT')}
                valueKey={'id'}
                titleKey={'name'}
                renderOption={renderRedirectToDepartmentOptions}
                onChange={() => handleChanges}
                validate={(value: string) => {
                  if (!value) {
                    return translate('CHOOSE_DEPARTMENT_REQUIRED') as string;
                  }
                  return true;
                }}
                filterOptions={(opts, state) => {
                  if (!state.inputValue) return opts;
                  const inputValueLowerCased = state.inputValue.toLowerCase();
                  return opts.filter(
                    (opt) =>
                      opt.name.toLowerCase().includes(inputValueLowerCased) ||
                      opt.ext.toLowerCase().includes(inputValueLowerCased)
                  );
                }}
              />
              {serializedDepartment && (
                <Card className={classes.balloonCard} elevation={0}>
                  <div className={classes.selectionBadge}>
                    <Translate
                      i18nKey={'EMPLOYEES_COUNT_NUMBER'}
                      values={{ employeesCount: serializedDepartment.employeesCount }}
                      components={{ t: <Typography type={'text4'} color={'tertiary900'} /> }}
                    />
                    <Translate
                      i18nKey={'ACTIVE_EMPLOYEES_COUNT_NUMBER'}
                      values={{ activeEmployeesCount: serializedDepartment.activeEmployeesCount }}
                      components={{ t: <Typography type={'text4'} color={'tertiary900'} /> }}
                    />
                    <Translate
                      i18nKey={'EXT_NUM_NUMBER'}
                      values={{ extensionNumber: serializedDepartment.ext }}
                      components={{ t: <Typography type={'text4'} color={'tertiary900'} /> }}
                    />
                  </div>
                </Card>
              )}
            </div>
            <div className={classes.formContent}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('CALL_DISTRIBUTION')}
              </Typography>
            </div>
            <div className={classes.formContent}>
              <CallDistributionFields
                options={callDistributionTimeoutOptions}
                onChange={() => handleChanges}
              />
            </div>
            <div className={classes.formContent}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('QUEUE_CALLER_NOTIFICATION')}
              </Typography>
            </div>
            <div className={classes.formContent}>
              <CheckboxField
                onChange={() => handleChanges}
                name={'notifyPosition'}
                label={translate('QUEUE_POSITION_NOTIFICATION')}
              />
              <CheckboxField
                onChange={() => handleChanges}
                name={'notifyTime'}
                label={translate('QUEUE_TIME_NOTIFICATION')}
              />
            </div>
            <div className={classes.formContent}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('DEPARTMENT_RESPONSE_TIMEOUT')}
              </Typography>
            </div>
          </>
        );
      }
      default:
        return undefined;
    }
  };

  return (
    <div className={classes.root}>
      <FormProvider {...formMethods}>
        <form
          id={'edit-redirect-settings'}
          action={''}
          onSubmit={handleSubmit(handleFormSubmit)}
          noValidate
        >
          <div>
            <div className={classes.formCaption}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('REDIRECT_TO')}
              </Typography>
            </div>
            <div className={classes.formContent}>
              <SelectField
                name={'type'}
                defaultValue={redirectOptions[0].value}
                valueKey={'value'}
                titleKey={'titleCode'}
                translating
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChanges(event, true)}
                data={redirectOptions}
              />
            </div>
            <div className={classes.formCaption}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('MESSAGE')}
              </Typography>
              <SwitchField name={'isMessageActive'} onChanges={() => handleChanges} />
            </div>
            <div className={classes.fields}>
              <MessageFields
                disabled={!isMessageActive}
                required={isMessageActive}
                onChanges={() => handleChanges}
                setIsSaveButtonLoading={setIsSaveButtonLoading}
              />
            </div>
            {renderControls()}
            <div className={classes.formContent}>
              <SelectField
                className={classes.redirectSelect}
                name={'redirectTimeout'}
                valueKey={'value'}
                titleKey={'titleCode'}
                translating
                required
                onChange={handleChanges}
                data={
                  type === ScenariosTypes.RedirectToDepartment
                    ? redirectDepartmentTimeoutOptions
                    : redirectEmployeeTimeoutOptions
                }
                defaultValue={
                  type === ScenariosTypes.RedirectToDepartment
                    ? redirectDepartmentTimeoutOptions[4].value
                    : redirectEmployeeTimeoutOptions[4].value
                }
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default RedirectScenarios;
