import { useMemo } from 'react';
import { useRoutes } from '@components/Routes';
import { useSearchParams } from 'react-router-dom';
import Preloader from '@shared/components/Preloader';
import { IntegrationList, IntegrationStatus } from '@components/typings/interfaces/integrations';
import { integrationsLogoList, integrationsMediumLogoList } from '../../Integrations.constants';
import { comparedEmployeeListState } from '../IntegrationForm.interfaces';
import { apiInformation } from '../IntegrationForm.constants';

export function useIntegrationProps(
  isEdit: boolean,
  defaultType: IntegrationList,
  integration: Record<string, string | number | Record<string, string>> | (null | undefined),
  employee: comparedEmployeeListState
) {
  const {
    path: { identifier },
  } = useRoutes();
  const [searchParams] = useSearchParams();
  const sId = searchParams.get('id');
  const integrationStatus = integration ? integration.status : IntegrationStatus.Draft;

  const APIName = useMemo(() => {
    if (!isEdit && identifier) {
      return identifier as IntegrationList;
    }
    return defaultType;
  }, [defaultType, identifier, isEdit]);

  const ApiInformation = useMemo(() => apiInformation[APIName], [APIName]);
  const { configuration } = ApiInformation;

  const isBitrix24Integration = useMemo(() => APIName === IntegrationList.Bitrix24, [APIName]);
  const isAmoCRMIntegration = useMemo(() => APIName === IntegrationList.AmoCrm, [APIName]);
  const isGravitelIntegration = useMemo(() => APIName === IntegrationList.Gravitel, [APIName]);
  const isAPIIntegration = useMemo(() => APIName === IntegrationList.API, [APIName]);
  const isYClientsIntegration = useMemo(() => APIName === IntegrationList.YClients, [APIName]);
  const isOmnideskIntegration = useMemo(() => APIName === IntegrationList.Omnidesk, [APIName]);
  const isPlanfixIntegration = useMemo(() => APIName === IntegrationList.Planfix, [APIName]);
  const isRoistatIntegration = useMemo(() => APIName === IntegrationList.Roistat, [APIName]);
  const isRetailIntegration = useMemo(() => APIName === IntegrationList.RetailCRM, [APIName]);
  const isKlienticsIntegration = useMemo(() => APIName === IntegrationList.Klientiks, [APIName]);
  const isSimpleCallsIntegration = useMemo(() => APIName === IntegrationList.ProstieZvonki, [APIName]);

  const isTabsHidden = useMemo(() => Boolean(configuration.isScenarioTabsHidden), [configuration]);

  const integrationId = useMemo(() => {
    if (sId) {
      return Number(sId);
    }
    if (
      isEdit &&
      (isBitrix24Integration || isAmoCRMIntegration || isYClientsIntegration || isRetailIntegration)
    ) {
      return Number(identifier);
    }
    return undefined;
  }, [
    sId,
    isEdit,
    isBitrix24Integration,
    isAmoCRMIntegration,
    isYClientsIntegration,
    identifier,
    isRetailIntegration,
  ]);

  const isScenarioTabsAvailable = useMemo(() => {
    if ((isBitrix24Integration || isAmoCRMIntegration || isRetailIntegration) && integrationId) {
      return true;
    }
    return !isBitrix24Integration && !isAmoCRMIntegration && !isRetailIntegration;
  }, [integrationId, isAmoCRMIntegration, isBitrix24Integration, isRetailIntegration]);

  const APILogo = useMemo(() => {
    if (!isEdit) {
      return integrationsLogoList[APIName];
    }
    if (isEdit && integration) {
      return integrationsLogoList[APIName];
    }
    return Preloader;
  }, [APIName, integration, isEdit]);

  const PreviewAPILogo = useMemo(() => {
    if (!isEdit) {
      return integrationsMediumLogoList[APIName];
    }
    return Preloader;
  }, [APIName, integration, isEdit]);

  const isEmployeeListAvailable = useMemo(
    () =>
      !!(
        employee.compared &&
        Object.keys(employee.compared).filter((key: string) => key !== 'notmatch').length > 0 &&
        employee.raw
      ),
    [employee]
  );

  const isSettingsAvailable = useMemo(
    () =>
      configuration.isSettingsAlwaysAvailable ||
      !!(employee.compared && employee.raw) ||
      integrationStatus === IntegrationStatus.Draft,
    [employee.compared, employee.raw, integrationStatus]
  );

  const isControlsAvailable = useMemo(
    () =>
      integrationId ||
      !(
        isBitrix24Integration ||
        isAmoCRMIntegration ||
        isYClientsIntegration ||
        isRetailIntegration
      ),
    [
      integrationId,
      isAmoCRMIntegration,
      isBitrix24Integration,
      isYClientsIntegration,
      isRetailIntegration,
    ]
  );

  return {
    APIName,
    ApiInformation,
    APILogo,
    PreviewAPILogo,
    isTabsHidden,
    integrationId,
    isScenarioTabsAvailable,
    isBitrix24Integration,
    isAmoCRMIntegration,
    isGravitelIntegration,
    isOmnideskIntegration,
    isPlanfixIntegration,
    isRoistatIntegration,
    isRetailIntegration,
    isAPIIntegration,
    isYClientsIntegration,
    isKlienticsIntegration,
    isSimpleCallsIntegration,
    isEmployeeListAvailable,
    isSettingsAvailable,
    isControlsAvailable,
  };
}
