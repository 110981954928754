import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { IntegrationList, IntegrationStatus } from '@components/typings/interfaces/integrations';
import {
  apiInformationType,
  compareStatus,
  crmEmployeeListType,
  IOptionData,
  IResponsibleForCalls,
  scenarioDirectionEnum,
  scenarioSettingsType,
  scenariosListType,
  scenarioStatusEnum,
  scenarioType,
  scenarioTypeEnum,
} from './IntegrationForm.interfaces';
import { bitrixScenarioInformationTabs } from './Bitrix24/BitrixCRM.constants';
import { amoScenarioInformationTabs } from './AmoCRM/AmoCRM.constants';
import { YClientsScenarioInformationTabs } from './YClients/YClientsCRMAuthorization.constants';
import { OmnideskScenarioInformationTabs } from './Omnidesk/OmnideskCRM.constants';
import { PlanfixScenarioInformationTabs } from './Planfix/PlanfixCRM.constants';
import { RoistatScenarioInformationTabs } from './Roistat/RoistatCRMAuthorization.constants';
import { retailScenarioInformationTabs } from './RetailCRM/RetailCRM.constants';
import { OdinSScenarioInformationTabs } from './OdinS/OdinSCRM.constants';
import { KlienticsScenarioInformationTabs } from './Klientics/KlienticsCRM.constants';
import { SimpleCallsScenarioInformationTabs } from './SimpleCalls/SimpleCalls.constants';

export const defaultScenarioInformationTabs: scenariosListType[] = [
  [
    {
      title: 'OPERATIONS_WITH_NUMBERS',
      active: true,
      options: ['SCENARIO_NUMBERS_DESCRIPTION'],
    },
  ],
  [
    {
      title: 'SCENARIO_TITLE_01',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_01'],
    },
    {
      title: 'SCENARIO_TITLE_02',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_02'],
    },
    {
      title: 'SCENARIO_TITLE_03',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_03'],
    },
    {
      title: 'SCENARIO_TITLE_04',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_04'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_04',
      edit: scenarioSettingsType.Responsible,
      options: ['RESPONSIBLE_SCENARIOS_ITEM'],
    },
  ],
];

export const CALL_BACK_LINK = 'https://helpdesk.bitrix24.ru/open/6986667/?sphrase_id=929591980';

export const scenarioTabList = [
  {
    visible: true,
    scenarioType: scenarioType.Simple,
    tabTitleDefault: 'NUMBERS',
    tabTitleAdvance: 'NUMBERS',
  },
  {
    visible: true,
    scenarioType: scenarioType.Simple,
    tabTitleDefault: 'SCENARIOS',
    tabTitleAdvance: 'INCOMING_SCENARIO_TAB',
  },
  {
    visible: true,
    scenarioType: scenarioType.Advanced,
    tabTitleDefault: 'SCENARIOS',
    tabTitleAdvance: 'OUTGOING_SCENARIO_TAB',
  },
  {
    visible: true,
    scenarioType: scenarioType.Advanced,
    tabTitleDefault: 'SCENARIOS',
    tabTitleAdvance: 'GENERAL_SCENARIO_TAB',
  },
];

export const apiInformation: apiInformationType = {
  [IntegrationList.API]: {
    title: 'API_INTEGRATION',
    description: 'INTEGRATION_API_DESCRIPTION',
    sideLogo: true,
    document: true,
    paid: true,
    configuration: {
      responsible: true,
      isSettingsAlwaysAvailable: true,
      isScenarioTabsHidden: true,
      settings: defaultScenarioInformationTabs,
      settingsTitle: 'INTEGRATION_SCENARIOS',
      settingsType: scenarioType.Simple,
    },
  },
  [IntegrationList.Gravitel]: {
    title: 'API_GRAVITEL',
    description: 'INTEGRATION_GRAVITEL_DESCRIPTION',
    sideLogo: false,
    document: false,
    paid: true,
    configuration: {
      responsible: true,
      isSettingsAlwaysAvailable: true,
      isScenarioTabsHidden: true,
      settings: defaultScenarioInformationTabs,
      settingsTitle: 'INTEGRATION_SCENARIOS',
      settingsType: scenarioType.Simple,
    },
  },
  [IntegrationList.Bitrix24]: {
    title: 'BITRIX24_API_INTEGRATION',
    description: 'INTEGRATION_BITRIX24_DESCRIPTION',
    installDescription: 'INSTALL_BITRIX_APP_DESCRIPTION',
    sideLogo: true,
    document: false,
    paid: true,
    configuration: {
      responsible: true,
      isSettingsAlwaysAvailable: false,
      isScenarioTabsHidden: false,
      settingsTitle: 'INTEGRATION_SCENARIOS_COUNT',
      settings: bitrixScenarioInformationTabs,
      settingsType: scenarioType.Advanced,
    },
  },
  [IntegrationList.AmoCrm]: {
    title: 'AMOCRM_API_INTEGRATION',
    description: 'AMOCRM_INTEGRATION_DESCRIPTION',
    installDescription: 'AMOCRM_INSTALL_DESCRIPTION',
    sideLogo: true,
    document: false,
    paid: true,
    configuration: {
      responsible: true,
      isSettingsAlwaysAvailable: false,
      isScenarioTabsHidden: false,
      settingsTitle: 'INTEGRATION_SCENARIOS_COUNT',
      settings: amoScenarioInformationTabs,
      settingsType: scenarioType.Advanced,
    },
  },
  [IntegrationList.Megaplan]: {
    title: 'MEGAPLAN_API_INTEGRATION',
    description: '',
    sideLogo: true,
    document: false,
    paid: false,
    configuration: {
      responsible: false,
      isSettingsAlwaysAvailable: false,
      isScenarioTabsHidden: false,
      settings: defaultScenarioInformationTabs,
      settingsTitle: 'INTEGRATION_SCENARIOS_COUNT',
      settingsType: scenarioType.Simple,
    },
  },
  [IntegrationList.RetailCRM]: {
    title: 'RETAIL_API_INTEGRATION',
    description: 'RETAILCRM_API_DESCRIPTION',
    installDescription: 'RETAIL_INSTALL_DESCRIPTION',
    sideLogo: true,
    document: false,
    paid: true,
    configuration: {
      responsible: true,
      isSettingsAlwaysAvailable: false,
      isScenarioTabsHidden: true,
      settings: retailScenarioInformationTabs,
      settingsTitle: 'INTEGRATION_SCENARIOS_COUNT',
      settingsType: scenarioType.Advanced,
    },
  },
  [IntegrationList.YClients]: {
    title: 'YCLIENTS_API_INTEGRATION',
    description: 'YCLIENTS_API_INTEGRATION_DESCRIPTION',
    sideLogo: true,
    document: false,
    paid: true,
    configuration: {
      responsible: false,
      isSettingsAlwaysAvailable: true,
      isScenarioTabsHidden: true,
      settings: YClientsScenarioInformationTabs,
      settingsTitle: 'INTEGRATION_SCENARIOS',
      settingsType: scenarioType.Simple,
    },
  },
  [IntegrationList.OdinS]: {
    title: 'ODINS_API_INTEGRATION',
    description: 'ODINS_API_INTEGRATION_DESCRIPTION',
    sideLogo: true,
    document: false,
    paid: true,
    configuration: {
      responsible: true,
      isSettingsAlwaysAvailable: true,
      isScenarioTabsHidden: true,
      settings: OdinSScenarioInformationTabs,
      settingsTitle: 'INTEGRATION_SCENARIOS',
      settingsType: scenarioType.Simple,
    },
  },
  [IntegrationList.Omnidesk]: {
    title: 'OMNIDESK_API_INTEGRATION',
    description: 'OMNIDESK_API_INTEGRATION_DESCRIPTION',
    sideLogo: true,
    document: false,
    paid: true,
    configuration: {
      responsible: false,
      isSettingsAlwaysAvailable: true,
      isScenarioTabsHidden: true,
      settings: OmnideskScenarioInformationTabs,
      settingsTitle: 'INTEGRATION_SCENARIOS',
      settingsType: scenarioType.Advanced,
    },
  },
  [IntegrationList.Planfix]: {
    title: 'PLANFIX_API_INTEGRATION',
    description: 'PLANFIX_API_INTEGRATION_DESCRIPTION',
    sideLogo: true,
    document: false,
    paid: true,
    configuration: {
      responsible: true,
      isSettingsAlwaysAvailable: true,
      isScenarioTabsHidden: true,
      settings: PlanfixScenarioInformationTabs,
      settingsTitle: 'INTEGRATION_SCENARIOS',
      settingsType: scenarioType.Advanced,
    },
  },
  [IntegrationList.Roistat]: {
    title: 'ROISTAT_API_INTEGRATION',
    description: 'ROISTAT_INTEGRATION_DESCRIPTION',
    sideLogo: true,
    document: false,
    paid: false,
    configuration: {
      responsible: false,
      isSettingsAlwaysAvailable: true,
      isScenarioTabsHidden: true,
      settingsTitle: 'INTEGRATION_SCENARIOS_SETTING',
      settings: RoistatScenarioInformationTabs,
      settingsType: scenarioType.Simple,
    },
  },
  [IntegrationList.ProstieZvonki]: {
    title: 'SIMPLE_CALLS_API_INTEGRATION',
    description: 'SIMPLE_CALLS_API_INTEGRATION_DESCRIPTION',
    sideLogo: true,
    document: false,
    paid: true,
    configuration: {
      responsible: false,
      isSettingsAlwaysAvailable: true,
      isScenarioTabsHidden: true,
      settings: SimpleCallsScenarioInformationTabs,
      settingsTitle: 'INTEGRATION_SCENARIOS_SETTING',
      settingsType: scenarioType.Simple,
    },
  },
  [IntegrationList.Klientiks]: {
    title: 'KLIENTICS_API_INTEGRATION',
    description: 'KLIENTICS_API_INTEGRATION_INSIDE_DESCRIPTION',
    sideLogo: true,
    document: false,
    paid: true,
    configuration: {
      responsible: false,
      isSettingsAlwaysAvailable: true,
      isScenarioTabsHidden: true,
      settings: KlienticsScenarioInformationTabs,
      settingsTitle: 'INTEGRATION_SCENARIOS',
      settingsType: scenarioType.Simple,
    },
  },
};

export const gravitelIntegrationList = [
  'U-ON.Travel',
  'Автошкола-Контроль',
  'SalesPlatform CRM',
  'РемОнлайн',
  'MacroCRM',
  'РосБизнесСофт',
  'SalesMax',
  'Intrum',
  'Salesap',
  'Beauty Expert',
  'ClickClient',
  'StecRealty',
  'CRM-1RS',
  'SalesPlatform',
  'BitMS',
  'Альфа.CRM',
  'EnvyCRM',
  'PROFITBASE',
  'СРМ Застройщика',
  'Автодиллер',
  'Юздеск',
  'OMNIDESK',
  'Monitor',
  'SalesMan',
  'Kvartus',
  'Gincore',
  'it-help',
  'МегаФон Рекрутика',
  'SONLINE',
  'YUcrm',
  'Бурмистр.ру',
  'RAMEX',
  'АДС на 100%',
  'profrealt-crm',
  'ПринтОфис',
  'OmisCRM',
  'Офис 21',
  'МОЯАВТОШКОЛА.онлайн',
  'Мой Дом Онлайн',
  'IT Tourism',
  'Go CRM',
  'CRM Darwin',
  'Ветменеджер',
  'SYNERGY CRM',
  'Симплекс',
  'SDELKA',
  'Pipedrive',
  'Overport',
  'Okdesk',
  'FreshOffice',
  'moreCRM',
  'MEDODS',
  'LeadVertex',
  'ИНСОЦ',
  'Инструмент Директора',
  'HomeCRM',
  'GEEKAUTO',
  'DOMUCHET.ONLINE',
  'DNK CRM',
  'Dealer Point',
  'CRMNedv',
  'bpm online',
  'АСУС МКД',
  'Lotinfo',
  'CRM-1RS',
  'BISHOP',
  'CRM RealtyRuler',
  'МойСклад',
  'MobiDel',
  'Skillaz',
  'Netix',
  'ListOk',
  'СРМ Застройщика',
  'York',
  'WireCRM',
  'Renovatio',
  'СИТИ',
  'Глобал ЖКХ',
  'Medesk',
  'fitness365',
  'Brizo',
];

export const getGravitelIntegrationList = (): Array<{
  key: number;
  value: string;
  crmName: string;
}> =>
  gravitelIntegrationList.map((item, index) => ({
    key: index,
    value: item,
    crmName: item,
  }));

export const formatNumbersOptions = (arr: IOptionData[]): IOptionData[] =>
  arr.map((item) => ({
    ...item,
    phone: formatPhone(item.phone),
  }));

export const leadResponsibleForCalls: IResponsibleForCalls = {
  [scenarioDirectionEnum.Incoming]: {
    responsible: 'CLIENT_RESPONSIBLE',
    talker: 'RECEIVER_OF_THE_CALL',
  },
  [scenarioDirectionEnum.Outgoing]: {
    responsible: 'CLIENT_RESPONSIBLE',
    talker: 'CALLER',
  },
};

export const taskResponsibleForCalls: IResponsibleForCalls = {
  [scenarioDirectionEnum.Incoming]: {
    responsible: 'CLIENT_RESPONSIBLE_02',
    talker: 'RECEIVER_OF_THE_CALL_02',
  },
  [scenarioDirectionEnum.Outgoing]: {
    responsible: 'CLIENT_RESPONSIBLE_02',
    talker: 'CALLER_02',
  },
};

export const incomingResponsibleOptions = [
  {
    key: 'responsible',
    value: 'CLIENT_RESPONSIBLE',
  },
  {
    key: 'talker',
    value: 'RECEIVER_OF_THE_CALL',
  },
];

export const incomingMissedResponsibleOptions = [
  {
    key: 'responsible',
    value: 'CLIENT_RESPONSIBLE',
  },
];

export const incomingUnknownResponsibleOptions = [
  {
    key: 'responsible',
    value: 'DUTY_OFFICER',
  },
  {
    key: 'talker',
    value: 'RECEIVER_OF_THE_CALL',
  },
];

export const outgoingResponsibleOptions = [
  {
    key: 'responsible',
    value: 'CLIENT_RESPONSIBLE',
  },
  {
    key: 'talker',
    value: 'CALLER',
  },
];

export const getSettingsObjectKeys = (
  settingType?: scenarioSettingsType
): { direction: scenarioDirectionEnum; type: scenarioTypeEnum; status: scenarioStatusEnum } => {
  switch (settingType) {
    case scenarioSettingsType.IncomingKnown:
      return {
        direction: scenarioDirectionEnum.Incoming,
        type: scenarioTypeEnum.Known,
        status: scenarioStatusEnum.Success,
      };
    case scenarioSettingsType.IncomingMissedKnown:
      return {
        direction: scenarioDirectionEnum.Incoming,
        type: scenarioTypeEnum.Known,
        status: scenarioStatusEnum.Missed,
      };
    case scenarioSettingsType.IncomingUnknown:
      return {
        direction: scenarioDirectionEnum.Incoming,
        type: scenarioTypeEnum.Unknown,
        status: scenarioStatusEnum.Success,
      };
    case scenarioSettingsType.IncomingMissedUnknown:
      return {
        direction: scenarioDirectionEnum.Incoming,
        type: scenarioTypeEnum.Unknown,
        status: scenarioStatusEnum.Missed,
      };
    case scenarioSettingsType.OutgoingKnown:
      return {
        direction: scenarioDirectionEnum.Outgoing,
        type: scenarioTypeEnum.Known,
        status: scenarioStatusEnum.Success,
      };
    case scenarioSettingsType.OutgoingMissedKnown:
      return {
        direction: scenarioDirectionEnum.Outgoing,
        type: scenarioTypeEnum.Known,
        status: scenarioStatusEnum.Missed,
      };
    case scenarioSettingsType.OutgoingUnknown:
      return {
        direction: scenarioDirectionEnum.Outgoing,
        type: scenarioTypeEnum.Unknown,
        status: scenarioStatusEnum.Success,
      };
    case scenarioSettingsType.OutgoingMissedUnknown:
      return {
        direction: scenarioDirectionEnum.Outgoing,
        type: scenarioTypeEnum.Unknown,
        status: scenarioStatusEnum.Missed,
      };
    case scenarioSettingsType.CommonCallHistory:
      return {
        direction: scenarioDirectionEnum.Common,
        type: scenarioTypeEnum.Known,
        status: scenarioStatusEnum.Success,
      };
    case scenarioSettingsType.Sites:
      return {
        direction: scenarioDirectionEnum.RetailCrm,
        type: scenarioTypeEnum.Site,
        status: scenarioStatusEnum.Success,
      };
    default:
      return {
        direction: scenarioDirectionEnum.Common,
        type: scenarioTypeEnum.Known,
        status: scenarioStatusEnum.Success,
      };
  }
};

export const compareEmployeeFilterList = [
  {
    value: compareStatus.Compared,
    title: 'COMPARED',
  },
  {
    value: compareStatus.NotCompare,
    title: 'DO_NOT_COMPARE',
  },
  {
    value: compareStatus.NotSelected,
    title: 'DO_NOT_COMPARED',
  }
];

export const ALL_EMPLOYEE_FILTERS_ON = [
  compareStatus.Compared,
  compareStatus.NotSelected,
  compareStatus.NotCompare,
];

export const getComparedEmployeeIndex = (
  list: crmEmployeeListType[],
  item: number | string
): number => {
  let emIndex = -1;
  const emItem = list.find((e: crmEmployeeListType) => Number(e.id) === Number(item));
  if (emItem) {
    emIndex = list.indexOf(emItem);
  }
  return emIndex;
};

export const setCloudUrl = (
  APIName: IntegrationList,
  domain: string | undefined,
  crmAddress: string | undefined
): string => {
  switch (APIName) {
    case IntegrationList.Gravitel:
      return `https://${domain}/sys/crm_api.wcgp`;
    case IntegrationList.Planfix:
      return `https://${domain}/sys/planfix.wcgp`;
    case IntegrationList.Omnidesk:
      return `https://${domain}/sys/omnidesk.wcgp`;
    case IntegrationList.OdinS:
      return `https://${domain}/sys/crm1c_api.wcgp`;
    case IntegrationList.Klientiks:
      return `https://${domain}/sys/klientix_api.wcgp`;
    case IntegrationList.Albato:
      return `https://${domain}/sys/albato.wcgp`;
    case IntegrationList.ProstieZvonki:
      return `https://${domain}/sys/simple_calls.wcgp`;
    case IntegrationList.Megaplan:
      return 'wss://megaplan.aicall.ru';
    case IntegrationList.API:
    default:
      return `https://${crmAddress}/v1/${domain}/`;
  }
};

export const setSwitchText = (
  status: IntegrationStatus | undefined,
  isIntegrationEdit: boolean
): string => {
  if (status && isIntegrationEdit) {
    if (status === IntegrationStatus.Active || status === IntegrationStatus.Draft) {
      return 'SWITCH_ACTIVE';
    }
    return 'SWITCH_INACTIVE';
  }
  return 'SWITCH_ACTIVE';
};

export const crmLiteralNames: Record<string, string> = {
  [IntegrationList.Klientiks]: 'KLIENTICS_API_INTEGRATION',
  [IntegrationList.ProstieZvonki]: 'SIMPLE_CALLS_API_INTEGRATION',
  [IntegrationList.YClients]: IntegrationList.YClients,
};
