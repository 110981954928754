import { FORGOT_PASSWORD_MUTATION } from '@/client/mutations';
import { useFormErrors } from '@/common/hooks/formErrors.hooks';
import { useMutation } from '@apollo/client';
import FormErrorMessage from '@components/FormErrorMessage';
import Message from '@components/Messages';
import EmailField from '@shared/components/EmailField';
import MessageDialog from '@shared/components/MessageDialog';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useForgotPasswordDialogStyles } from './ForgotPasswordDialog.styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ForgotPasswordDialog = ({ isOpen, onClose }: Props) => {
  const classes = useForgotPasswordDialogStyles();
  const [translate] = useTranslation();

  const [isSuccess, setIsSuccess] = useState(false);

  const [
    forgotPassword,
    { error: { graphQLErrors = [] } = {}, data, loading, reset: mutationReset },
  ] = useMutation(FORGOT_PASSWORD_MUTATION);

  const formMethods = useForm<{ email: string }>({ defaultValues: { email: '' } });
  const { handleSubmit } = formMethods;
  useFormErrors(graphQLErrors, formMethods);

  const onSubmit = useCallback(
    ({ email }: { email: string }) => {
      if (loading) {
        return;
      }
      forgotPassword({ variables: { email } })
        .then(() => {
          setIsSuccess(true);
        })
        .catch(() => {}); // error is handled via `graphQLErrors`
    },
    [forgotPassword, loading]
  );

  const renderMessages = () => {
    if (graphQLErrors?.length) {
      return <FormErrorMessage errors={graphQLErrors} />;
    }
    if (isSuccess) {
      return <Message className={classes.message} data={data?.forgotPassword} type={'success'} />;
    }
    return null;
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setIsSuccess(false);
      mutationReset();
      formMethods.reset();
    }, 150);
  };

  return (
    <MessageDialog
      title={translate('FORGOT_PASSWORD')}
      isOpen={isOpen}
      onCancel={handleClose}
      renderContent={
        <>
          <Typography color={'tertiary900'} type={'text3'}>
            {translate('FORGOT_PASSWORD_TEXT')}
          </Typography>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
              <EmailField name="email" required />
              {renderMessages()}
            </form>
          </FormProvider>
        </>
      }
      buttonSuccessProps={{
        titleCode: isSuccess ? translate('CLOSE') : translate('RECOVER_PASSWORD'),
        onClick: isSuccess ? handleClose : handleSubmit(onSubmit),
        loading,
      }}
      buttonCancelProps={{
        titleCode: translate('CANCEL'),
        onClick: handleClose,
        className: clsx(isSuccess && classes.hidden),
      }}
    />
  );
};

export default ForgotPasswordDialog;
