import React, { FunctionComponent } from 'react';
import { toPrecision } from '@components/utils/toPrecision';
import { useTranslation } from 'react-i18next';
import { IDomainNumber } from '@components/typings/interfaces';
import { NumberDiscount } from '@/features/FinancialCabinet/Numbers/Numbers.helpers';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';

export const NumberPerMonthCell: FunctionComponent<ICellProps<IDomainNumber>> = ({ item }) => {
  const [translate] = useTranslation();

  if (item) {
    const { monthlyFee, monthlyDiscount, isMonthlyDiscountAbsolute } = item.tariff || {};

    return (
      <Typography type={'text3'} color={'inherit'}>
        {translate('RUB_PER_MONTH', {
          amount: monthlyFee ? toPrecision(monthlyFee, false) : '-',
        })}
        {!!monthlyDiscount && (
          <NumberDiscount discount={monthlyDiscount} isAbsolute={!!isMonthlyDiscountAbsolute} />
        )}
      </Typography>
    );
  }

  return <div />;
};
