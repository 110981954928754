export const payerTypeOptionsList = [
  {
    value: 'UL',
    titleCode: 'LEGAL_TYPE',
  },
  {
    value: 'FL',
    titleCode: 'INDIVIDUAL_TYPE',
  },
  {
    value: 'IP',
    titleCode: 'BUSINESSMAN_TYPE',
  },
];

export const partnerTypeOptionsList = [
  {
    value: 'UL',
    titleCode: 'LEGAL_TYPE',
  },
  {
    value: 'IP',
    titleCode: 'BUSINESSMAN_TYPE',
  },
];

export const genderOptions = [
  {
    value: 'male',
    titleCode: 'MALE',
  },
  {
    value: 'female',
    titleCode: 'FEMALE',
  },
];
