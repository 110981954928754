import React, { FunctionComponent, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { COMMON_OUTGOING_NUMBER_QUERY, DOMAIN_NUMBERS_QUERY } from '@/client/queries';
import Drawer from '@components/Drawer';
import clsx from 'clsx';
import { UPDATE_COMMON_OUTGOING_NUMBER_MUTATION } from '@/client/mutations';
import { FormProvider, useForm } from 'react-hook-form';
import SelectField, { ISelectFieldOptionRendererProps } from '@shared/components/SelectField';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import Typography from '@shared/components/Typography';
import Flex from '@shared/components/Flex';
import { CheckIcon } from '@shared/assets/images/icons';
import { NumberType } from '@components/typings/interfaces';
import { useOutgoingCallsCommonNumberStyles } from './OutgoingCallsCommonNumber.styles';

export type OutgoingCallProps = {
  numberId?: number;
};

interface IFormFields {
  numberId?: number;
}

export const OutgoingCallsCommonNumber: FunctionComponent<OutgoingCallProps> = ({ numberId }) => {
  const classes = useOutgoingCallsCommonNumberStyles({});
  const [translate] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const formMethods = useForm<IFormFields>({
    defaultValues: {
      numberId,
    },
  });
  const { handleSubmit } = formMethods;
  const [isChanged, setIsChanged] = useState(false);

  const [updateOutgoingCallsCommonNumber, { loading: updateLoading }] = useMutation(
    UPDATE_COMMON_OUTGOING_NUMBER_MUTATION
  );

  const { data: { boughtDomainNumbers: domainNumbers = [] } = { boughtDomainNumbers: [] } } =
    useQuery<{
      boughtDomainNumbers: { id: number; phone: string; city: string; type: NumberType }[];
    }>(DOMAIN_NUMBERS_QUERY, { fetchPolicy: 'cache-first' });
  const allowedDomainNumbers = domainNumbers?.filter(
    (num) => ![NumberType.GravitelCommon, NumberType.Own].includes(num.type)
  );

  const handleClose = () => {
    searchParams.delete('id');
    setSearchParams(searchParams);
  };

  const handleSubmitForm = ({ numberId: selectedNumberId }: IFormFields) => {
    if (updateLoading) return;
    updateOutgoingCallsCommonNumber({
      variables: { data: { numberId: selectedNumberId } },
      refetchQueries: [{ query: COMMON_OUTGOING_NUMBER_QUERY }],
    }).then(() => {
      handleClose();
    });
  };

  const handleChanges = () => {
    setIsChanged(true);
  };

  const renderCustomOption = ({
    data,
    selected,
  }: ISelectFieldOptionRendererProps<{
    id: number;
    phone: string;
    city: string;
    type: NumberType;
  }>) => {
    const { id: numId, phone = '', city = '' } = data;

    if (numId === undefined) {
      return (
        <div className={classes.customOption}>
          <Typography type={'text3'} color={'tertiary900'}>
            {translate('CHOOSE')}
          </Typography>
        </div>
      );
    }

    return (
      <div className={clsx(classes.customOption, { [classes.customOptionSelected]: selected })}>
        <Flex alignItems={'center'}>
          {selected && <CheckIcon className={classes.glyph} />}
          <Flex direction={'column'} alignItems={'flexStart'}>
            <Typography type={'text3'} color={selected ? 'primary700' : 'tertiary900'}>
              {formatPhone(phone)}
            </Typography>
            <Typography
              type={'text4'}
              color={selected ? 'primary700' : 'tertiary600'}
              className={classes.secondaryText}
            >
              {city}
            </Typography>
          </Flex>
        </Flex>
      </div>
    );
  };

  return (
    <Drawer
      title={'COMPANY_PRIMARY_NUMBER'}
      ModalProps={{ disableBackdropClick: isChanged }}
      elevation={4}
      open
      size={'xs'}
      primaryButton={{
        title: 'ACCEPT',
        props: {
          form: 'edit-common-outgoing-call',
          type: 'submit',
          loading: updateLoading,
        },
      }}
      secondaryButton={{
        title: 'CANCEL',
        onClick: handleClose,
        props: {
          disabled: updateLoading,
        },
      }}
      onClose={handleClose}
    >
      <FormProvider {...formMethods}>
        <form
          id={'edit-common-outgoing-call'}
          action={''}
          onSubmit={handleSubmit(handleSubmitForm)}
          className={classes.form}
        >
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('CHOOSE_NUMBER')}
          </Typography>
          <div className={classes.formContent}>
            <SelectField
              name={'numberId'}
              Renderer={renderCustomOption}
              valueKey={'id'}
              titleKey={'phone'}
              data={allowedDomainNumbers}
              onChange={handleChanges}
              required
              validate={(value) => {
                if (!value || !allowedDomainNumbers.some((num) => num.id === value)) {
                  return translate('CHOOSE_NUMBER') as string;
                }
                return true;
              }}
            />
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default OutgoingCallsCommonNumber;
