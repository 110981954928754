import React, { FunctionComponent, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { DOMAIN_ALL_NUMBERS_QUERY } from '@/client/queries';
import Breadcrumbs from '@/components/Breadcrumbs';
import PagePreloader from '@components/PagePreloader';
import PageError from '@shared/components/PageError';
import Typography from '@shared/components/Typography';
import Translate from '@shared/components/Translate';
import QuickSearch from '@components/QuickSearch';
import { useSearchParams } from 'react-router-dom';
import LazyTable, { CellPreloader, IColumn } from '@components/LazyTable';
import { PhoneIcon } from '@shared/assets/images/icons';
import Flex from '@shared/components/Flex';
import BodyContainer from '@/layouts/BodyContainer';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IDomainNumber } from '@components/typings/interfaces';
import { IIncomingNumber } from './IncomingNumbers.interfaces';
import { useIncomingNumbersStyles } from './IncomingNumbers.styles';
import {
  IncomingNumberActionsCell,
  IncomingNumberPeriodsCell,
  IncomingNumberTypeCell,
  IncomingNumberCell,
  IncomingNumberIntegrationCell,
} from './modules';

export const IncomingNumbers: FunctionComponent = () => {
  const classes = useIncomingNumbersStyles();
  const [searchParams] = useSearchParams();
  const sQuery = searchParams.get('query')?.toUpperCase();

  const columns: IColumn<IIncomingNumber>[] = [
    {
      id: 'external',
      label: 'TYPE',
      minWidth: 32,
      width: '3em',
      verticalAlign: 'top',
      Renderer: IncomingNumberTypeCell,
    },
    {
      id: 'phone',
      label: 'NUMBER',
      width: 280,
      minWidth: 280,
      verticalAlign: 'top',
      Renderer: IncomingNumberCell,
    },
    {
      id: 'integration',
      label: 'INTEGRATIONS',
      width: 160,
      minWidth: 160,
      verticalAlign: 'top',
      Renderer: IncomingNumberIntegrationCell,
    },
    {
      id: 'periods',
      label: 'SCHEDULE',
      verticalAlign: 'top',
      Renderer: IncomingNumberPeriodsCell,
    },
    {
      id: 'actions',
      label: '',
      width: 80,
      minWidth: 80,
      Renderer: IncomingNumberActionsCell,
      align: 'right',
    },
  ];

  const formMethods = useForm({
    defaultValues: {
      query: sQuery,
    },
  });
  const [translate] = useTranslation();

  const { loading = false, error = false, data } = useQuery(DOMAIN_ALL_NUMBERS_QUERY);
  const allIncomingNumbersData = [...(data?.domainNumbers || [])].sort(
    (a: IDomainNumber, b: IDomainNumber) => {
      if (a.status < b.status) return -1;
      if (a.status > b.status) return 1;
      return 0;
    }
  );

  const tableData = useMemo(() => {
    if (allIncomingNumbersData) {
      return sQuery
        ? allIncomingNumbersData.filter(({ phone, name, city }: IIncomingNumber) => {
            const cleanedQuery = sQuery.replace(/[( )\s+-]/g, '');
            return (
              name?.toUpperCase().includes(sQuery) ||
              city?.toUpperCase().includes(sQuery) ||
              phone?.includes(cleanedQuery)
            );
          })
        : allIncomingNumbersData;
    }
    return [];
  }, [allIncomingNumbersData, sQuery]);

  const renderIncomingNumbersContent = () => {
    if (loading) return <PagePreloader />;
    if (error) return <PageError />;

    const renderEmptyContent = () => {
      if (!sQuery) {
        return (
          <div className={classes.noNumbers}>
            <PhoneIcon className={classes.phoneNumberIcon} />
            <Typography type={'text3'} color={'tertiary600'}>
              <Translate i18nKey={'NO_INCOMING_NUMBERS'} />
            </Typography>
          </div>
        );
      }
      return (
        <CellPreloader>
          <Typography color={'tertiary900'} type={'text3'}>
            {translate('NOTHING_FOUND')}
          </Typography>
        </CellPreloader>
      );
    };

    return (
      <FormProvider {...formMethods}>
        <Flex direction={'column'} className={classes.root}>
          <Breadcrumbs>
            <QuickSearch parameterName={'query'} />
          </Breadcrumbs>
          <LazyTable<IIncomingNumber>
            columns={columns}
            data={tableData}
            renderEmptyDataMessage={renderEmptyContent()}
          />
        </Flex>
      </FormProvider>
    );
  };

  return <BodyContainer disableOverflow>{renderIncomingNumbersContent()}</BodyContainer>;
};

export default IncomingNumbers;
