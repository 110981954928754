import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { setStorageItem } from '@components/storage/storage';
import { refreshAuthenticationState } from '@components/client/client';
import { useMutation } from '@apollo/client';
import { SIGNIN } from '@components/client/mutations';
import { Button } from '@shared/components/Button/Button';
import FormControlLabelForRadio from '@shared/components/FormControlLabelForRadio';
import Radio from '@shared/components/Radio';
import RadioGroup from '@shared/components/RadioGroup';
import clsx from 'clsx';
import FormErrorMessage from '@components/FormErrorMessage';
import { AuthFormStyle } from '@/layouts/Auth/AuthForm/AuthForm.styles';
import PasswordField from '@shared/components/PasswordField';
import EmailField from '@shared/components/EmailField';
import Typography from '@shared/components/Typography';
import { useMediaQuery } from '@material-ui/core';
import ForgotPasswordDialog from '../ForgotPasswordDialog/ForgotPasswordDialog';

export const SignInForm = () => {
  const classes = AuthFormStyle();

  const [isForgotPasswordDialogOpen, setIsForgotPasswordDialogOpen] = useState(false);

  const formMethods = useForm({ mode: 'onBlur' });
  const { handleSubmit, watch } = formMethods;
  const domain = watch('domain');

  const notSmallScreen = useMediaQuery('(min-width:960px)', { noSsr: true });

  const onCompleted = (
    response: {
      signin?: {
        token?: string;
        refreshToken?: string;
        user?: { domains?: { id: number; domain: string; current: boolean }[] };
      };
    } | null
  ) => {
    const {
      signin: { token = undefined, refreshToken = undefined, user: { domains = [] } = {} } = {},
    } = response || {};
    if (token) {
      let currentDomain = domain;
      if (!currentDomain) {
        const curDomain = domains?.find((domainItem) => domainItem.current);
        currentDomain = curDomain?.domain;
      }
      const { host, protocol } = window.location;
      if (process.env.REDIRECT_TO_DOMAIN && currentDomain && currentDomain !== host) {
        window.location.href = `${protocol}//${currentDomain}/?token=${token}&refreshToken=${refreshToken}`;
        return;
      }
      setStorageItem('token', token);
      setStorageItem('refreshToken', refreshToken);
      refreshAuthenticationState();
    }
  };

  const [signIn, { data, error: { graphQLErrors = undefined } = {}, loading }] = useMutation(
    SIGNIN,
    { onCompleted }
  );
  const { token, user: { domains = [] } = {} } = data?.signin || {};
  const [translate] = useTranslation();

  const handleSubmitForm = (variables: { email?: string; password?: string; domain?: string }) => {
    signIn({ variables }).catch(() => {
      // error display is handled with useMutation `error` field and with help of FormErrorMessage component
    });
  };

  const openForgotPasswordDialog = () => {
    setIsForgotPasswordDialogOpen(true);
  };

  const handleForgotPasswordDialogClose = () => {
    setIsForgotPasswordDialogOpen(false);
  };

  const renderDomainControl = ({ id, domain: optionDomain }: { id: number; domain: string }) => (
    <FormControlLabelForRadio
      isActive={optionDomain === domain}
      key={id}
      value={optionDomain}
      disabled={loading}
      control={<Radio disabled={loading} />}
      label={optionDomain}
    />
  );

  const renderForgotButton = () => {
    if (domains.length) {
      return null;
    }
    return (
      <div className={classes.link}>
        <Button variant="text" onClick={openForgotPasswordDialog}>
          <Typography underline color={'link500'}>
            {translate('FORGOT_PASSWORD')}
          </Typography>
        </Button>
      </div>
    );
  };

  return (
    <>
      <ForgotPasswordDialog
        isOpen={isForgotPasswordDialogOpen}
        onClose={handleForgotPasswordDialogClose}
      />
      <div className={classes.root}>
        <div className={clsx(classes.header, classes.headerShort)}>
          <Typography bold color={'tertiary900'} type={'text1'}>
            {translate(domains.length && !token ? 'CHOOSE_PBX' : 'SIGNIN_FORM_TITLE')}
          </Typography>
        </div>
        <div className={classes.content}>
          <FormProvider {...formMethods}>
            <form action={''} onSubmit={handleSubmit(handleSubmitForm)}>
              <FormErrorMessage errors={graphQLErrors} className={classes.signInFormError} />
              <div
                className={clsx(classes.formContent, {
                  [classes.hidden]: domains.length && !graphQLErrors,
                })}
              >
                <div className={classes.formContent}>
                  <EmailField
                    name="email"
                    autoComplete={'user-name'}
                    disabled={loading}
                    label={translate('EMAIL')}
                    InputProps={{ readOnly: !!domains.length && !graphQLErrors }}
                    placeholder={translate('EMAIL_REQUIRED')}
                    required
                  />
                </div>
                <div className={classes.formContent}>
                  <PasswordField
                    name="password"
                    scoring={false}
                    generation={false}
                    autoComplete={'current-password'}
                    label={translate('PASSWORD')}
                    disabled={loading}
                    InputProps={{ readOnly: !!domains.length && !graphQLErrors }}
                    required
                    placeholder={translate('PASSWORD_EMPTY')}
                  />
                </div>
              </div>
              <div
                className={clsx(classes.domainsFormContent, {
                  [classes.hidden]:
                    token || domains?.length === 1 || !domains.length || graphQLErrors,
                })}
              >
                <RadioGroup
                  key={domains[0]?.domain || 'domainRadio'}
                  defaultValue={domains[0]?.domain}
                  name={'domain'}
                  className={classes.domainsFormRadioGroup}
                >
                  {domains.map(renderDomainControl)}
                </RadioGroup>
              </div>
              <Button
                className={notSmallScreen ? classes.submitButton : classes.submitBtnSmallScreen}
                type={'submit'}
                disableElevation={false}
                fullWidth
                loading={loading}
              >
                <Typography type={'text2'} color={'inherit'}>
                  {translate(domains.length && !token ? 'CONTINUE' : 'SIGNIN')}
                </Typography>
              </Button>
              {renderForgotButton()}
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};
