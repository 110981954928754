export enum OpenModalType {
  Off,
  RefuseBought,
  AbortRefuse,
  Bought,
}

export interface INumberDiscountProps {
  discount: number;
  isAbsolute: boolean;
}
