import { BALANCE_DETAILS_QUERY, BALANCE_QUERY, USER_QUERY } from '@/client/queries';
import BalanceDialog from '@/components/BalanceDialog/BalanceDialog';
import Breadcrumbs from '@/components/Breadcrumbs';
import PeriodSelect from '@/components/PeriodSelect/PeriodSelect';
import BodyContainer from '@/layouts/BodyContainer';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import {
  PaymentServiceModelWithId,
  supplyPaymentServiceModelsWithIds,
} from '@/utils/paymentServiceModel';
import { PeriodValues, useDateOptions } from '@/utils/useDateOptions';
import { useQuery } from '@apollo/client';
import LazyTable, { IColumn } from '@components/LazyTable';
import { WIDE_SIDEBAR_STATE } from '@components/client/queries';
import { DomainStatus } from '@components/typings/interfaces';
import { toPrecision } from '@components/utils';
import Button from '@shared/components/Button/Button';
import Flex from '@shared/components/Flex';
import PageError from '@shared/components/PageError';
import Preloader from '@shared/components/Preloader';
import Typography from '@shared/components/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useBalanceStyle } from './Balance.styles';
import {
  BalanceAmountCell,
  BalanceAmountHeaderCell,
  BalanceDateCell,
  BalanceOperationCell,
  BalanceTypeCell,
} from './modules';
import { goToDocumentsIfCurrentDomainStatusIsDemo } from '../utils/goToDocumentsIfCurrentDomainStatusIsDemo';

export const columns: IColumn<PaymentServiceModelWithId>[] = [
  { id: 'name', label: 'OPERATION', Renderer: BalanceOperationCell },
  { id: 'type', width: '15em', minWidth: '15em', label: 'TYPE', Renderer: BalanceTypeCell },
  { id: 'date', width: '10em', minWidth: '10em', label: 'DATE', Renderer: BalanceDateCell },
  {
    id: 'amount',
    label: 'SUMMARY',
    align: 'right',
    Renderer: BalanceAmountCell,
    HeaderRenderer: BalanceAmountHeaderCell,
  },
];

export const Balance = () => {
  const classes = useBalanceStyle();
  const [translate] = useTranslation();
  const [isFillBalanceDialogOpen, setFillBalanceDialogOpen] = useState(false);
  const [isPromisePaymentDialogOpen, setPromisePaymentDialogOpen] = useState(false);

  const navigate = useNavigate();

  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const domain = userData && getCurrentDomain(userData.user);
  const clientType = domain?.client?.type;
  const isClientNotFL = clientType !== 'FL';

  useEffect(() => {
    goToDocumentsIfCurrentDomainStatusIsDemo(navigate, userData);
  }, [navigate, userData]);

  const periodData = useDateOptions([
    PeriodValues.CurrentMonth,
    PeriodValues.PastMonth,
    PeriodValues.Custom,
  ]);
  const startMonth = periodData[PeriodValues.CurrentMonth].from;
  const endMonth = periodData[PeriodValues.CurrentMonth].to;

  const periodRangeDates = {
    from: startMonth,
    to: endMonth,
  };

  const queryData = useMemo(
    () => ({
      from: startMonth.toISOString(),
      to: endMonth.toISOString(),
      offset: null,
      limit: 1000,
    }),
    [startMonth, endMonth]
  );

  const {
    data: balanceDetailsData,
    refetch: refetchBalance,
    loading,
    error,
  } = useQuery(BALANCE_DETAILS_QUERY, {
    variables: queryData,
  });
  const { getReportPaymentsServices: balanceDetails = [] } = balanceDetailsData || {
    getReportPaymentsServices: [],
  };

  const balanceDetailsWithIds = supplyPaymentServiceModelsWithIds(balanceDetails);
  const { data: balanceData } = useQuery(BALANCE_QUERY);

  const { data: sidebarState } = useQuery(WIDE_SIDEBAR_STATE);
  const isWideSidebar = sidebarState?.isWide;
  const {
    getBalance: { balance = 0 },
  } = balanceData || { getBalance: {} };

  const canReplenishBalance = useMemo(
    () =>
      domain && domain?.status !== DomainStatus.Demo && domain?.status !== DomainStatus.Moderating,
    [domain]
  );

  const canPromisePayment = useMemo(
    () =>
      domain &&
      domain?.status !== DomainStatus.Demo &&
      domain?.status !== DomainStatus.Moderating &&
      domain?.status !== DomainStatus.Confirmed,
    [domain]
  );

  function handlePeriodChange(date: { from: Date; to: Date; period: string }) {
    refetchBalance({
      ...queryData,
      from: date.from.toISOString(),
      to: date.to.toISOString(),
    }).then();
  }

  function handlePromisePaymentSuccess() {
    refetchBalance(queryData).then();
  }

  function handleOpenBalanceDialog() {
    setFillBalanceDialogOpen(true);
  }

  function handleCloseBalanceDialog() {
    setFillBalanceDialogOpen(false);
  }

  function handleOpenPromiseDialog() {
    setPromisePaymentDialogOpen(true);
  }

  function handleClosePromiseDialog() {
    setPromisePaymentDialogOpen(false);
  }

  const renderBalance = useMemo(
    () => (
      <Typography type={'text2'} color={balance < 0 ? 'danger600' : 'tertiary900'}>
        {toPrecision(balance)} ₽
      </Typography>
    ),
    [balance]
  );

  const renderEmptyData = () => {
    if (loading)
      return (
        <Flex className={classes.marginTop2} justifyContent={'center'}>
          <Preloader />
        </Flex>
      );
    return (
      <Flex className={classes.rowWrapper} justifyContent={'center'}>
        <Typography type={'text3'}>{translate('NORESULT_BALANCE')}</Typography>
      </Flex>
    );
  };

  const renderBalanceDetails = () => (
    <LazyTable<PaymentServiceModelWithId>
      columns={columns}
      data={balanceDetailsWithIds}
      renderEmptyDataMessage={renderEmptyData()}
    />
  );

  const renderMessages = () => {
    if (canReplenishBalance) {
      return (
        <BalanceDialog
          isFillBalanceDialogOpen={isFillBalanceDialogOpen}
          isPromisePaymentDialogOpen={isPromisePaymentDialogOpen}
          onCloseBalanceDialog={handleCloseBalanceDialog}
          onClosePromiseDialog={handleClosePromiseDialog}
          onPromisePaymentSuccess={handlePromisePaymentSuccess}
        />
      );
    }
    return null;
  };

  return (
    <BodyContainer customRootClass={classes.contentBottomSpace} disableOverflow>
      <Flex direction={'column'} className={classes.root}>
        <Breadcrumbs>
          <div>
            <PeriodSelect<PeriodValues>
              periodName={'currentMonth'}
              periodList={periodData}
              datePeriod={periodRangeDates}
              onPeriodChange={handlePeriodChange}
              position={'right'}
            />
          </div>
        </Breadcrumbs>
        {error ? (
          <PageError />
        ) : (
          <>
            {renderBalanceDetails()}
            {!loading && (
              <div className={classes.balance}>
                <Typography
                  className={isWideSidebar && classes.currentBalanceMargin}
                  type={'text2'}
                  color={'tertiary900'}
                >
                  {translate('CURRENT_BALANCE')}
                </Typography>
                <div className={classes.balanceActions}>
                  <div className={classes.balanceNumber}>{renderBalance}</div>
                  {canReplenishBalance && (
                    <>
                      {canPromisePayment && isClientNotFL && (
                        <div className={classes.balanceControls}>
                          <Button
                            variant={'secondary'}
                            title={translate('PROMISED_PAYMENT')}
                            onClick={handleOpenPromiseDialog}
                          />
                        </div>
                      )}
                      <div className={classes.balanceControls}>
                        <Button
                          title={translate(isClientNotFL ? 'GENERATE_INVOICE' : 'FILL_UP_BALANCE')}
                          onClick={handleOpenBalanceDialog}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {renderMessages()}
          </>
        )}
      </Flex>
    </BodyContainer>
  );
};
