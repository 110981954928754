import {
  REMOVE_EMPLOYEE_REGISTRATIONS_MUTATION,
  UPDATE_EMPLOYEE_MUTATION,
} from '@/client/mutations';
import {
  CHECK_IF_EMAIL_EXIST_LOCAL_QUERY,
  EMPLOYEE_QUERY,
  EMPLOYEE_REGISTRATIONS_QUERY,
  GET_USED_EXTENSIONS_QUERY,
  USER_QUERY,
} from '@/client/queries';
import { useFormErrors } from '@/common/hooks/formErrors.hooks';
import { getCurrentEmployee } from '@/utils/getCurrentEmployee';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { employeeRolesOptions } from '@/features/Employees/EmployeesConstants';
import BodyContainer from '@/layouts/BodyContainer';
import {
  getAllowedEmergenceNumbersStatus,
  getExtLength,
  getFreeExtensions,
  getRole,
  hasSystemRole,
} from '@/utils';
import { normalizePhone } from '@shared/utils';
import { PureQueryOptions, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import CallForwardingFields from '@components/CallForwadingFields';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import EmailField from '@shared/components/EmailField';
import FormErrorMessage from '@components/FormErrorMessage';
import PasswordField from '@shared/components/PasswordField';
import PhoneField from '@shared/components/PhoneField';
import { debounce, Card } from '@material-ui/core';
import ControlButtons from '@shared/components/ControlButtons';
import SelectField from '@shared/components/SelectField';
import Typography from '@shared/components/Typography';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import validator from 'validator';
import Flex from '@shared/components/Flex';
import { add, format } from 'date-fns';
import { formatTime } from '@/features/History/HistoryPage.constants';
import Button from '@shared/components/Button';
import BottomButtons from '@/layouts/BottomButtons';
import MessageDialog from '@shared/components/MessageDialog';
import FormFieldBase from '@shared/components/FormFieldBase';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import ComboBoxField from '@shared/components/ComboBoxField';
import { EmployeeQuery, Role } from '@/client/generated/graphql';
import PreloaderPanel from '@shared/components/PreloaderPanel';
import clsx from 'clsx';
import { DUMMY_PASSWORD } from './Employee.constants';
import { useEmployeeStyles } from './Employee.styles';

type FormData = {
  ext: string | undefined;
  role: string | undefined;

  sipPassword: string | undefined;
  position: string | undefined;
  callForwarding: boolean | undefined;
  callForwardingTimeout: number | undefined;
  login: string | undefined;

  // user fields
  name: string | undefined;
  email: string | undefined;
  password: string | undefined;
  phone: string | undefined;
};

const navigateTimeoutMs = 150;

const employeeToFormData = (employee?: EmployeeQuery['employee']): FormData => {
  return {
    callForwarding: employee?.callForwarding || false,
    callForwardingTimeout: employee?.callForwardingTimeout || 0,
    email: employee?.user.email,
    ext: employee?.ext || undefined,
    login: employee?.login || undefined,
    name: employee?.user?.name || undefined,
    phone: employee?.phone || '',
    position: employee?.position || undefined,
    role: employee?.role || undefined,
    sipPassword: employee?.sipPassword || undefined,
    password: DUMMY_PASSWORD,
  };
};

// TODO (fix types) check whether this is working on not
export const EditEmployee = () => {
  const classes = useEmployeeStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const idMatch = useMatch('/:category/:subcategory/:id');
  const { params: { id = '' } = {} } = idMatch || {};
  const employeeId = Number(id);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });

  const [updateEmployee, { error, loading }] = useMutation(UPDATE_EMPLOYEE_MUTATION);
  const isEmergencyServicesAllowed = getAllowedEmergenceNumbersStatus();
  const extLength = getExtLength();

  const { data: { user } = {} } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const currentEmployee = getCurrentEmployee(user);
  const currentEmployeeRole = currentEmployee?.role;
  const userDomain = user && getCurrentDomain(user)?.domain;

  const formMethods = useForm<FormData>({
    defaultValues: employeeToFormData(undefined),
  });
  useFormErrors(error?.graphQLErrors, formMethods);
  const { reset, handleSubmit, watch, clearErrors, setValue } = formMethods;
  const ext = watch('ext');
  const email = watch('email');
  const password = watch('password');
  const callForwardingEnabled = watch('callForwarding');

  const {
    data: employeeData,
    loading: loadingEmployee,
    error: employeeError,
  } = useQuery(EMPLOYEE_QUERY, {
    variables: {
      id: employeeId,
    },
    onCompleted(data) {
      reset(employeeToFormData(data.employee));
    },
    onError() {
      navigate(blockedPath || '/employee/employees/add');
    },
  });
  const employee = employeeData?.employee;
  const employeeRole = getRole(employee?.role);

  const {
    data: extensionData,
    loading: loadingExtensions,
    error: loadingExtensionsError,
  } = useQuery<{ getUsedExtensions?: { ext: string }[] }>(GET_USED_EXTENSIONS_QUERY);
  const { getUsedExtensions: extensions = undefined } = extensionData || {
    getUsedExtensions: undefined,
  };
  // TODO handle lazy query error
  const [checkIfEmailExist, { data: checkIfEmailExistData }] = useLazyQuery(
    CHECK_IF_EMAIL_EXIST_LOCAL_QUERY,
    { fetchPolicy: 'no-cache' }
  );
  // TODO handle lazy query error
  const [getEmployeeRegistrations, { data: registrationsData }] = useLazyQuery(
    EMPLOYEE_REGISTRATIONS_QUERY,
    { fetchPolicy: 'no-cache' }
  );
  const employeeRegistrations = registrationsData?.employeeRegistrations;
  const [removeEmployeeRegistrations] = useMutation(REMOVE_EMPLOYEE_REGISTRATIONS_MUTATION);

  const { isEmailExistLocal: { exist = undefined, local = undefined } = {} } =
    checkIfEmailExistData || {};

  const isEmailNotificationShow = useMemo(
    () => Boolean(userDomain && email?.includes(userDomain)),
    [email, userDomain]
  );
  const isSystemUser = hasSystemRole(currentEmployeeRole);
  const isSupporter =
    currentEmployeeRole === Role.Supporter || currentEmployeeRole === Role.Sysadmin;

  const isDomainEmail = useMemo(
    () => email && email?.slice(email.lastIndexOf('@') + 1) === userDomain,
    [email, userDomain]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCheckIfEmailExist = useCallback(
    debounce((value: string) => {
      clearErrors('email');
      checkIfEmailExist({ variables: { email: value } });
    }, 300),
    []
  );

  const extChange = (value?: string) => {
    const computedExt = value || ext;
    if ((userDomain && isDomainEmail) || !email?.length)
      setValue('email', `${computedExt}@${userDomain}`);
  };

  useEffect(() => {
    if (email && validator.isEmail(email)) {
      debouncedCheckIfEmailExist(email);
    }
  }, [debouncedCheckIfEmailExist, email]);

  useEffect(() => {
    if (isSystemUser) {
      getEmployeeRegistrations({
        variables: {
          id: employeeId,
        },
      });
    }
  }, [isSystemUser, employeeId, getEmployeeRegistrations]);

  const { extensionsOptions = [] } =
    loadingExtensions || loadingExtensionsError || !extensions
      ? { extensionsOptions: [] }
      : getFreeExtensions(extensions, isEmergencyServicesAllowed, extLength);

  const isNotAbleToChangeRoles =
    currentEmployee?.id === employeeId ||
    (currentEmployeeRole === Role.Admin &&
      employeeRole === Role.Owner &&
      !hasSystemRole(currentEmployee?.role));

  let availableRoles = [...employeeRolesOptions];
  if (currentEmployeeRole === Role.Admin) {
    availableRoles = availableRoles.filter((r) => r.value !== Role.Owner);
  }

  const handleSubmitForm = (formData: FormData) => {
    if (loading) {
      return;
    }

    const refetchQueries: (string | PureQueryOptions)[] = [
      {
        query: EMPLOYEE_QUERY,
        variables: {
          id: Number.isFinite(Number(employeeId)) ? Number(employeeId) : undefined,
        },
      },
    ];
    if (employeeId === user?.id) {
      refetchQueries.push({ query: USER_QUERY });
    }
    updateEmployee({
      variables: {
        data: {
          id: employeeId,
          callForwarding: formData.callForwarding,
          callForwardingTimeout: formData.callForwardingTimeout,
          ext: formData.ext,
          // `|| null` prevents empty string from sending to a backend
          login: formData.login || null,
          phone: normalizePhone(formData.phone),
          position: formData.position,
          role: getRole(formData.role),
          sipPassword: formData.sipPassword,
          user: {
            email: formData.email,
            name: formData.name,
            // `|| undefined` prevents empty string from becoming a valid value on backend
            password:
              formData.password === DUMMY_PASSWORD ? undefined : formData.password || undefined,
          },
        },
      },
      refetchQueries,
    }).then(() => {
      if (
        ext !== user?.email?.slice(0, ext?.length) &&
        employee?.user?.email !== formData.email &&
        isChanged.isBlocking
      ) {
        setIsModalOpen(true);
        return;
      }
      setIsChanged({
        isBlocking: false,
        action: ConfirmAction.Finish,
      });
      setTimeout(() => {
        navigate(blockedPath || '/employee/employees');
      }, navigateTimeoutMs);
    });
  };

  const setBlockedPathWrapped = (path: string) => {
    setBlockedPath(path);
  };

  const handleFormChange = () => {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  };

  const handleCancelChanges = () => {
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Cancel,
    });
    setTimeout(() => {
      navigate(blockedPath || '/employee/employees');
    }, navigateTimeoutMs);
  };

  const removeRegistrations = () => {
    removeEmployeeRegistrations({
      variables: {
        id: employeeId,
      },
    }).then(() => {
      setTimeout(() => {
        getEmployeeRegistrations({
          variables: {
            id: employeeId,
          },
        });
      }, 200);
    });
  };

  const handleCloseClick = () => {
    setIsModalOpen(false);
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
    setTimeout(() => {
      navigate(blockedPath || '/employee/employees');
    }, navigateTimeoutMs);
  };

  const handleEmailChange = (value: string) => {
    handleFormChange();
    if (value.slice(value.lastIndexOf('@') + 1) === userDomain) {
      setValue('email', `${ext}@${userDomain}`);
    }
  };

  const handleExtChange = (value: string) => {
    handleFormChange();
    extChange(value);
  };

  const renderSipRegistrations = () => (
    <>
      <Typography type={'text2'} color={'tertiary900'} className={classes.title}>
        {translate('SIP_REGISTRATIONS')}
      </Typography>
      <Card className={classes.balloonCard} elevation={0}>
        {employeeRegistrations?.contacts?.length ? (
          <>
            {employeeRegistrations.contacts.map((contact, index) => (
              <Card key={`sip-card-${index}`} className={classes.registrationCard} elevation={0}>
                <Flex key={`sip-reg-${index}`} direction={'column'}>
                  <Flex key={`sip-contact-${index}`} direction={'row'}>
                    <Typography
                      type={'text4'}
                      color={'tertiary900'}
                      bold
                      className={classes.registrationHeader}
                    >
                      {`${translate('SIP_CONTACT')}: `}
                    </Typography>
                    <Typography
                      type={'text4'}
                      color={'tertiary900'}
                      className={classes.registrationValue}
                    >
                      {contact.contact}
                    </Typography>
                  </Flex>
                  <Flex key={`sip-ua-${index}`} direction={'row'}>
                    <Typography
                      type={'text4'}
                      color={'tertiary900'}
                      bold
                      className={classes.registrationHeader}
                    >
                      {`${translate('USER_AGENT')}: `}
                    </Typography>
                    <Typography
                      type={'text4'}
                      color={'tertiary900'}
                      className={classes.registrationValue}
                    >
                      {contact.userAgent}
                    </Typography>
                  </Flex>
                  <Flex key={`sip-expires-${index}`} direction={'row'}>
                    <Typography
                      type={'text4'}
                      color={'tertiary900'}
                      bold
                      className={classes.registrationHeader}
                    >
                      {`${translate('ACTIVE_TILL')}: `}
                    </Typography>
                    <Typography
                      type={'text4'}
                      color={'tertiary900'}
                      className={classes.registrationValue}
                    >
                      {`${format(
                        add(new Date(), {
                          seconds: contact.expires,
                        }),
                        formatTime
                      )}`}
                    </Typography>
                  </Flex>
                </Flex>
              </Card>
            ))}
            <Flex key={'sip-via'} direction={'row'} className={classes.via}>
              <Typography
                type={'text4'}
                color={'tertiary900'}
                bold
                className={classes.registrationHeader}
              >
                {`${translate('SIP_REGISTRATION_VIA')}:`}
              </Typography>
              <Typography
                type={'text4'}
                color={'tertiary900'}
                className={classes.registrationValue}
              >
                {employeeRegistrations.via}
              </Typography>
            </Flex>
            <Flex justifyContent={'flexEnd'}>
              <Button
                title={translate('REMOVE_SIP_REGISTRATIONS')}
                variant={'text'}
                className={classes.textUnderline}
                onClick={removeRegistrations}
              />
            </Flex>
          </>
        ) : (
          <Typography type={'text3'} color={'tertiary600'} className={classes.title}>
            {translate('NO_SIP_REGISTRATIONS')}
          </Typography>
        )}
      </Card>
    </>
  );

  return (
    <BodyContainer customRootClass={classes.contentBottomSpace}>
      <PreloaderPanel className={clsx(!loadingEmployee && classes.hidden)} />
      <div className={clsx(classes.root, loadingEmployee && classes.hidden)}>
        <FormProvider {...formMethods}>
          <form
            id={'edit-employee-form'}
            action={''}
            onSubmit={handleSubmit(handleSubmitForm)}
            className={classes.form}
            noValidate
          >
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('EMPLOYEE_DATA')}
            </Typography>
            <div className={classes.formContent}>
              <FormFieldRhfUncontrolled
                name={'name'}
                label={translate('NAME')}
                inputProps={{
                  maxLength: 100,
                }}
                validate={(value: string) =>
                  value === '' ? (translate('ENTER_NAME') as string) : true
                }
                onChange={handleFormChange}
              />
              <EmailField
                name={'email'}
                label={translate('EMAIL')}
                required
                validate={(value: string) => {
                  if (value && value !== employee?.user?.email && (exist || local)) {
                    return translate('EMAIL_ALREADY_REGISTERED');
                  }
                  return true;
                }}
                onChange={(e) => handleEmailChange(e.target.value)}
              />
              {isEmailNotificationShow && (
                <div className={classes.notificationBlock}>
                  <Typography type={'text4'} color={'tertiary900'}>
                    {translate('EMAIL_GENERATE_AUTOMATICALLY')}
                  </Typography>
                </div>
              )}
              <div className={classes.itemWrapper}>
                <PasswordField
                  name={'password'}
                  label={translate('PASSWORD')}
                  onClick={() => {
                    if (password === DUMMY_PASSWORD) setValue('password', '');
                  }}
                  onChange={handleFormChange}
                  scoring
                  generation
                />
              </div>
              {isSystemUser && (
                <div className={classes.itemWrapper}>
                  <PasswordField
                    name={'sipPassword'}
                    label={translate('SIP_PASSWORD')}
                    scoring
                    generation
                    onChange={handleFormChange}
                  />
                </div>
              )}
              {isNotAbleToChangeRoles ? (
                <FormFieldBase
                  label={translate('ROLE')}
                  value={translate(employee?.role || '')}
                  InputProps={{ readOnly: true }}
                />
              ) : (
                <SelectField
                  name={'role'}
                  label={translate('ROLE')}
                  defaultValue={employeeRolesOptions[1].value}
                  valueKey={'value'}
                  titleKey={'titleCode'}
                  translating
                  data={availableRoles}
                  onChange={handleFormChange}
                />
              )}
              {(employee?.login || isSupporter) && (
                <FormFieldRhfUncontrolled
                  name={'login'}
                  label={translate('LOGIN')}
                  InputProps={{ readOnly: !isSupporter }}
                />
              )}
              <ComboBoxField
                name="ext"
                valueKey="ext"
                freeSolo
                maxLength={extLength}
                showOptionsIfEmpty={false}
                data={extensionsOptions}
                placeholder={translate('CHOOSE')}
                label={translate('EXTENSION_NUMBER')}
                onChange={(_e, val) => {
                  if (typeof val === 'string') {
                    handleExtChange(val);
                  }
                }}
                onInputChange={(_e, val, reason) => {
                  // reset means than input was changed programmatically, not by user
                  if (reason === 'reset') return;
                  handleExtChange(val);
                }}
                validate={(extValue: string) => {
                  const value = Number(extValue);
                  if (!extValue) {
                    return translate('EXTENSION_NUMBER_REQUIRED') as string;
                  }
                  if (extValue.length !== extLength) {
                    return translate('EXTENSION_NUMBER_WRONG_LENGTH', { length: extLength });
                  }
                  if (Number.isNaN(value)) {
                    return translate('INVALID_EXTENSION');
                  }
                  if (value >= 100 && value <= 199 && !isEmergencyServicesAllowed) {
                    return translate('EXTENSION_NUMBER_FOR_EMERGENCY') as string;
                  }
                  return true;
                }}
                filterOptions={(options, { inputValue }) => {
                  return options.filter((option) => option.ext.startsWith(inputValue));
                }}
              />
              <FormFieldRhfUncontrolled
                name={'position'}
                label={translate('POSITION')}
                onChange={handleFormChange}
                inputProps={{
                  maxLength: 100,
                }}
              />
              <PhoneField
                name={'phone'}
                label={translate('MOBILE')}
                onChange={handleFormChange}
                required={callForwardingEnabled}
              />
              <div className={classes.formContentWrapper}>
                <Typography type={'text2'} color={'tertiary900'} className={classes.title}>
                  {translate('REDIRECT_TO_DEVICES')}
                </Typography>
                <CallForwardingFields
                  onChange={handleFormChange}
                  className={classes.defaultElementWidth20}
                />
              </div>
              {isSystemUser && renderSipRegistrations()}
            </div>
            <BottomButtons>
              <div className={classes.actions}>
                <ControlButtons
                  confirmTitle={'SAVE_CHANGES'}
                  cancelTitle={'CANCEL'}
                  form={'edit-employee-form'}
                  onCancelClick={handleCancelChanges}
                  rootConfirmStyles={classes.controlButton}
                  loading={loading}
                />
              </div>
            </BottomButtons>
            <FormErrorMessage
              errors={[
                ...(error?.graphQLErrors || []),
                ...(employeeError?.graphQLErrors || []),
                ...(loadingExtensionsError?.graphQLErrors || []),
              ]}
            />
          </form>
          <MessageDialog
            isOpen={isModalOpen}
            title={translate('EMAIL_CHANGE_SUCCESS')}
            contentClass={classes.dialog}
            onCancel={handleCloseClick}
            renderContent={
              <Flex justifyContent={'flexStart'} alignItems={'center'} direction={'column'}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('EMAIL_WAS_CHANGED')}
                </Typography>
                <div className={classes.btnControlls}>
                  <Button
                    title={translate('CLOSE')}
                    variant={'primary'}
                    onClick={handleCloseClick}
                    smallHeight
                  />
                </div>
              </Flex>
            }
            disableEnforceFocus
          />
          <ConfirmDialog
            isBlocked={isChanged.isBlocking}
            onNavigationBlocked={setBlockedPathWrapped}
            onSaveChanges={handleSubmit(handleSubmitForm)}
          />
        </FormProvider>
      </div>
    </BodyContainer>
  );
};

export default EditEmployee;
