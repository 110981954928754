import React, { FunctionComponent, useMemo, useRef, useState, Fragment } from 'react';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import {
  CLIENT_QUERY,
  DOMAIN_ALL_NUMBERS_QUERY,
  GET_SELECTED_TARIFF_QUERY,
  USER_QUERY,
} from '@/client/queries';
import { format } from 'date-fns';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { getNumbersPrices } from '@/utils/getNumbersPrices';
import Button from '@shared/components/Button/Button';
import { MOVE_TO_MODERATING_MUTATION } from '@/client/mutations';
import { toPrecision } from '@components/utils/toPrecision';
import { useNavigate } from 'react-router-dom';
import MessageDialog from '@shared/components/MessageDialog';
import Translate from '@shared/components/Translate';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import clsx from 'clsx';
import { GET_CONTRACT } from '@components/client/mutations';
import ControlButtons from '@shared/components/ControlButtons';
import { getStorageItem } from '@components/storage/storage';
import { NumberType, DomainStatus } from '@components/typings/interfaces';
import { getAccountsPrices, getTariffExactOptions } from '@/utils/tariff';
import Flex from '@shared/components/Flex';
import { ContractLayout } from '@/features/Documents';
import { OptionType, TariffOptionCategory } from '@/client/generated/graphql';
import { FLFieldList, IPFieldList, scenarioList, ULFieldList } from '../Contract.constants';
import { confirmAssistantList, scenarioListType } from '../Contract.interfaces';
import { useContractStyle } from '../Contract.styles';

export const ContractConfirm: FunctionComponent = () => {
  const classes = useContractStyle();
  const navigate = useNavigate();
  const [translate] = useTranslation();

  const [showConfirm, setShowConfirm] = useState(false);
  const [isPromptOpen, setIsPromptOpen] = useState(false);

  const { data: clientData } = useQuery(CLIENT_QUERY);
  const { data: selectedTariffData } = useQuery(GET_SELECTED_TARIFF_QUERY);
  const { data: reservedNumberListData } = useQuery(DOMAIN_ALL_NUMBERS_QUERY);
  const { data: userData, refetch } = useQuery(USER_QUERY, { fetchPolicy: 'no-cache' });

  const [getContract, { loading: getContractLoading }] = useMutation(GET_CONTRACT);
  const [updateClientPassport, { loading: loadingUpdateClient }] = useMutation(
    MOVE_TO_MODERATING_MUTATION
  );

  const tariff = selectedTariffData?.getDomain?.tariff;
  const reservedNumberList = reservedNumberListData?.domainNumbers || [];
  const individualPassport = clientData?.getClient?.individualPassport;
  const legalPassport = clientData?.getClient?.legalPassport;
  const type = clientData?.getClient?.type;
  const params = clientData?.getClient?.params;
  const domains = clientData?.getClient?.domains;

  const domainData = getCurrentDomain(userData?.user);
  const numbersList = reservedNumberList.filter((e) => e.type !== NumberType.Demo);

  const rootRef = useRef<HTMLDivElement | null>(null);

  const isSiblingDomainCommercial = domains?.some(
    (item: { status: DomainStatus }) => item.status === DomainStatus.Commercial
  );

  const assistants = useMemo(() => domainData?.settings?.assistants || [], [domainData]);

  const formattedAssistantList = useMemo(() => {
    if (assistants) {
      return scenarioList.reduce(
        (result: confirmAssistantList[], scenarioItem: scenarioListType) => {
          const filteredItem = assistants.find(
            (i: { type: string }) => i.type === scenarioItem.assistantType
          );
          if (filteredItem?.count) {
            result.push({
              name: scenarioItem.title,
              amount: filteredItem?.count,
              monthPrice: scenarioItem.flexiblePrice ? 0 : scenarioItem.monthPrice,
              fixedPrice: scenarioItem.installationPrice || 0,
            });
          }
          return result;
        },
        []
      );
    }
    return null;
  }, [assistants]);

  const selectedOptionsList = useMemo(() => {
    if (!tariff) return [];
    return tariff.options.filter(
      (opt) =>
        opt.category === TariffOptionCategory.Additional &&
        opt.type !== OptionType.AdditionalAccounts &&
        opt.type !== OptionType.Accounts
    );
  }, [tariff]);

  function handleClickSendToModeration() {
    if (loadingUpdateClient) return;
    setShowConfirm(false);
    updateClientPassport().then(() => {
      refetch().then(() => {
        navigate('/documents/documents-list');
      });
    });
  }

  function handleShowPopupDialog() {
    setShowConfirm(true);
  }

  function handleDocumentViewClick() {
    getContract().then((res) => {
      const url = process.env.API_URL;
      const token = getStorageItem('token');
      window.open(`${url}/files/view?filename=${res.data.getContract}&token=${token}`);
    });
  }

  function handleClosePopupDialog() {
    setShowConfirm(false);
  }

  const renderContractPersonalData = () => {
    const passportData = type === 'FL' ? individualPassport : legalPassport;
    const dataObject = {
      ...passportData,
      phones: params?.contacts?.phones?.[0],
      emails: params?.contacts?.emails?.[0],
    };

    const renderTypographyItem = (label: string, field: string) => (
      <div className={classes.personalDataContactTitle}>
        <Typography type={'text4'} color={'tertiary700'}>
          {label}
        </Typography>
        <div className={classes.personalDataItem}>
          <Typography type={'text3'} color={'tertiary900'}>
            {field}
          </Typography>
        </div>
      </div>
    );

    // eslint-disable-next-line no-nested-ternary
    const fieldList = type === 'FL' ? FLFieldList : type === 'IP' ? IPFieldList : ULFieldList;
    return fieldList.map((item, index) => (
      <Fragment key={`title-${index}`}>
        <div className={classes.fieldTitle}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate(item.labelName)}
          </Typography>
        </div>
        <div className={classes.personalDataContainer}>
          {item.fields.map((field, fieldIndex) => {
            const labelText = translate(field.labelName);
            const fieldItem = dataObject[field.fieldName as keyof typeof dataObject];
            const fieldText = (): string => {
              if (field.format === 'raw') {
                if (type === 'FL') {
                  return translate('INDIVIDUAL_TYPE');
                }
                if (type === 'UL') {
                  return translate('LEGAL_TYPE');
                }
                return translate('BUSINESSMAN_TYPE');
              }
              if (field.format === 'date') {
                if (!fieldItem) {
                  return '-';
                }
                return format(new Date(fieldItem), 'dd/MM/yyyy');
              }
              if (fieldItem === 'male' || fieldItem === 'female') {
                return translate(fieldItem.toUpperCase());
              }
              return dataObject[field.fieldName as keyof typeof dataObject] as string;
            };
            return (
              <div key={`opt-${fieldIndex}`}>{renderTypographyItem(labelText, fieldText())}</div>
            );
          })}
        </div>
      </Fragment>
    ));
  };

  const renderIndividualPrompt = () => {
    if (isPromptOpen) {
      return (
        <div className={classes.contractConfirmIndividualPrompt}>
          <Typography type={'text3'} color={'tertiary900'}>
            {translate('INDIVIDUAL_PROMPT')}
          </Typography>
        </div>
      );
    }
    return null;
  };

  const renderContractTariffData = () => {
    if (tariff) {
      const {
        totalEmployeeCount,
        baseEmployeeCount,
        additionalEmployeeCount,
        accountsOption,
        additionalAccountsOption,
      } = getTariffExactOptions(tariff?.options || []);

      const {
        isAccountDiscount,
        isAdditionalAccountDiscount,
        accountPrice,
        baseAccountPrice,
        additionalAccountPrice,
        baseAdditionalAccountPrice,
      } = getAccountsPrices(accountsOption, additionalAccountsOption);

      const domainsPrice =
        (isAccountDiscount ? accountPrice : baseAccountPrice) * baseEmployeeCount +
        (isAdditionalAccountDiscount ? additionalAccountPrice : baseAdditionalAccountPrice) *
          additionalEmployeeCount;

      const baseDomainsPrice =
        baseAccountPrice * baseEmployeeCount + baseAdditionalAccountPrice * additionalEmployeeCount;

      return (
        <div className={classes.confirmServiceGrid}>
          <div className={classes.tariffPlanContainer}>
            <Typography type={'text3'} color={'base'}>
              {`${translate('TARIFF_PLAN')} "${tariff.name}"`}
            </Typography>
          </div>
          <div className={classes.confirmHeadingRow}>
            <div className={classes.flexBlock}>
              <div className={classes.defaultElementWidth19}>
                <Typography type={'text4'} color={'tertiary700'}>
                  {translate('IN_COMPANY')}
                </Typography>
              </div>
              <div className={clsx(classes.defaultElementWidth7, classes.textAlignRight)}>
                <Typography type={'text4'} color={'tertiary500'}>
                  {translate('AMOUNT')}
                </Typography>
              </div>
            </div>
            <div className={classes.flexBlock}>
              <div className={clsx(classes.defaultElementWidth8, classes.textAlignRight)}>
                <Typography type={'text4'} color={'tertiary500'}>
                  {translate('ONE_TIME_WRITE_OFF')}
                </Typography>
              </div>
              <div className={clsx(classes.defaultElementWidth12, classes.textAlignRight)}>
                <Typography type={'text4'} color={'tertiary500'}>
                  {translate('MONTH_PRICE')}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.confirmGridColoredRow}>
            <div className={classes.flexBlock}>
              <div className={classes.defaultElementWidth19}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('EMPLOYEES')}
                </Typography>
              </div>
              <div className={clsx(classes.defaultElementWidth7, classes.textAlignRight)}>
                <Typography type={'text3'} color={'tertiary600'}>
                  {totalEmployeeCount}
                </Typography>
              </div>
            </div>
            <div className={classes.flexBlock}>
              <div className={clsx(classes.defaultElementWidth8, classes.textAlignRight)}>
                <Typography type={'text3'} color={'tertiary900'}>
                  -
                </Typography>
              </div>
              <div className={clsx(classes.defaultElementWidth12, classes.textAlignRight)}>
                <Flex direction={'column'}>
                  <Typography
                    type={'text3'}
                    color={
                      isAccountDiscount || isAdditionalAccountDiscount
                        ? 'primary700'
                        : 'tertiary900'
                    }
                    bold
                  >
                    {translate('RUB_PER_MONTH_SHORT', { amount: toPrecision(domainsPrice, false) })}
                  </Typography>
                  {isAccountDiscount || isAdditionalAccountDiscount ? (
                    <Typography
                      className={classes.oldPriceTextStyle}
                      type={'text3'}
                      color={'tertiary900'}
                    >
                      {translate('RUB_PER_MONTH_SHORT', {
                        amount: toPrecision(baseDomainsPrice, false),
                      })}
                    </Typography>
                  ) : null}
                </Flex>
              </div>
            </div>
          </div>
          {selectedOptionsList.map((sOption, sIndex) => {
            const isIntegrationOption = sOption.type === OptionType.Integrations;
            const integrationsAmount =
              (sOption.domainTariffOptionParams.count || 1) / totalEmployeeCount;
            const optionAccountAmount = isIntegrationOption
              ? totalEmployeeCount
              : sOption.domainTariffOptionParams.count;
            const baseOptionPrice = sOption.baseMonthlyFee || 0;
            const optionPrice = sOption.monthlyFee || 0;
            const isDiscountOption = optionPrice !== baseOptionPrice;
            return (
              <div key={`${sOption.type}-${sIndex}`} className={classes.confirmGridColoredRow}>
                <div className={classes.flexBlock}>
                  <div className={classes.defaultElementWidth19}>
                    <Typography type={'text3'} color={'tertiary900'}>
                      {sOption.name}
                      {isIntegrationOption ? (
                        <Typography type={'default'} color={'tertiary500'}>
                          {` (${translate('INTEGRATIONS_AMOUNT', { amount: integrationsAmount })})`}
                        </Typography>
                      ) : null}
                    </Typography>
                  </div>
                  <div className={clsx(classes.defaultElementWidth7, classes.textAlignRight)}>
                    <Typography type={'text3'} color={'tertiary600'}>
                      {optionAccountAmount}
                    </Typography>
                  </div>
                </div>
                <div className={classes.flexBlock}>
                  <div className={clsx(classes.defaultElementWidth8, classes.textAlignRight)}>
                    <Typography type={'text3'} color={'tertiary900'}>
                      -
                    </Typography>
                  </div>
                  <div className={clsx(classes.defaultElementWidth12, classes.textAlignRight)}>
                    <Flex direction={'column'}>
                      <Typography
                        type={'text3'}
                        color={isDiscountOption ? 'primary700' : 'tertiary900'}
                        bold={isDiscountOption}
                      >
                        {translate('RUB_PER_MONTH_SHORT', {
                          amount: toPrecision(
                            (sOption.domainTariffOptionParams.count || 0) * optionPrice,
                            false
                          ),
                        })}
                      </Typography>
                      {isDiscountOption ? (
                        <Typography
                          className={classes.oldPriceTextStyle}
                          type={'text3'}
                          color={'tertiary900'}
                        >
                          {translate('RUB_PER_MONTH_SHORT', {
                            amount: toPrecision(
                              (sOption.domainTariffOptionParams.count || 0) * baseOptionPrice,
                              false
                            ),
                          })}
                        </Typography>
                      ) : null}
                    </Flex>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return <div />;
  };

  const renderContractScenariosData = () => {
    if (formattedAssistantList?.length) {
      return (
        <>
          <div className={classes.confirmTariffRow}>
            <Typography type={'text4'} color={'tertiary700'}>
              {translate('SCENARIOS')}:
            </Typography>
          </div>
          <div className={classes.confirmServiceGrid}>
            {formattedAssistantList.map((assistant: confirmAssistantList, index: number) => (
              <div key={`scenario-${index}`} className={classes.confirmGridColoredRow}>
                <div className={classes.flexBlock}>
                  <div className={classes.defaultElementWidth19}>
                    {assistant.name === 'SCENARIOS_BASE_TITLE' && (
                      <Typography type={'text3'} color={'tertiary900'}>
                        {assistant.amount <= 1
                          ? translate('SCENARIO_BASE_TITLE')
                          : translate('SCENARIOS_BASE_TITLE')}
                      </Typography>
                    )}
                    {assistant.name === 'SCENARIOS_INDIVIDUAL_TITLE' && (
                      <Typography
                        type={'text3'}
                        color={'tertiary900'}
                        underline
                        onMouseEnter={() => setIsPromptOpen(true)}
                        onMouseLeave={() => setIsPromptOpen(false)}
                      >
                        {assistant.amount <= 1
                          ? translate('SCENARIO_INDIVIDUAL_TITLE')
                          : translate('SCENARIOS_INDIVIDUAL_TITLE')}
                      </Typography>
                    )}
                  </div>
                  <div className={clsx(classes.defaultElementWidth7, classes.textAlignRight)}>
                    <Typography type={'text3'} color={'tertiary600'}>
                      {assistant.amount}
                    </Typography>
                  </div>
                </div>
                <div className={classes.flexBlock}>
                  <div className={clsx(classes.defaultElementWidth8, classes.textAlignRight)}>
                    <Typography type={'text3'} color={'tertiary900'}>
                      {assistant.fixedPrice !== 0
                        ? `${toPrecision(assistant.fixedPrice * assistant.amount, false)} ₽`
                        : '-'}
                    </Typography>
                  </div>
                  <div className={clsx(classes.defaultElementWidth12, classes.textAlignRight)}>
                    <Typography type={'text3'} color={'tertiary900'}>
                      {assistant.fixedPrice !== 0
                        ? `${translate('RUB_PER_MONTH_SHORT', {
                            amount: toPrecision(assistant.monthPrice * assistant.amount, false),
                          })}`
                        : '-'}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
            {renderIndividualPrompt()}
          </div>
        </>
      );
    }
    return <div />;
  };

  const renderContractNumbersData = () => {
    if (numbersList.length !== 0) {
      return (
        <>
          <div className={classes.confirmTariffRow}>
            <Typography type={'text4'} color={'tertiary700'}>
              {translate('NUMBERS')}:
            </Typography>
          </div>
          <div className={classes.confirmServiceGrid}>
            {numbersList.map((phoneItem, index) => (
              <div key={`phone-${index}`} className={classes.confirmGridColoredRow}>
                <div className={classes.flexBlock}>
                  <div className={classes.defaultElementWidth15}>
                    <Typography type={'text3'} color={'tertiary900'}>
                      {formatPhone(phoneItem.phone)}
                    </Typography>
                  </div>
                  <div className={classes.defaultElementWidth7}>
                    <Typography type={'text3'} color={'tertiary600'}>
                      {translate(`NUMBER_${phoneItem.category}`)}
                    </Typography>
                  </div>
                </div>
                <div className={classes.flexBlock}>
                  <Flex direction={'column'}>
                    <Typography
                      type={'text3'}
                      color={phoneItem.tariff?.setupDiscount ? 'primary700' : 'tertiary900'}
                      bold={!!phoneItem.tariff?.setupDiscount}
                    >
                      {`${toPrecision(phoneItem.tariff?.setupFee || 0, false)} ₽`}
                    </Typography>
                    {phoneItem.tariff?.setupDiscount ? (
                      <Typography
                        className={classes.oldPriceTextStyle}
                        type={'text3'}
                        color={'tertiary900'}
                      >
                        {`${toPrecision(phoneItem.tariff?.baseSetupFee || 0, false)} ₽`}
                      </Typography>
                    ) : null}
                  </Flex>
                  <div className={clsx(classes.defaultElementWidth12, classes.textAlignRight)}>
                    <Typography type={'text3'} color={'tertiary900'}>
                      {translate('RUB_PER_MONTH_SHORT', {
                        amount: toPrecision(phoneItem.tariff?.monthlyFee || 0, false),
                      })}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      );
    }

    return <div />;
  };

  const renderContractTotalsData = () => {
    let monthPrice = 0;
    let fixedPrice = 0;
    let baseFixedPrice = 0;
    let baseMonthPrice = 0;

    if (tariff) {
      const {
        totalEmployeeCount,
        baseEmployeeCount,
        additionalEmployeeCount,
        accountsOption,
        additionalAccountsOption,
      } = getTariffExactOptions(tariff?.options || []);

      const domainPrice = accountsOption?.monthlyFee || 0;
      const baseDomainPrice = accountsOption?.baseMonthlyFee || 0;

      const addDomainPrice = additionalAccountsOption?.monthlyFee || 0;
      monthPrice += domainPrice * baseEmployeeCount + addDomainPrice * additionalEmployeeCount;
      baseMonthPrice +=
        baseDomainPrice * baseEmployeeCount + addDomainPrice * additionalEmployeeCount;

      selectedOptionsList.forEach((sOption) => {
        if (sOption.params.perEmployee) {
          if (sOption.type === OptionType.Integrations) {
            const multiplier = (sOption?.domainTariffOptionParams?.count || 0) / totalEmployeeCount;
            monthPrice += (sOption.monthlyFee || 0) * totalEmployeeCount * multiplier;
            baseMonthPrice += (sOption.baseMonthlyFee || 0) * totalEmployeeCount * multiplier;
          } else {
            monthPrice += (sOption.monthlyFee || 0) * totalEmployeeCount;
            baseMonthPrice += (sOption.baseMonthlyFee || 0) * totalEmployeeCount;
          }
        } else {
          monthPrice += sOption.monthlyFee || 0;
          baseMonthPrice += sOption.baseMonthlyFee || 0;
        }
      });
    }

    if (numbersList) {
      const {
        fixedPrice: numbersFixPrice = 0,
        monthlyPrice: numbersMonthPrice = 0,
        baseSetupPrice: setupPriceWithoutDiscount = 0,
        baseMonthPrice: monthPriceWithoutDiscount = 0,
      } = getNumbersPrices(numbersList);
      monthPrice += numbersMonthPrice;
      fixedPrice += numbersFixPrice;
      baseFixedPrice += setupPriceWithoutDiscount;
      baseMonthPrice += monthPriceWithoutDiscount;
    }

    if (formattedAssistantList) {
      formattedAssistantList.forEach((item: confirmAssistantList) => {
        monthPrice += item.monthPrice * item.amount;
        fixedPrice += item.fixedPrice * item.amount;
        baseFixedPrice += item.fixedPrice * item.amount;
        baseMonthPrice += item.monthPrice * item.amount;
      });
    }

    return (
      <div className={classes.confirmServiceGrid}>
        <div className={classes.confirmServiceTotalRow}>
          <div className={clsx(classes.confirmHeadingRow, classes.confirmHeadingRowTotal)}>
            <div className={classes.defaultElementWidth21}>
              <Typography type={'text3'} color={'tertiary900'} bold>
                {translate('TOTAL')}
              </Typography>
            </div>
            <div className={classes.flexBlock}>
              <div className={clsx(classes.defaultElementWidth8, classes.textAlignRight)}>
                <Flex direction={'column'}>
                  <Typography type={'text3'} color={'tertiary900'} bold>
                    {toPrecision(fixedPrice, false)} ₽
                  </Typography>
                  {baseFixedPrice !== fixedPrice ? (
                    <Typography
                      className={classes.oldPriceTextStyle}
                      type={'text3'}
                      color={'tertiary600'}
                    >
                      {`${toPrecision(baseFixedPrice, false)} ₽`}
                    </Typography>
                  ) : null}
                </Flex>
              </div>
              <div className={clsx(classes.defaultElementWidth12, classes.textAlignRight)}>
                <Flex direction={'column'}>
                  <Typography type={'text3'} color={'tertiary900'} bold>
                    {translate('RUB_PER_MONTH_SHORT', { amount: toPrecision(monthPrice, false) })}
                  </Typography>
                  {baseMonthPrice !== monthPrice ? (
                    <Typography
                      className={classes.oldPriceTextStyle}
                      type={'text3'}
                      color={'tertiary600'}
                    >
                      {translate('RUB_PER_MONTH_SHORT', {
                        amount: toPrecision(baseMonthPrice, false),
                      })}
                    </Typography>
                  ) : null}
                </Flex>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ContractLayout isLoading={loadingUpdateClient} childrenHeight={rootRef?.current?.clientHeight}>
      <div ref={rootRef} className={classes.personalDataRoot}>
        {!isSiblingDomainCommercial && (
          <>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('CONTRACT_FORMED')}
            </Typography>
            <div className={classes.phoneNumberTextContainer}>
              <Translate
                i18nKey="CONTRACT_FORMED_NOTE_02"
                components={{
                  t: <Typography type={'text3'} color={'tertiary900'} />,
                  b: <br />,
                }}
              />
            </div>
          </>
        )}
        <div className={classes.contractConfirmContent}>{renderContractPersonalData()}</div>
        <Typography type={'text2'} color={'tertiary900'}>
          {translate('SELECTED_SERVICES')}
        </Typography>
        <div className={classes.confirmPaddingBlock}>
          <div className={classes.confirmDataBlock}>
            {renderContractTariffData()}
            {renderContractScenariosData()}
            {renderContractNumbersData()}
            {renderContractTotalsData()}
          </div>
        </div>
        <div className={classes.confirmBottomContainer}>
          <div className={classes.actionsContainer}>
            {!isSiblingDomainCommercial && (
              <Button
                classes={{ root: classes.defaultElementWidth4 }}
                variant={'tertiary'}
                loading={getContractLoading}
                disabled={loadingUpdateClient}
                onClick={() => handleDocumentViewClick()}
              >
                {translate('VIEW_DOCUMENT')}
              </Button>
            )}
            <Button
              title={translate('SEND_TO_MODERATION')}
              className={classes.confirmBtn}
              variant={'primary'}
              onClick={() => handleShowPopupDialog()}
              disabled={getContractLoading}
              loading={loadingUpdateClient}
            />
          </div>
        </div>
        <MessageDialog
          isOpen={showConfirm}
          title={translate('CANCEL_CHANGES_PROMPT')}
          onCancel={handleClosePopupDialog}
          contentClass={classes.confirmPopupDialog}
          renderContent={
            <>
              <div className={classes.confirmPopupText}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('CONTRACT_FORMED_NOTE_03')}
                </Typography>
              </div>
              <ControlButtons
                confirmTitle={'CONFIRM'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                onConfirmClick={() => handleClickSendToModeration()}
                onCancelClick={handleClosePopupDialog}
                loading={loadingUpdateClient}
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                small
              />
            </>
          }
        />
      </div>
    </ContractLayout>
  );
};
