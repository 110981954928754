import React, { FunctionComponent, useState } from 'react';
import { Button } from '@shared/components/Button/Button';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EditIcon } from '@shared/assets/images/icons';
import Typography from '@shared/components/Typography';
import ControlButtons from '@shared/components/ControlButtons';
import { ICellProps } from '@components/LazyTable';
import { IIncomingNumber, IncomingNumberStatusType } from '../IncomingNumbers.interfaces';
import { useIncomingNumbersStyles } from '../IncomingNumbers.styles';

export const IncomingNumberActionsCell: FunctionComponent<ICellProps<IIncomingNumber>> = ({
  item,
}) => {
  const classes = useIncomingNumbersStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  if (item) {
    const { id, status } = item;

    if (!status || status === IncomingNumberStatusType.Reserved) {
      return null;
    }
    return (
      <div className={classes.numberActionsBody}>
        <div className={classes.numberActionsContent}>
          <Button
            className={classes.numberActionsActionButton}
            variant={'secondary'}
            onClick={() => navigate(`/calls/incoming/${id}`)}
            disabled={status === IncomingNumberStatusType.Restricted}
          >
            <EditIcon />
          </Button>
        </div>
        <Dialog
          aria-labelledby={'delete-dialog-title'}
          onClose={() => setOpen(false)}
          open={open}
          className={classes.numberActionsDialog}
          classes={{ paper: classes.defaultElementWidth21 }}
        >
          <div className={classes.numberActionsDialogTitle}>
            <Typography color={'tertiary900'} type={'text2'}>
              {translate('INCOMING_NUMBER_SWITCH_OFF_PROMPT')}
            </Typography>
          </div>
          <div className={classes.numberActionsDialogContent}>
            <Typography type={'text3'} color={'tertiary900'}>
              {translate('INCOMING_NUMBER_SWITCH_OFF_WARNING')}
              {'\xa0'}
              {translate('INCOMING_NUMBER_SWITCH_OFF_DESCRIPTION')}
            </Typography>
          </div>
          <div className={classes.numberActionsDialogActions}>
            <ControlButtons
              confirmTitle={'SWITCH_OFF'}
              cancelTitle={'LEAVE'}
              flexDirection={'row-reverse'}
              justifyContent={'start'}
              onConfirmClick={() => setOpen(false)}
              onCancelClick={() => setOpen(false)}
              rootConfirmStyles={classes.defaultElementWidthHalf}
            />
          </div>
        </Dialog>
      </div>
    );
  }

  return <div />;
};
