import React, { FunctionComponent, useCallback, useState } from 'react';
import { EditIcon, TrashIcon } from '@shared/assets/images/icons';
import { Button } from '@shared/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { DELETE_OUTGOING_NUMBER_MUTATION } from '@/client/mutations';
import { OUTGOING_CALLS_QUERY } from '@/client/queries';
import { IOutgoingCall } from '@components/typings/interfaces';
import Typography from '@shared/components/Typography';
import Translate from '@shared/components/Translate';
import { useOutgoingCallsStyles } from '@/features/OutgoingCalls/OutgoingCalls.styles';
import ControlButtons from '@shared/components/ControlButtons';
import MessageDialog from '@shared/components/MessageDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { ICellProps } from '@components/LazyTable';

export const OutgoingCallActionsCell: FunctionComponent<ICellProps<IOutgoingCall>> = ({ item }) => {
  const classes = useOutgoingCallsStyles();
  const [translate] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const formMethods = useForm();
  const { handleSubmit } = formMethods;
  const [deleteCallsRestrictions, { loading: deleteLoading }] = useMutation(
    DELETE_OUTGOING_NUMBER_MUTATION,
    {
      refetchQueries: [
        {
          query: OUTGOING_CALLS_QUERY,
        },
      ],
    }
  );

  function handleEditNumberClick(id: number) {
    searchParams.set('id', String(id));
    setSearchParams(searchParams);
  }

  const handleSubmitForm = useCallback(() => {
    deleteCallsRestrictions({ variables: { data: { id: item?.id } } }).then(() => {
      setOpen(false);
    });
  }, [deleteCallsRestrictions, item]);

  if (item) {
    const { id, department, employee } = item;

    const getEntityName = () => {
      if (department?.name) {
        return 'DELETE_OUTGOING_CALL_DEPARTMENT';
      }
      if (employee?.user?.name) {
        return 'DELETE_OUTGOING_CALL_EMPLOYEE';
      }
      return '';
    };

    return (
      <div className={classes.tableButtonCell}>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.actionRowButton}
            variant={'secondary'}
            color={'error'}
            onClick={() => setOpen(true)}
          >
            <TrashIcon />
          </Button>
          <Button
            className={classes.actionRowButton}
            variant={'secondary'}
            onClick={() => handleEditNumberClick(id)}
          >
            <EditIcon />
          </Button>
        </div>
        <MessageDialog
          title={translate('ARE_YOU_SURE')}
          isOpen={open}
          onCancel={() => setOpen(false)}
          contentClass={classes.defaultElementWidth21}
          renderContent={
            <FormProvider {...formMethods}>
              <form
                id={'remove-outgoing-number'}
                action={''}
                onSubmit={handleSubmit(handleSubmitForm)}
              >
                <div className={classes.modalTextContainer}>
                  <Translate
                    i18nKey={'DELETE_OUTGOING_CALL_WARNING'}
                    values={{
                      entity: translate(getEntityName()),
                      name: employee?.user?.name || department?.name,
                    }}
                    components={{
                      b: <br />,
                      t: <Typography color="tertiary900" type={'text3'} />,
                      p: <Typography color="primary700" type={'text3'} />,
                    }}
                  />
                </div>
                <ControlButtons
                  confirmColor={'error'}
                  confirmTitle={'REMOVE'}
                  cancelTitle={'CANCEL'}
                  cancelVariant="secondary"
                  justifyContent={'start'}
                  flexDirection={'row-reverse'}
                  onConfirmClick={handleSubmitForm}
                  onCancelClick={() => setOpen(false)}
                  rootClass={classes.dialogActions}
                  loading={deleteLoading}
                  small
                />
              </form>
            </FormProvider>
          }
        />
      </div>
    );
  }

  return <td />;
};
