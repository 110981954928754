/* eslint-disable no-await-in-loop */
/* eslint-disable no-constant-condition */
import resultify from './resultify';

type AnyFunction = (...args: any[]) => any;

type DownloadErrorCause =
  | 'error-fetch'
  | 'error-fetch-with-response'
  | 'no-body'
  | 'error-read'
  | 'no-content-length'
  | 'no-content-type';

export type OnError = (cause: DownloadErrorCause, error?: any) => void;

type DownloadFileFuncArgs = {
  abortController: AbortController;
  url: string;

  filename?: string;

  setIsDownloading?: (val: boolean) => void;
  setDownloadProgress?: (val: number) => void;

  onError?: OnError;
  onSuccess?: AnyFunction;
  onCancel?: AnyFunction;
};

const minimumProgressValue = 0.03;

const isAbortError = (error: unknown) => {
  return (
    typeof error === 'object' &&
    error !== null &&
    (error as Record<string, unknown>).name === 'AbortError'
  );
};

const downloadFileWithProgress = async ({
  abortController,
  url,

  filename,

  setDownloadProgress,
  setIsDownloading,

  onError,
  onSuccess,
  onCancel,
}: DownloadFileFuncArgs) => {
  if (!url) {
    return;
  }

  setIsDownloading?.(true);
  setDownloadProgress?.(minimumProgressValue);

  const reset = () => {
    setIsDownloading?.(false);
    setDownloadProgress?.(0);
  };

  const handleError = (cause: DownloadErrorCause, error?: any) => {
    onError?.(cause, error);
    reset();
  };

  const result = await resultify(fetch(url, { signal: abortController.signal }));

  if (result.type === 'error') {
    if (isAbortError(result.error)) {
      onCancel?.();
      reset();
      return;
    }
    handleError('error-fetch', result.error);
    return;
  }

  if (result.data.status < 200 || result.data.status > 299) {
    const resultText = await resultify(result.data.text());
    const info = resultText.type === 'success' ? resultText.data : result.data.statusText;

    handleError('error-fetch-with-response', { status: result.data.status, info });
    return;
  }

  const body = result.data.body;
  const contentLength = Number(result.data.headers.get('Content-Length'));
  const contentType = result.data.headers.get('Content-Type');

  if (!body) {
    handleError('no-body');
    return;
  }
  if (Number.isNaN(contentLength)) {
    handleError('no-content-length');
    return;
  }
  if (!contentType) {
    handleError('no-content-type');
    return;
  }

  let receivedLength = 0;
  const reader = body.getReader();
  const chunks: Uint8Array[] = [];

  try {
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        break;
      }
      chunks.push(value);
      receivedLength += value.length;
      setDownloadProgress?.(Math.max(receivedLength / contentLength, minimumProgressValue));
    }
  } catch (error) {
    if (isAbortError(error)) {
      onCancel?.();
      reset();
      return;
    }
    handleError('error-read', error);
    return;
  }

  const blob = new Blob(chunks, { type: contentType });
  const link = document.createElement('a');
  const objectURL = URL.createObjectURL(blob);

  link.href = objectURL;
  link.download = filename || '';
  link.hidden = true;

  document.body.append(link);
  link.click();
  link.remove();

  URL.revokeObjectURL(objectURL);

  setIsDownloading?.(false);
  setDownloadProgress?.(0);
  onSuccess?.();
};

export default downloadFileWithProgress;
