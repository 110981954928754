import {
  CREATE_APOLOGY_MESSAGES_SETTINGS_MUTATION,
  REMOVE_APOLOGY_MESSAGES_SETTINGS_MUTATION,
  UPDATE_APOLOGY_MESSAGES_SETTINGS_MUTATION,
} from '@/client/mutations';
import {
  DOMAIN_NUMBERS_QUERY,
  GET_ALL_APOLOGY_SMS_QUERY,
  GET_APOLOGY_SMS_BY_ID_QUERY,
} from '@/client/queries';
import { useFormErrors } from '@/common/hooks';
import { getSegments } from '@/common/utils';
import BodyContainer from '@/layouts/BodyContainer';
import BottomButtons from '@/layouts/BottomButtons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import FormErrorMessage from '@components/FormErrorMessage';
import { useRoutes } from '@components/Routes';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { FormControlLabel } from '@material-ui/core';
import { HelpCircleIcon, TrashIcon } from '@shared/assets/images/icons';
import { Button } from '@shared/components/Button/Button';
import ComboBoxField from '@shared/components/ComboBoxField';
import ControlButtons from '@shared/components/ControlButtons';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import MessageDialog from '@shared/components/MessageDialog';
import Radio from '@shared/components/Radio';
import RadioGroup from '@shared/components/RadioGroup';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TooltipHover from '@shared/components/Popover/TooltipHover';
import Flex from '@shared/components/Flex';
import { MAX_MESSAGE_LENGTH } from './ApologySMS.constants';
import { ISelectedNumber } from './ApologySMS.interfaces';
import { ApologySMSStyles } from './ApologySMS.styles';

export const ApologySMS: FunctionComponent = () => {
  const classes = ApologySMSStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const formMethods = useForm<any>({ defaultValues: { 'numberId-0': -2 } }); // TODO add correct types to form
  const { watch, handleSubmit, setValue, setError, clearErrors } = formMethods;
  const {
    path: { subcategory, identifier },
  } = useRoutes();

  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [numbersArray, setNumbersArray] = useState<number[]>([-2]);
  const [isChanged, setIsChanged] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });
  const [disabledNumbers, setDisabledNumbers] = useState<number[]>([]);

  const { data: numbersData } = useQuery<{
    boughtDomainNumbers: { id: number; phone: string }[];
  }>(DOMAIN_NUMBERS_QUERY);
  // TODO handle lazy query error
  const [getOneApologySMS, { data: oneApologySMS }] = useLazyQuery(GET_APOLOGY_SMS_BY_ID_QUERY);

  const [
    createApologySMS,
    {
      error: errorCreateApologySMS,
      called: calledCreateApologySMS,
      loading: loadingCreateApologySMS,
    },
  ] = useMutation(CREATE_APOLOGY_MESSAGES_SETTINGS_MUTATION);

  const [
    updateApologySMS,
    {
      error: errorUpdateApologySMS,
      called: calledUpdateApologySMS,
      loading: loadingUpdateApologySMS,
    },
  ] = useMutation(UPDATE_APOLOGY_MESSAGES_SETTINGS_MUTATION);

  const [removeApologySMS, { loading: loadingRemoveApologySMS }] = useMutation(
    REMOVE_APOLOGY_MESSAGES_SETTINGS_MUTATION,
    {
      refetchQueries: [{ query: GET_ALL_APOLOGY_SMS_QUERY }],
    }
  );

  useFormErrors(errorUpdateApologySMS?.graphQLErrors, formMethods);
  useFormErrors(errorCreateApologySMS?.graphQLErrors, formMethods);
  const formErrors = [
    ...(errorUpdateApologySMS?.graphQLErrors || []),
    ...(errorCreateApologySMS?.graphQLErrors || []),
  ];
  const isApologySMSEdit = identifier !== 'add';
  const emptyNumbersArray = numbersArray[0] === -2;
  const getOneApologySMSConfig = oneApologySMS?.getApologyMessagesSettingsById;

  const domainNumbers = useMemo(
    () => numbersData?.boughtDomainNumbers || [],
    [numbersData?.boughtDomainNumbers]
  );
  const smsTemplateText = watch('comment') || '';

  const selectGroup = useMemo(
    () => [
      {
        value: 'Always',
        titleCode: 'ALWAYS',
      },
      {
        value: 'WorkTime',
        titleCode: 'DURING_WORKING_HOURS',
      },
      {
        value: 'NonWorkTime',
        titleCode: 'OUT_OF_HOURS',
      },
    ],
    []
  );

  const optionNumber: ISelectedNumber[] = useMemo(
    () => [
      // This empty option is used to make ComboBoxField controlled (by internal logic of MUI Autocomplete)
      { id: -2, phone: '' },
      {
        id: -1,
        phone: translate('ALL_NUMBERS'),
      },
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...domainNumbers?.map((item) => ({
        id: item.id,
        phone: formatPhone(item.phone),
      })),
    ],
    [domainNumbers, translate]
  );
  const computedClass = clsx(classes.textFieldCounter, {
    [classes.textFieldCounterError]: smsTemplateText.length > MAX_MESSAGE_LENGTH,
  });

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  function handleFormChange() {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  }

  function handleCancelClick() {
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
    navigate(-1);
  }

  const handleRemoveApologySMS = () => {
    handleFormChange();
    removeApologySMS({
      variables: {
        id: Number(identifier),
      },
    });
    navigate('/business-cards/apology');
  };

  const handleAddNewNumber = useCallback(() => {
    handleFormChange();
    const newNumbersArray = [...numbersArray, -2];
    const lastElIndex = newNumbersArray.length - 1;

    formMethods.setValue(`numberId-${lastElIndex}`, -2);
    setNumbersArray([...numbersArray, -2]);
  }, [numbersArray, formMethods]);

  const handleSelectedNumber = useCallback(
    (item: ISelectedNumber, index: number) => {
      if (item.id === -2) {
        return;
      }
      if (item.id === -1) {
        setNumbersArray([-1]);
        formMethods.setValue('numberId-0', -1);
      } else {
        const newNumbersArray = [...numbersArray];
        newNumbersArray[index] = item?.id;

        setNumbersArray(newNumbersArray);
      }
      handleFormChange();
    },
    [numbersArray, formMethods]
  );

  function handleRemoveSelectedNumber(index: number) {
    if (numbersArray) {
      const updatedNumbersArray = [...numbersArray];
      updatedNumbersArray.splice(index, 1);
      setNumbersArray(updatedNumbersArray.length ? updatedNumbersArray : [-2]);
    }
  }

  function handleRemoveDialogOpen() {
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
    setIsRemoveDialogOpen(true);
  }

  const handleClosePopupDialog = () => {
    setIsRemoveDialogOpen(false);
  };

  const smsAmount = () =>
    !smsTemplateText
      ? `0 ${translate('SMS')}`
      : `${getSegments(smsTemplateText, 0)} ${translate('SMS')}`;

  const handleFormSubmit = useCallback(
    (data) => {
      const ApologySMSData = {
        messageTemplate: data.comment,
        triggerTime: data.interval,
        active: Boolean(!isApologySMSEdit || getOneApologySMSConfig?.active),
        allNumbers: numbersArray.some((phoneId) => phoneId === -1),
        domainNumberIds: numbersArray,
      };
      if (!isApologySMSEdit) {
        createApologySMS({
          variables: {
            input: ApologySMSData,
          },
        });
      } else {
        updateApologySMS({
          variables: {
            input: {
              id: Number(identifier),
              ...ApologySMSData,
            },
          },
        });
      }
    },
    [
      isApologySMSEdit,
      getOneApologySMSConfig?.active,
      numbersArray,
      createApologySMS,
      updateApologySMS,
      identifier,
    ]
  );

  useEffect(() => {
    if (domainNumbers && numbersArray.length) setDisabledNumbers(numbersArray);
  }, [domainNumbers, getOneApologySMSConfig, numbersArray, setDisabledNumbers]);

  useEffect(() => {
    if (!getOneApologySMSConfig) return;
    if (isApologySMSEdit) {
      setValue('comment', getOneApologySMSConfig.messageTemplate);
      setValue('interval', getOneApologySMSConfig.triggerTime);
    }
  }, [getOneApologySMSConfig, isApologySMSEdit, setValue]);

  useEffect(() => {
    if (numbersArray.length) {
      numbersArray.forEach((phoneId: number | undefined, index: number) => {
        setValue(`numberId-${index}`, phoneId);
      });
    }
  }, [numbersArray, setValue]);

  useEffect(() => {
    if (getOneApologySMSConfig && isApologySMSEdit && emptyNumbersArray) {
      if (getOneApologySMSConfig.allNumbers) {
        setNumbersArray([-1]);
        return;
      }
      if (getOneApologySMSConfig.domainNumbers) {
        setNumbersArray(getOneApologySMSConfig.domainNumbers.map((number) => number.id));
      }
    }
  }, [emptyNumbersArray, getOneApologySMSConfig, isApologySMSEdit, numbersArray]);

  useEffect(() => {
    if (!getOneApologySMSConfig && isApologySMSEdit) {
      getOneApologySMS({
        variables: { id: Number(identifier) || undefined },
      });
    }
  }, [getOneApologySMS, getOneApologySMSConfig, identifier, isApologySMSEdit, subcategory]);

  useEffect(() => {
    if (
      (calledCreateApologySMS && !loadingCreateApologySMS && !errorCreateApologySMS) ||
      (calledUpdateApologySMS && !loadingUpdateApologySMS && !errorUpdateApologySMS)
    ) {
      navigate(blockedPath || '/business-cards/apology');
    }
  }, [
    blockedPath,
    calledCreateApologySMS,
    calledUpdateApologySMS,
    errorCreateApologySMS,
    errorUpdateApologySMS,
    loadingCreateApologySMS,
    loadingUpdateApologySMS,
    navigate,
  ]);

  useEffect(() => {
    if (
      (calledCreateApologySMS || calledUpdateApologySMS) &&
      !errorCreateApologySMS &&
      !errorUpdateApologySMS
    ) {
      setIsChanged({
        isBlocking: false,
        action: ConfirmAction.Finish,
      });
    }
  }, [
    calledCreateApologySMS,
    calledUpdateApologySMS,
    errorCreateApologySMS,
    errorUpdateApologySMS,
  ]);

  useEffect(() => {
    if (smsTemplateText.length > MAX_MESSAGE_LENGTH) {
      setError('comment', {
        type: 'manual',
        message: translate('MORE_THAN_POSSIBLE'),
      });
    } else clearErrors();
  }, [smsTemplateText, clearErrors, setError, translate]);

  const renderPurposeItems = (phoneId: number, index: number) => {
    // Prevent `undefined` uncontrolled behavior. Use hidden empty option with id -2 instead.
    if (formMethods.getValues()[`numberId-${index}`] === undefined) {
      setValue(`numberId-${index}`, -2);
    }

    return (
      <>
        <ComboBoxField
          className={classes.controlSize}
          name={`numberId-${index}`}
          data={optionNumber}
          getOptionDisabled={(option) => disabledNumbers.includes(option.id)}
          valueKey={'id'}
          titleKey={'phone'}
          placeholder={translate('CHOOSE')}
          validate={(value: number) => {
            const isEmpty = value === -2;
            if (isEmpty) {
              return translate('CHOOSE_NUMBER') as string;
            }
            return true;
          }}
          onChange={(e, item: ISelectedNumber) => handleSelectedNumber(item, index)}
          // We do not show hidden empty option
          filterOptions={(options) => {
            return options.filter((o) => o.phone !== '');
          }}
        />

        {numbersArray.length > 1 && (
          <Button
            variant={'secondary'}
            color={'error'}
            className={classes.listControl}
            onClick={() => handleRemoveSelectedNumber(index)}
          >
            <TrashIcon />
          </Button>
        )}
      </>
    );
  };

  return (
    <BodyContainer>
      <div className={classes.root}>
        <FormProvider {...formMethods}>
          <form
            id={'add-apology-sms'}
            action={''}
            className={classes.form}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('MISSED_CALL_TO_NUMBER')}
            </Typography>
            <div className={classes.fieldSize}>
              {numbersArray.map((phoneId, index) => (
                <div className={classes.listItem} key={`selectedNumber-${index}`}>
                  {renderPurposeItems(phoneId, index)}
                </div>
              ))}
              {!emptyNumbersArray &&
                !numbersArray.includes(-1) &&
                !numbersArray.some((n) => n === -2) && (
                  <Button
                    className={classes.addNumberButton}
                    title={translate('ADD_NUMBER')}
                    variant={'secondary'}
                    onClick={handleAddNewNumber}
                  />
                )}
            </div>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('SELECT_INTERVAL')}
            </Typography>
            <RadioGroup defaultValue={'Always'} name={'interval'}>
              {selectGroup.map(({ titleCode, value }) => (
                <div key={`interval-${titleCode}`} className={classes.filterRow}>
                  <FormControlLabel
                    value={value || 'Always'}
                    control={<Radio color={'secondary'} />}
                    label={translate(titleCode)}
                    onChange={handleFormChange}
                  />
                </div>
              ))}
            </RadioGroup>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('ENTER_MESSAGE')}
            </Typography>
            <div className={classes.textFormContainer}>
              <FormFieldRhfUncontrolled
                className={classes.textForm}
                name={'comment'}
                fullWidth
                rowsMax={12}
                rows={2}
                multiline
                onChange={handleFormChange}
                InputProps={{ style: { paddingBottom: '1.5em' } }}
                validate={(value) => {
                  if (!value) {
                    return translate('ENTER_MESSAGE') as string;
                  }
                  if (value && value.length > MAX_MESSAGE_LENGTH) {
                    return translate('MORE_THAN_POSSIBLE') as string;
                  }
                  return true;
                }}
              />
              <Typography className={computedClass} type={'text5'} color={'tertiary600'}>
                {`${smsTemplateText.length} ${translate(
                  'OUT_OF'
                ).toLowerCase()} ${MAX_MESSAGE_LENGTH}`}
              </Typography>
            </div>
            <div className={classes.counterRow}>
              <Typography type={'text3'} color={'tertiary900'} bold>
                {translate('FUNDS_WILL_DE_DEBITED')}
              </Typography>
              <Flex alignItems="flexStart">
                <Typography type={'text3'} color={'tertiary900'} bold>
                  {smsAmount()}
                </Typography>
                <TooltipHover
                  className={classes.notificationIcon}
                  placement={'right'}
                  title={
                    <Typography color={'tertiary900'} type={'text4'}>
                      {translate('SMS_LENGTH_NOTIFICATION')}
                    </Typography>
                  }
                >
                  <HelpCircleIcon />
                </TooltipHover>
              </Flex>
            </div>
            <BottomButtons>
              <div className={classes.actions}>
                <div>
                  <ControlButtons
                    confirmTitle={'SAVE_CHANGES'}
                    cancelTitle={'CANCEL'}
                    form={'add-apology-sms'}
                    onCancelClick={handleCancelClick}
                    rootConfirmStyles={classes.button}
                    rootCancelBtn={classes.cancelButton}
                    loading={loadingCreateApologySMS || loadingUpdateApologySMS}
                  />
                </div>
                {isApologySMSEdit && (
                  <Button variant={'tertiary'} noHover onClick={handleRemoveDialogOpen}>
                    <Typography underline type={'text3'} color={'tertiary900'}>
                      {translate('REMOVE')}
                    </Typography>
                  </Button>
                )}
              </div>
            </BottomButtons>
            <FormErrorMessage errors={formErrors} />
          </form>
        </FormProvider>
        <ConfirmDialog
          isBlocked={isChanged.isBlocking}
          onSaveChanges={handleSubmit(handleFormSubmit)}
          onNavigationBlocked={getBlockedPath}
        />
        <MessageDialog
          isOpen={isRemoveDialogOpen}
          title={translate('ARE_YOU_SURE')}
          contentClass={classes.confirmPopupDialog}
          onCancel={handleClosePopupDialog}
          renderContent={
            <>
              <div className={classes.confirmPopupText}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('APOLOGY_SMS_REMOVE_DESCRIPTION')}
                </Typography>
              </div>
              <ControlButtons
                confirmTitle={'REMOVE'}
                confirmColor={'error'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                rootClass={classes.confirmPopupControls}
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                onConfirmClick={() => handleRemoveApologySMS()}
                onCancelClick={handleClosePopupDialog}
                loading={loadingRemoveApologySMS}
                small
              />
            </>
          }
        />
      </div>
    </BodyContainer>
  );
};

export default ApologySMS;
