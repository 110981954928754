import React, { FunctionComponent } from 'react';
import { toPrecision } from '@components/utils/toPrecision';
import { IDomainNumber } from '@components/typings/interfaces';
import Typography from '@shared/components/Typography';
import { NumberDiscount } from '@/features/FinancialCabinet/Numbers/Numbers.helpers';
import { ICellProps } from '@components/LazyTable';

export const NumberCostCell: FunctionComponent<ICellProps<IDomainNumber>> = ({ item }) => {
  if (item) {
    const { setupFee, setupDiscount, isSetupDiscountAbsolute } = item?.tariff || {};

    return (
      <Typography type={'text3'} color={'inherit'}>
        {`${setupFee ? toPrecision(setupFee, false) : '-'} ₽`}
        {!!setupDiscount && (
          <NumberDiscount discount={setupDiscount} isAbsolute={!!isSetupDiscountAbsolute} />
        )}
      </Typography>
    );
  }

  return <div />;
};
