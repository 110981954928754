import { makeStyles } from '@material-ui/core/styles';

export const useWelcomeDialogStyles = makeStyles(
  ({ color: { secondary } }) => ({
    dialog: {
      padding: '2em 2em 1em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dialogPaper: {
      maxWidth: '24em',
      width: '24em',
    },
    dialogContent: {
      padding: '0 1.5em 1.5em',
      lineHeight: '150%',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column',
    },
    dialogCaption: {
      marginBottom: '0.5em',
    },
    textBlock: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      paddingBottom: '1.5em',
      alignItems: 'center',
      gap: '0.5em',
    },
    welcomeTitle: {
      paddingTop: '1em',
    },
    dialogActions: {
      padding: '2em',
      justifyContent: 'center',
    },
    description: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1em',
      justifyContent: 'center',
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
      position: 'relative',
      '& > *': {
        marginBottom: '1em !important',
      },
    },
    descriptionText: {
      textAlign: 'center',
      margin: '0 auto 1em',
      display: 'block',
    },
    button: {
      marginTop: '1em',
      width: '100%',
    },
    reservedBlock: {
      borderRadius: '6px',
      border: `1px solid ${secondary[200]}`,
      backgroundColor: secondary[50],
      padding: '0.75em',
      marginTop: '1em',
    },
  }),
  {
    index: 1,
  }
);
