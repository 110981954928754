import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  DEPARTMENTS_QUERY,
  DOMAIN_NUMBERS_QUERY,
  EMPLOYEES_QUERY,
  OUTGOING_CALLS_QUERY,
} from '@/client/queries';
import Drawer from '@components/Drawer';
import { ADD_OUTGOING_NUMBER_MUTATION, UPDATE_OUTGOING_NUMBER_MUTATION } from '@/client/mutations';
import ComboBoxField from '@shared/components/ComboBoxField';
import {
  deserializeOutgoingCall,
  IOutgoingCall,
  NumberType,
  OutgoingCallEntity,
} from '@components/typings/interfaces';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import SelectField, { ISelectFieldOptionRendererProps } from '@shared/components/SelectField';
import Typography from '@shared/components/Typography';
import PagePreloader from '@components/PagePreloader';
import Flex from '@shared/components/Flex';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import clsx from 'clsx';
import HighlightedText from '@components/HighlightedText';
import { CheckIcon } from '@shared/assets/images/icons';
import { useOutgoingCallStyles } from './OutgoingCall.styles';

export type OutgoingCallProps = { item?: Partial<IOutgoingCall> };

interface IFormFields {
  callerId?: string;
  numberId?: number;
}

export const OutgoingCall: FunctionComponent<OutgoingCallProps> = ({
  item: { id, numberId, employeeId, departmentId } = {},
}) => {
  const classes = useOutgoingCallStyles({});
  const [translate] = useTranslation();
  const [isChanged, setIsChanged] = useState(false);
  const [addOutgoingCall, { loading: addLoading }] = useMutation(ADD_OUTGOING_NUMBER_MUTATION);
  const [searchParams, setSearchParams] = useSearchParams();
  const [updateOutgoingCall, { loading: updateLoading }] = useMutation(
    UPDATE_OUTGOING_NUMBER_MUTATION
  );

  const {
    loading = false,
    error = false,
    data: { getOutgoingNumbers = [] } = {},
    called,
  } = useQuery(OUTGOING_CALLS_QUERY, { fetchPolicy: 'no-cache' });

  const outgoingCalls = useMemo(
    () => getOutgoingNumbers?.map(deserializeOutgoingCall) || [],
    [getOutgoingNumbers]
  );

  const { data: { employees = [] } = { employees: [] }, loading: employeesLoading } = useQuery<{
    employees: { id: string | number; ext: string; user?: { name?: string } }[];
  }>(EMPLOYEES_QUERY, { fetchPolicy: 'no-cache' });

  const { data: { departments = [] } = { departments: [] }, loading: departmentsLoading } =
    useQuery<{
      departments: { id: string | number; name?: string; ext: string }[];
    }>(DEPARTMENTS_QUERY, { fetchPolicy: 'no-cache' });

  const { data: { boughtDomainNumbers: domainNumbers = [] } = { boughtDomainNumbers: [] } } =
    useQuery<{
      boughtDomainNumbers: { id: number; phone: string; city: string; type: NumberType }[];
    }>(DOMAIN_NUMBERS_QUERY, { fetchPolicy: 'cache-first' });

  const allowedDomainNumbers = domainNumbers?.filter(
    (num) => ![NumberType.GravitelCommon, NumberType.Own].includes(num.type)
  );
  const formMethods = useForm<IFormFields>({
    defaultValues: {
      callerId:
        // eslint-disable-next-line no-nested-ternary
        employeeId !== null && Number.isFinite(Number(employeeId))
          ? `${employeeId}_employeeId`
          : departmentId !== null && Number.isFinite(Number(departmentId))
          ? `${departmentId}_departmentId`
          : '',
      numberId,
    },
  });
  const { handleSubmit } = formMethods;

  const handleClose = () => {
    searchParams.delete('id');
    setSearchParams(searchParams);
  };

  const handleSubmitForm = ({ callerId, numberId: selectedNumberId }: IFormFields) => {
    if (addLoading || updateLoading) return;
    const options = {
      variables: {
        data: {
          id,
          employeeId: callerId?.endsWith('_employeeId')
            ? Number(callerId?.replace('_employeeId', ''))
            : undefined,
          departmentId: callerId?.endsWith('_departmentId')
            ? Number(callerId?.replace('_departmentId', ''))
            : undefined,
          numberId: selectedNumberId,
        },
      },
      refetchQueries: [
        {
          query: OUTGOING_CALLS_QUERY,
        },
      ],
    };
    if (id !== undefined) {
      updateOutgoingCall(options).then(() => {
        handleClose();
      });
    } else {
      addOutgoingCall(options).then(() => {
        handleClose();
      });
    }
  };

  const options = employees
    .map(({ id: emplId, ext, user: { name = '' } = {} }) => ({
      value: `${emplId}_employeeId`,
      title: name || '',
      ext,
      type: translate('EMPLOYEES'),
    }))
    .concat(
      departments.map(({ id: deptId, name: deptName = '', ext }) => ({
        value: `${deptId}_departmentId`,
        title: deptName || '',
        ext,
        type: translate('DEPARTMENTS'),
      }))
    );

  const renderCallerOptions = (
    data: {
      ext: string;
      title: string;
    },
    { inputValue }: AutocompleteRenderOptionState
  ) => (
    <Flex className={classes.customLine} justifyContent={'spaceBetween'}>
      <Typography
        className={clsx(classes.callerTitle, classes.textOverflow)}
        color={'tertiary900'}
        type={'text3'}
      >
        {data.title}
      </Typography>
      <div className={classes.callerExt}>
        <HighlightedText
          type={'text'}
          text={data.ext}
          query={inputValue}
          typographyType={'text3'}
          typographyColor={'tertiary900'}
        />
      </div>
    </Flex>
  );

  const renderCustomOption = ({
    data,
    selected,
  }: ISelectFieldOptionRendererProps<{ id: number; phone: string; city: string }>) => {
    const { id: numId, phone = '', city = '' } = data;

    if (numId === undefined) {
      return (
        <div className={classes.customOption}>
          <Typography type={'text3'} color={'tertiary400'}>
            {translate('CHOOSE')}
          </Typography>
        </div>
      );
    }

    return (
      <div className={classes.customOption}>
        <Flex alignItems={'center'}>
          {selected && <CheckIcon className={classes.glyph} />}
          <Flex direction={'column'} alignItems={'flexStart'}>
            <Typography type={'text3'} color={selected ? 'primary700' : 'tertiary900'}>
              {formatPhone(phone)}
            </Typography>
            <Typography type={'text4'} color={'tertiary600'} className={classes.secondaryText}>
              {city || ''}
            </Typography>
          </Flex>
        </Flex>
      </div>
    );
  };

  const getOptionDisabled = useCallback(
    ({ value }: { value: string }) => {
      const [, optionId, type] = /^(\d+)_(employeeId|departmentId)/gi.exec(value) || [];
      if (optionId && !loading && !error && called) {
        return outgoingCalls.some(
          ({
            employeeId: restrictionEmployeeId,
            departmentId: restrictionDepartmentId,
            entity,
          }: IOutgoingCall) =>
            (type === 'employeeId' &&
              entity === OutgoingCallEntity.Employee &&
              restrictionEmployeeId === Number(optionId)) ||
            (type === 'departmentId' &&
              entity === OutgoingCallEntity.Department &&
              restrictionDepartmentId === Number(optionId))
        );
      }
      return false;
    },
    [called, error, loading, outgoingCalls]
  );

  function handleChanges() {
    setIsChanged(true);
  }

  return (
    <Drawer
      title={'PERSONAL_OUTGOING_NUMBER'}
      ModalProps={{ disableBackdropClick: isChanged }}
      elevation={4}
      open
      size={'xs'}
      primaryButton={{
        title: 'SAVE',
        props: {
          form: 'edit-outgoing-call',
          type: 'submit',
          loading,
        },
      }}
      secondaryButton={{
        title: 'CANCEL',
        onClick: handleClose,
        props: {
          disabled: loading,
        },
      }}
      onClose={handleClose}
    >
      {employeesLoading || departmentsLoading ? (
        <PagePreloader />
      ) : (
        <FormProvider {...formMethods}>
          <form
            id={'edit-outgoing-call'}
            action={''}
            onSubmit={handleSubmit(handleSubmitForm)}
            className={classes.form}
          >
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('EMPLOYEE_OR_DEPARTMENT')}
            </Typography>
            <div className={classes.formContent}>
              <ComboBoxField
                data={options.sort(({ type: typeA }, { type: typeB }) => {
                  if (typeA < typeB) return -1;
                  if (typeA > typeB) return 1;
                  return 0;
                })}
                name={'callerId'}
                valueKey={'value'}
                titleKey={'title'}
                getOptionDisabled={getOptionDisabled}
                groupBy={(option: { type: string }) => option.type}
                placeholder={translate('CHOOSE')}
                renderOption={renderCallerOptions}
                validate={(value: string) => {
                  if (!value) {
                    return translate('SELECT_EMPLOYEE_OR_DEPARTMENT') as string;
                  }
                  return true;
                }}
                onChange={handleChanges}
                filterOptions={(opts, state) => {
                  if (!state.inputValue) return opts;
                  const inputValueLowercased = state.inputValue.toLocaleLowerCase();
                  return opts.filter(
                    (opt) =>
                      opt.ext.toLowerCase().includes(inputValueLowercased) ||
                      opt.title.toLowerCase().includes(inputValueLowercased)
                  );
                }}
              />
            </div>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('OUTGOING_NUMBER')}
            </Typography>
            <div className={classes.formContent}>
              <SelectField
                name={'numberId'}
                Renderer={renderCustomOption}
                valueKey={'id'}
                titleKey={'phone'}
                data={allowedDomainNumbers}
                onChange={handleChanges}
                validate={(value) => {
                  if (!value || !allowedDomainNumbers.some((num) => num.id === value)) {
                    return translate('CHOOSE_NUMBER') as string;
                  }
                  return true;
                }}
              />
            </div>
          </form>
        </FormProvider>
      )}
    </Drawer>
  );
};

export default OutgoingCall;
