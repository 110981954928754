import React, { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import { IIncomingNumber } from '../IncomingNumbers.interfaces';
import { useIncomingNumbersStyles } from '../IncomingNumbers.styles';

export const IncomingNumberCell: FunctionComponent<ICellProps<IIncomingNumber>> = ({ item }) => {
  const classes = useIncomingNumbersStyles();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;

  if (item) {
    const { phone = '', city = '', name } = item;

    const renderNumberInformation = () => {
      if (!name) return null;

      return (
        <div className={classes.row}>
          <HighlightedText
            typographyColor={'tertiary500'}
            type={'text'}
            text={name}
            query={searchText}
            textOverflow
          />
        </div>
      );
    };

    return (
      <div className={classes.numberContent}>
        <div className={classes.row}>
          <HighlightedText type={'phone'} text={phone} query={searchText} />
        </div>
        <div className={classes.row}>
          <HighlightedText
            type={'text'}
            text={city}
            query={searchText}
            typographyColor={'tertiary600'}
            typographyType={'text4'}
          />
        </div>
        {renderNumberInformation()}
      </div>
    );
  }

  return <td />;
};
