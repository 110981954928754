import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { IDomainNumber, NumberStatus } from '@components/typings/interfaces';
import { format } from 'date-fns';
import { ICellProps } from '@components/LazyTable';

type TextColorCellVariants = 'inherit' | 'warning600' | 'danger600';

export const NumberStatusCell: FunctionComponent<ICellProps<IDomainNumber>> = ({ item }) => {
  const [translate] = useTranslation();

  if (!item) {
    return <div />;
  }
  const { status } = item;

  function getStatusObject(): {
    translate: string;
    date?: string;
    textColor: TextColorCellVariants;
  } {
    switch (status) {
      case NumberStatus.Reserved:
        /*
        Для Забронирован - должно приходить поле statusTill с датой, до которой номер будет находиться в этом статусе
         */
        return {
          translate: 'NUMBER_STATUS_Reserved',
          date: item?.statusTill ? format(new Date(item.statusTill), 'dd.MM.yyyy') : '',
          textColor: 'warning600',
        };
      case NumberStatus.WillDisabled:
        return {
          translate: 'NUMBER_STATUS_WILL_DISABLED',
          date: item?.statusTill ? format(new Date(item.statusTill), 'dd.MM.yyyy') : '',
          textColor: 'danger600',
        };
      case NumberStatus.Bought:
        return {
          translate: 'NUMBER_STATUS_Bought',
          // todo Для Куплен пишем без даты. Сейчас дата покупки не запоминается
          date: '',
          textColor: 'inherit',
        };
      default:
        return {
          translate: `NUMBER_STATUS_${status}`,
          date: '',
          textColor: 'danger600',
        };
    }
  }

  const currentCellData = getStatusObject();
  return (
    <Typography type={'text3'} color={currentCellData.textColor}>
      {translate(currentCellData.translate)}
      {!!currentCellData.date && (
        <>
          <br />
          {translate(currentCellData.date)}
        </>
      )}
    </Typography>
  );
};
