import React, { Fragment, FunctionComponent, useState } from 'react';
import { ICellProps } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import { Button } from '@shared/components/Button/Button';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { integrationsLogoList } from '@components/utils';
import clsx from 'clsx';
import { UserIcon, ArrowRightSmallIcon, PowerIcon } from '@shared/assets/images/icons';
import { IntegrationStatus } from '@components/typings/interfaces/integrations';
import { RESTIconSmall } from '@shared/assets/images/icons/integrations';
import { IIncomingNumber } from '../IncomingNumbers.interfaces';
import { useIncomingNumbersStyles } from '../IncomingNumbers.styles';

export const IncomingNumberIntegrationCell: FunctionComponent<ICellProps<IIncomingNumber>> = ({
  item,
}) => {
  const classes = useIncomingNumbersStyles();
  const [translate] = useTranslation();
  const [expanded, setExpanded] = useState(false);

  if (item) {
    const { integrations, responsible } = item;

    if (!integrations?.length) return <div />;
    const computedIntegrationList = [...integrations];
    const statusOrder = [
      IntegrationStatus.Active,
      IntegrationStatus.Draft,
      IntegrationStatus.Inactive,
    ];
    computedIntegrationList.sort(
      (a, b) =>
        statusOrder.indexOf(a.status as IntegrationStatus) -
        statusOrder.indexOf(b.status as IntegrationStatus)
    );
    const expandable = computedIntegrationList.length > 3;
    const dividerIndex = computedIntegrationList.filter(
      (int) => int.status === IntegrationStatus.Active || int.status === IntegrationStatus.Draft
    ).length;
    let collapsed = [];
    if (expandable && !expanded) {
      collapsed = computedIntegrationList.splice(3);
    }

    return (
      <Flex direction={'column'} className={classes.defaultElementWidthFull}>
        {computedIntegrationList.map((integration, index) => {
          const IntegrationLogo = integrationsLogoList[integration.type] || RESTIconSmall;
          const isDisabled = integration.status === IntegrationStatus.Inactive;
          return (
            <Fragment key={`${integration.type}-${index}`}>
              {dividerIndex !== 0 && dividerIndex === index ? (
                <div className={classes.integrationIconDivider} />
              ) : null}
              <Flex justifyContent={'flexStart'} className={classes.integrationIconLine}>
                <div className={classes.integrationIconWrapper}>
                  <Flex
                    className={clsx(classes.integrationIcon, {
                      [classes.integrationIconDisabled]: isDisabled,
                    })}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <IntegrationLogo />
                  </Flex>
                  {isDisabled ? (
                    <Flex
                      className={classes.integrationDisabledIcon}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <PowerIcon />
                    </Flex>
                  ) : null}
                </div>
                {integration.id === responsible?.id ? (
                  <>
                    <div
                      className={clsx(classes.integrationArrow, classes.integrationIcon, {
                        [classes.integrationIconDisabled]: isDisabled,
                      })}
                    >
                      <ArrowRightSmallIcon />
                    </div>
                    <div
                      className={clsx(classes.integrationGrouped, classes.integrationIcon, {
                        [classes.integrationIconDisabled]: isDisabled,
                      })}
                    >
                      <UserIcon className={classes.integrationUserIcon} />
                    </div>
                  </>
                ) : null}
              </Flex>
            </Fragment>
          );
        })}
        {expandable && (
          <Flex className={classes.integrationIconLine}>
            <Button
              clear
              disableElevation
              disableRipple
              onClick={() => setExpanded((prevState) => !prevState)}
              variant={'text'}
            >
              <Typography underline color={'link600'}>
                {expanded ? translate('LESS') : `${translate('MORE')} ${collapsed.length}`}
              </Typography>
            </Button>
          </Flex>
        )}
      </Flex>
    );
  }

  return <div />;
};
