import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { INCOMING_NUMBERS_QUERY } from '@/client/queries';
import {
  IOptionData,
  IScenarioSettingProps,
  selectedNumbersType,
} from '../IntegrationForm.interfaces';

export function useIntegrationNumbers(
  scenarioNumbers: string,
  integration: Record<string, string | number> | (null | undefined),
  integrationSettings: IScenarioSettingProps[]
) {
  const [selectedNumbers, setSelectedNumber] = useState<selectedNumbersType>({});
  const { data: domainNumbersData } = useQuery(INCOMING_NUMBERS_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const boughtDomainNumbers = useMemo(
    () => domainNumbersData?.boughtDomainNumbers || [],
    [domainNumbersData]
  );

  const selectedNumbersKeys = useMemo(() => Object.keys(selectedNumbers), [selectedNumbers]);

  const responsibleNumbersList = useMemo(
    () => (scenarioNumbers === 'allNumbers' ? boughtDomainNumbers : Object.values(selectedNumbers)),
    [scenarioNumbers, boughtDomainNumbers, selectedNumbers]
  );

  const isResponsibleNumbersAvailable = useMemo(
    () =>
      responsibleNumbersList.filter(
        (i: IOptionData) => i.responsible === null || i.responsible.name === integration?.name
      ).length !== 0,
    [responsibleNumbersList, integration]
  );

  const formatDomainNumbers = (a1: Array<string>, a2: Array<string> | null) =>
    a1.map((n) => ({
      domainNumberId: parseInt(n, 10),
      responsible: a2 ? a2.includes(n) : false,
    }));

  const availableNumbersList = useMemo(() => {
    const allSelectedNumbers = integrationSettings.reduce(
      (result: selectedNumbersType, sScenarioItem: IScenarioSettingProps) => ({
        ...result,
        ...sScenarioItem.selectedNumbers,
      }),
      {}
    );
    return boughtDomainNumbers.reduce((result: IOptionData[], number: IOptionData) => {
      const keyList = Object.keys(allSelectedNumbers);
      if (!keyList.includes(String(number.id))) {
        result.push(number);
      }
      return result;
    }, []);
  }, [integrationSettings, boughtDomainNumbers]);

  return {
    formatDomainNumbers,
    boughtDomainNumbers,
    selectedNumbersKeys,
    responsibleNumbersList,
    availableNumbersList,
    isResponsibleNumbersAvailable,
    selectedNumbers,
    setSelectedNumber,
  };
}
