import React, { useCallback, useEffect, useMemo } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { DOMAIN_ALL_NUMBERS_QUERY, USER_QUERY } from '@/client/queries';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { Skeleton } from '@material-ui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePrevious } from '@/common/hooks/usePrev.hooks';
import { INITIALIZE_EMPLOYEE_MUTATION } from '@/client/mutations';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import FormErrorMessage from '@components/FormErrorMessage';
import Button from '@shared/components/Button';
import { removeStorageItem } from '@components/storage/storage';
import Typography from '@shared/components/Typography/Typography';
import { isFeatureAvailable, useDomainFeatures } from '@/common/hooks';
import { deserializeProfile } from '@/features/Profile/Profile.utils';
import Translate from '@shared/components/Translate';
import { NumberStatus } from '@/client/generated/graphql';
import { useWelcomeDialogStyles } from './WelcomeDialog.styles';

export const WelcomeDialog = () => {
  const classes = useWelcomeDialogStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const { data: { user = undefined } = {}, loading } = useQuery(USER_QUERY);
  const prevLoading = usePrevious(loading);
  const formMethods = useForm({ defaultValues: user && deserializeProfile(user) });
  const { handleSubmit, reset, watch } = formMethods;
  // @ts-ignore - circular dependency on ts level
  const formValue = watch('name');
  const { features } = useDomainFeatures();
  const nextRoute = useMemo(
    () => (isFeatureAvailable('dashboard', features) ? '/dashboard/' : '/history/'),
    [features]
  );
  const onCompleted = () => navigate(nextRoute);
  const [initializeEmployee, { error, loading: loadingUpdate }] = useMutation(
    INITIALIZE_EMPLOYEE_MUTATION,
    {
      onCompleted,
      refetchQueries: [{ query: USER_QUERY }],
    }
  );
  const { data: numbersData, loading: loadingPhone } = useQuery(DOMAIN_ALL_NUMBERS_QUERY);

  const { domainNumbers = [] } = numbersData || {};

  const phone = domainNumbers.find((number) => number.status === NumberStatus.Bought)?.phone;
  const reservedPhone = domainNumbers.find((number) => number.status === NumberStatus.Reserved)
    ?.phone;

  useEffect(() => {
    if (user && !loading && prevLoading) {
      reset(deserializeProfile(user));
    }
  }, [reset, user, prevLoading, loading]);

  const handleFormSubmit = useCallback(
    ({ name, position }: { name: string; position?: string }) => {
      removeStorageItem('welcome');
      const currentEmployeeId = user?.domains?.find(({ current }) => current)?.employees?.[0]?.id;
      if (currentEmployeeId) {
        initializeEmployee({
          variables: {
            data: {
              id: currentEmployeeId,
              name,
              position,
            },
          },
        });
      }
    },
    [initializeEmployee, user]
  );

  const renderContent = () => {
    if (loading || loadingPhone) {
      return <Skeleton />;
    }

    return (
      <>
        <div className={classes.textBlock}>
          <Typography>{translate('PERSONAL_NUMBER_DEDICATED')}</Typography>
          <Typography color={'tertiary900'} type={'text2'} bold>
            {formatPhone(phone || '')}
          </Typography>
        </div>
        <Typography className={classes.descriptionText}>
          {translate('DEFINE_NAME_AND_POSITION')}
        </Typography>
        <FormProvider {...formMethods}>
          <form
            id={'welcome-form'}
            action={''}
            onSubmit={handleSubmit(handleFormSubmit)}
            className={classes.form}
          >
            <FormFieldRhfUncontrolled
              disabled={loadingUpdate}
              name={'name'}
              label={translate('FULL_NAME')}
              inputProps={{
                maxLength: 100,
              }}
            />
            <FormFieldRhfUncontrolled
              disabled={loadingUpdate}
              name={'position'}
              label={translate('POSITION')}
              inputProps={{
                maxLength: 100,
              }}
            />
            {reservedPhone && (
              <div className={classes.reservedBlock}>
                <Translate
                  i18nKey={'RESERVED_NUMBER_DEDICATED'}
                  values={{
                    reservedNumber: formatPhone(reservedPhone),
                  }}
                  components={{
                    b: <b />,
                    n: <br />,
                    t: <Typography color="tertiary900" type={'text4'} />,
                  }}
                />
              </div>
            )}
            <Button
              title={translate('LET_GO')}
              form={'welcome-form'}
              type={'submit'}
              className={classes.button}
              disabled={!formValue}
            />
            {error && <FormErrorMessage errors={error?.graphQLErrors} />}
          </form>
        </FormProvider>
      </>
    );
  };

  return (
    <Dialog
      aria-labelledby={'welcome-dialog'}
      onClose={() => navigate(nextRoute)}
      open
      className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.textBlock}>
          <Typography className={classes.welcomeTitle} bold color={'tertiary900'} type={'text2'}>
            {translate('WELCOME')}
          </Typography>
          <Typography color={'primary700'} type={'text2'}>
            {translate('SOFT_SWITCH_SUCCESSFULLY_CREATED')}
          </Typography>
        </div>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeDialog;
