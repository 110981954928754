import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/components/Button/Button';
import RadioGroup from '@shared/components/RadioGroup';
import {
  DOMAIN_NUMBERS_QUERY,
  GET_ALL_MISSED_CALLS_QUERY,
  GET_DEPARTMENTS_AND_EMPLOYEES_QUERY,
  GET_ONE_MISSED_CALL_QUERY,
  USER_QUERY,
} from '@/client/queries';
import { FormControlLabel } from '@material-ui/core';
import SelectField, { ISelectFieldOptionRendererProps } from '@shared/components/SelectField';
import Typography from '@shared/components/Typography';
import EmailField from '@shared/components/EmailField';
import Radio from '@shared/components/Radio';
import Translate from '@shared/components/Translate';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { normalizePhone } from '@shared/utils';
import ComboBoxField from '@shared/components/ComboBoxField';
import {
  CREATE_MISSED_CALL_CONFIG_MUTATION,
  REMOVE_MISSED_CALL_CONFIG_MUTATION,
  UPDATE_MISSED_CALL_CONFIG_MUTATION,
} from '@/client/mutations';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import { useRoutes } from '@components/Routes';
import MessageDialog from '@shared/components/MessageDialog';
import Flex from '@shared/components/Flex';
import clsx from 'clsx';
import { useFormErrors } from '@/common/hooks';
import PhoneField from '@shared/components/PhoneField';
import ControlButtons from '@shared/components/ControlButtons';
import { NotificationTypes, IDepOrEmployees } from '@components/typings/interfaces';
import { CheckIcon } from '@shared/assets/images/icons';
import BodyContainer from '@/layouts/BodyContainer';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { isCertainErrorMessageInApolloErrorErrors } from '@shared/utils/apollo';
import { NotificationStyles } from './Notification.styles';
import {
  INotificationReceivers,
  IMissedCalls,
  INotificationData,
  IReceivers,
  NotificationSource,
} from '../SettingsNotifications.interface';
import { RecipientCard } from './modules';

export const Notification: FunctionComponent = () => {
  const classes = NotificationStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const formMethods = useForm({
    shouldUnregister: true,
  });
  const {
    watch,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = formMethods;
  const {
    path: { subcategory, identifier },
  } = useRoutes();
  const [recipientsArray, setRecipientsArray] = useState<(IDepOrEmployees | null)[]>([]);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [showErrorDialog, setShowErrorDialog] = useState({
    showDialog: false,
    title: '',
    message: '',
  });
  const [isChanged, setIsChanged] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });
  const isMissedCallEdit = identifier !== 'add';
  const selectedType = watch('scenarioMissedCalls');

  const { data: { boughtDomainNumbers: domainNumbers = [] } = { boughtDomainNumbers: [] } } =
    useQuery<{
      boughtDomainNumbers: { id: number; phone: string; city: string }[];
    }>(DOMAIN_NUMBERS_QUERY, { fetchPolicy: 'cache-first' });
  const { data: { user } = {} } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });

  const { data: { departmentsAndEmployees = [] } = {} } = useQuery<{
    departmentsAndEmployees: IDepOrEmployees[];
  }>(GET_DEPARTMENTS_AND_EMPLOYEES_QUERY, { fetchPolicy: 'no-cache' });

  const [
    createNotification,
    {
      error: errorCreateNotification,
      called: calledCreateNotification,
      loading: loadingCreateNotification,
    },
  ] = useMutation(CREATE_MISSED_CALL_CONFIG_MUTATION);
  const [
    updateNotification,
    {
      error: errorUpdateNotification,
      called: calledUpdateNotification,
      loading: loadingUpdateNotification,
    },
  ] = useMutation(UPDATE_MISSED_CALL_CONFIG_MUTATION);
  const [removeNotification, { loading: loadingRemoveNotification }] = useMutation(
    REMOVE_MISSED_CALL_CONFIG_MUTATION,
    {
      refetchQueries: [{ query: GET_ALL_MISSED_CALLS_QUERY }],
    }
  );
  // TODO handle lazy query error
  const [getOneMissedCall, { data: { getOneMissedCallConfig = null } = {} }] =
    useLazyQuery(GET_ONE_MISSED_CALL_QUERY);
  useFormErrors(errorUpdateNotification?.graphQLErrors, formMethods);
  useFormErrors(errorCreateNotification?.graphQLErrors, formMethods);
  const notificationNumber = getOneMissedCallConfig?.domainNumber
    ? formatPhone(getOneMissedCallConfig?.domainNumber.phone)
    : getOneMissedCallConfig?.employee?.name || getOneMissedCallConfig?.department?.name;
  const dialogReceiversText = getOneMissedCallConfig?.allNumbers
    ? translate('ALL_NUMBERS')
    : notificationNumber;
  const userDomain = user && getCurrentDomain(user)?.domain;

  const optionNumber = useMemo(
    () => [
      {
        id: -1,
        phone: 'ALL_NUMBERS',
      },
      ...domainNumbers,
    ],
    [domainNumbers]
  );

  let numberId = 0;
  if (
    getOneMissedCallConfig &&
    (getOneMissedCallConfig.allNumbers || getOneMissedCallConfig.domainNumber)
  ) {
    numberId = getOneMissedCallConfig.allNumbers ? -1 : getOneMissedCallConfig.domainNumber.id;
  }
  let empOrDepId = '';
  if (
    selectedType === NotificationSource.employeeOrDep &&
    getOneMissedCallConfig &&
    (getOneMissedCallConfig?.employee?.id || getOneMissedCallConfig?.department?.id)
  ) {
    empOrDepId =
      departmentsAndEmployees.find(
        (el) =>
          el.id === (getOneMissedCallConfig?.employee?.id || getOneMissedCallConfig?.department?.id)
      )?.listId || '';
  }

  useEffect(() => {
    if (numberId) {
      setValue('numberId', numberId);
    }
  }, [numberId, setValue]);

  useEffect(() => {
    if (empOrDepId) {
      setValue('callerId', empOrDepId);
    }
  }, [empOrDepId, setValue]);

  function handleFormChange() {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  }

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  useEffect(() => {
    if (!getOneMissedCallConfig && isMissedCallEdit) {
      getOneMissedCall({
        variables: { id: Number(identifier) || undefined },
      });
    }
  }, [getOneMissedCall, getOneMissedCallConfig, identifier, isMissedCallEdit, subcategory]);

  useEffect(() => {
    if (!getOneMissedCallConfig) return;
    if (getOneMissedCallConfig.allNumbers || getOneMissedCallConfig.domainNumber) {
      setValue('scenarioMissedCalls', NotificationSource.phoneNumber);
    }
    if (getOneMissedCallConfig?.employee?.id || getOneMissedCallConfig?.department?.id) {
      setValue('scenarioMissedCalls', NotificationSource.employeeOrDep);
    }

    const recipientArrayData = getOneMissedCallConfig?.receivers
      ?.filter((el: IMissedCalls) => el.department || el.employee)
      .reduce((result: IDepOrEmployees[], rec: IReceivers) => {
        const listId = rec.employee
          ? `${rec?.employee?.id}_EMPLOYEE`
          : `${rec?.department?.id}_DEPARTMENT`;
        const id = rec.employee ? rec?.employee?.id : rec?.department?.id;
        const receiversType = rec.employee ? 'EMPLOYEES' : 'DEPARTMENTS';
        const departmentCounter = {
          emailsCount: rec?.department?.emailCount,
          phonesCount: rec?.department?.numberCount,
          employeesCount: rec?.department?.employeesCount,
        };
        const emailNotification = rec.notificationTypes?.includes(NotificationTypes.Email);
        const smsNotification = rec.notificationTypes?.includes(NotificationTypes.SMS);

        result.push({
          email: rec.employee ? rec.employee.email : undefined,
          phone: rec.employee ? rec.employee.phone : undefined,
          emailsCount: departmentCounter.emailsCount,
          emailNotification,
          listId,
          id,
          phonesCount: departmentCounter.phonesCount,
          smsNotification,
          type: receiversType,
          employeesCount: departmentCounter.employeesCount,
          hasNotificationMethod: emailNotification || smsNotification,
        });
        return result;
      }, []);
    setRecipientsArray([...recipientArrayData]);

    getOneMissedCallConfig?.receivers
      ?.filter((el: IMissedCalls) => !el.department && !el.employee)
      ?.forEach((rec: IReceivers) => {
        if (rec.notificationTypes) {
          rec.notificationTypes.forEach((notificationEl) => {
            if (notificationEl === 'sms') {
              setValue('additionalPhone', rec.external);
            }
            if (notificationEl === 'email') {
              setValue('additionalEmail', rec.external);
            }
          });
        }
      });
  }, [departmentsAndEmployees, getOneMissedCallConfig, optionNumber, setValue]);

  const handleRemoveNotification = () => {
    removeNotification({
      variables: {
        data: {
          missedCallId: Number(identifier),
        },
      },
    });
    navigate('/settings/notifications');
  };

  const handleAddNewRecipient = () => {
    handleFormChange();
    setRecipientsArray([...recipientsArray, null]);
  };

  const handleClosePopupDialog = () => {
    setIsRemoveDialogOpen(false);
  };

  function handleChanges(recipientItem: IDepOrEmployees, i: number) {
    const newRecipientArray = [...recipientsArray];
    clearErrors(`recipientCard[${i}]`);
    handleFormChange();
    newRecipientArray[i] = { ...recipientItem };
    setRecipientsArray(newRecipientArray);
  }

  function handleRemoveRecipient(i: number) {
    if (recipientsArray) {
      const updatedRecipientsList = [...recipientsArray];
      updatedRecipientsList.splice(i, 1);
      setRecipientsArray(updatedRecipientsList);
    }
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
    clearErrors(`recipientCard[${i}]`);
  }

  function handleCancelClick() {
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
    navigate(-1);
  }

  function handleCloseErrorDialog() {
    setShowErrorDialog({
      showDialog: false,
      title: '',
      message: '',
    });
  }

  const handleFormSubmit = useCallback(
    (data) => {
      const filterCallerData = data.callerId ? data.callerId.split('_') : null;
      const isDepartment = filterCallerData ? filterCallerData[1] === 'DEPARTMENT' : null;
      const isEmployee = filterCallerData ? filterCallerData[1] === 'EMPLOYEE' : null;
      let hasError = false;
      const selectedRecipients: INotificationReceivers[] = [];
      recipientsArray.forEach((rec, i: number) => {
        const notificationArray: NotificationTypes[] = [];
        let departmentId = null;
        let employeeId = null;
        if (!rec?.id) {
          if (!data.additionalPhone && !data.additionalEmail) {
            setError(`selectedRecipient-${i}`, {
              type: 'manual',
              message: selectedRecipients.length
                ? translate('SELECT_EMPLOYEE_OR_DEPARTMENT')
                : translate('SELECT_EMPLOYEE_DEPARTMENT_OR_EXT'),
            });
            hasError = true;
          }
        } else {
          if (rec?.smsNotification) {
            notificationArray.push(NotificationTypes.SMS);
          }
          if (rec?.emailNotification) {
            notificationArray.push(NotificationTypes.Email);
          }
          if (!rec?.hasNotificationMethod) {
            setError(`recipientCard[${i}]`, {
              type: 'manual',
              message: 'NO_AVAILABLE_NOTIFICATION_METHOD',
            });
            hasError = true;
          } else if (notificationArray.length === 0) {
            setError(`recipientCard[${i}]`, {
              type: 'manual',
              message: 'INVALID_NOTIFICATION_TYPE',
            });
            hasError = true;
          }
          if (rec?.type === 'DEPARTMENTS') {
            departmentId = Number(rec.id);
          }
          if (rec?.type === 'EMPLOYEES') {
            employeeId = Number(rec.id);
          }
          selectedRecipients.push({
            departmentId,
            employeeId,
            notificationTypes: notificationArray,
          });
        }
      });
      if (hasError) {
        return;
      }
      if (data.additionalPhone) {
        selectedRecipients.push({
          external: normalizePhone(data.additionalPhone),
          notificationTypes: [NotificationTypes.SMS],
        });
      }
      if (data.additionalEmail) {
        selectedRecipients.push({
          external: data.additionalEmail,
          notificationTypes: [NotificationTypes.Email],
        });
      }
      const notificationData: INotificationData = {
        allNumbers: false,
        domainNumberId: null,
        departmentId: null,
        employeeId: null,
        receivers: selectedRecipients,
      };
      if (data.scenarioMissedCalls === NotificationSource.phoneNumber) {
        if (data.numberId === -1) {
          notificationData.allNumbers = true;
        } else if (data.numberId) {
          notificationData.domainNumberId = data.numberId;
        }
      } else if (isDepartment) {
        notificationData.departmentId = Number(filterCallerData[0]);
      } else if (isEmployee) {
        notificationData.employeeId = Number(filterCallerData[0]);
      }
      if (!isMissedCallEdit) {
        createNotification({
          variables: {
            data: notificationData,
          },
        }).catch((error) => {
          let errorTitle = 'SOMETHING_WENT_WRONG';
          let errorMessage = 'SOMETHING_WENT_WRONG';
          if (isCertainErrorMessageInApolloErrorErrors(error, 'all_numbers must be unique')) {
            errorTitle = 'ERROR';
            errorMessage = 'ALL_NUMBERS_NOTIFICATION_SELECTED';
          }
          setShowErrorDialog({ showDialog: true, title: errorTitle, message: errorMessage });
        });
      } else {
        updateNotification({
          variables: {
            data: {
              id: Number(identifier),
              ...notificationData,
            },
          },
        }).catch((error) => {
          let errorTitle = 'SOMETHING_WENT_WRONG';
          let errorMessage = 'SOMETHING_WENT_WRONG';

          if (isCertainErrorMessageInApolloErrorErrors(error, 'all_numbers must be unique')) {
            errorTitle = 'ERROR';
            errorMessage = 'ALL_NUMBERS_NOTIFICATION_SELECTED';
          }
          setShowErrorDialog({ showDialog: true, title: errorTitle, message: errorMessage });
        });
      }
    },
    [
      createNotification,
      identifier,
      isMissedCallEdit,
      recipientsArray,
      setError,
      translate,
      updateNotification,
    ]
  );

  useEffect(() => {
    if (
      (calledCreateNotification && !loadingCreateNotification && !errorCreateNotification) ||
      (calledUpdateNotification && !loadingUpdateNotification && !errorUpdateNotification)
    ) {
      navigate(blockedPath || '/settings/notifications');
    }
  }, [
    blockedPath,
    calledCreateNotification,
    calledUpdateNotification,
    errorCreateNotification,
    errorUpdateNotification,
    loadingCreateNotification,
    loadingUpdateNotification,
    navigate,
  ]);

  useEffect(() => {
    if (
      (calledCreateNotification || calledUpdateNotification) &&
      !errorCreateNotification &&
      !errorUpdateNotification
    ) {
      setIsChanged({
        isBlocking: false,
        action: ConfirmAction.Finish,
      });
    }
  }, [
    calledCreateNotification,
    calledUpdateNotification,
    errorCreateNotification,
    errorUpdateNotification,
  ]);

  useEffect(() => {
    if (recipientsArray.length === 0) {
      setRecipientsArray([null]);
    }
  }, [isMissedCallEdit, recipientsArray]);

  const renderCustomOption = ({
    data,
    selected,
  }: ISelectFieldOptionRendererProps<{ id: string | number; phone: string }>) => {
    const { id: numId, phone = '' } = data;

    if (numId === undefined) {
      return (
        <div className={classes.customOption}>
          <Typography type={'text3'} color={'tertiary300'}>
            {translate('CHOOSE')}
          </Typography>
        </div>
      );
    }

    return (
      <div className={classes.customOption}>
        <Flex alignItems={'center'}>
          {selected && <CheckIcon className={classes.glyph} />}
          <Typography type={'text3'} color={selected ? 'primary700' : 'tertiary900'}>
            {phone === 'ALL_NUMBERS' ? translate('ALL_NUMBERS') : formatPhone(phone)}
          </Typography>
        </Flex>
      </div>
    );
  };

  const renderSourceCard = () => {
    const classNames = clsx({
      [classes.phoneNumberCard]: selectedType === NotificationSource.phoneNumber,
      [classes.employeeOrDepCard]: selectedType === NotificationSource.employeeOrDep,
    });
    if (selectedType === NotificationSource.phoneNumber) {
      return (
        <div className={classNames}>
          <SelectField
            label={translate('PHONE_NUMBER')}
            name={'numberId'}
            data={optionNumber}
            Renderer={renderCustomOption}
            valueKey={'id'}
            titleKey={'phone'}
            defaultValue={numberId || undefined}
            validate={(value: string) => {
              if (!value) {
                return translate('CHOOSE_NUMBER') as string;
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
      );
    }
    if (selectedType === NotificationSource.employeeOrDep) {
      return (
        <div className={classNames}>
          <ComboBoxField
            label={translate('EMPLOYEES_AND_DEPARTMENTS')}
            data={departmentsAndEmployees.sort(({ type: typeA }, { type: typeB }) => {
              if (typeA < typeB) return -1;
              if (typeA > typeB) return 1;
              return 0;
            })}
            name={'callerId'}
            valueKey={'listId'}
            titleKey={'name'}
            defaultValue={empOrDepId || undefined}
            groupBy={(option: { type: string }) => translate(option.type || '', '')}
            placeholder={translate('CHOOSE')}
            validate={(value: string) => {
              if (!value) {
                return translate('SELECT_EMPLOYEE_OR_DEPARTMENT') as string;
              }
              return true;
            }}
            onChange={handleFormChange}
          />
        </div>
      );
    }
    return null;
  };

  const renderReceiversCards = () => {
    if (recipientsArray) {
      return recipientsArray.map((rec, i) => (
        <RecipientCard
          recipient={rec}
          cardIndex={i}
          name={`recipientCard[${i}]`}
          key={`recipientCard_${i}`}
          data={departmentsAndEmployees.filter(
            ({ listId }) =>
              listId === rec?.listId || !recipientsArray?.some((r) => r?.listId === listId)
          )}
          onDelete={handleRemoveRecipient}
          onChange={handleChanges}
          // @ts-ignore invalid type parsing
          error={errors?.recipientCard ? errors?.recipientCard[i] : false}
          userDomain={userDomain}
        />
      ));
    }
    return null;
  };

  return (
    <BodyContainer>
      <Flex direction={'column'} className={classes.root}>
        <FormProvider {...formMethods}>
          <form
            id={'create-notification'}
            action={''}
            className={classes.form}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('CREATE_NOTIFICATION_TITLE')}
            </Typography>
            <RadioGroup
              className={classes.redirectRadioGroup}
              defaultValue={NotificationSource.phoneNumber}
              name={'scenarioMissedCalls'}
              onChange={handleFormChange}
            >
              <div className={classes.integrationRadioGroup}>
                <FormControlLabel
                  value={NotificationSource.phoneNumber}
                  control={<Radio color={'secondary'} />}
                  label={translate('PHONE_NUMBER')}
                />
                <FormControlLabel
                  value={NotificationSource.employeeOrDep}
                  control={<Radio color={'secondary'} />}
                  label={translate('EMPLOYEE_OR_DEPARTMENT')}
                />
              </div>
            </RadioGroup>
            {renderSourceCard()}
            <Typography className={classes.notifyTitle} type={'text2'} color={'tertiary900'}>
              {translate('NOTIFY')}
            </Typography>
            {renderReceiversCards()}
            <Button
              title={translate('ADD_RECIPIENT')}
              className={classes.addRecipientButton}
              variant={'secondary'}
              onClick={handleAddNewRecipient}
              disabled={
                recipientsArray.some((rec) => rec === null) ||
                departmentsAndEmployees.filter(
                  ({ listId }) => !recipientsArray?.some((r) => r?.listId === listId)
                )?.length === 0
              }
            />
            <div className={classes.additionalContainer}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('ADDITIONAL')}
              </Typography>
              <div className={classes.additionalFields}>
                <div className={classes.notificationFieldContainer}>
                  <PhoneField
                    name={'additionalPhone'}
                    label={translate('SEND_SMS')}
                    onChange={handleFormChange}
                  />
                </div>
                <div className={classes.notificationFieldContainer}>
                  <EmailField
                    name={'additionalEmail'}
                    label={`${translate('SEND_EMAIL')}:`}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
            </div>
            <div className={classes.actions}>
              <div>
                <ControlButtons
                  confirmTitle={'SAVE_CHANGES'}
                  cancelTitle={'CANCEL'}
                  form={'create-notification'}
                  onCancelClick={handleCancelClick}
                  rootConfirmStyles={classes.button}
                  rootCancelBtn={classes.cancelButton}
                  loading={loadingCreateNotification || loadingUpdateNotification}
                />
              </div>
              {isMissedCallEdit && (
                <Button variant={'tertiary'} onClick={() => setIsRemoveDialogOpen(true)}>
                  <Typography underline type={'text3'} color={'tertiary400'}>
                    {translate('REMOVE_NOTIFICATION')}
                  </Typography>
                </Button>
              )}
            </div>
          </form>
        </FormProvider>
        <ConfirmDialog
          isBlocked={isChanged.isBlocking}
          onSaveChanges={handleSubmit(handleFormSubmit)}
          onNavigationBlocked={getBlockedPath}
        />
        <MessageDialog
          isOpen={isRemoveDialogOpen}
          title={translate('DELETE_EMPLOYEE_PROMPT')}
          onCancel={handleClosePopupDialog}
          contentClass={classes.confirmPopupDialog}
          renderContent={
            <>
              <div className={classes.confirmPopupText}>
                <Translate
                  i18nKey={'REMOVE_NOTIFICATION_INFORMATION'}
                  values={{
                    dialogReceiversText,
                  }}
                  components={{
                    t: <Typography type={'text3'} color={'tertiary900'} />,
                    s: <Typography type={'text3'} color={'primary700'} medium />,
                  }}
                />
              </div>
              <ControlButtons
                confirmTitle={'REMOVE'}
                confirmColor={'error'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                onConfirmClick={() => handleRemoveNotification()}
                onCancelClick={handleClosePopupDialog}
                loading={loadingRemoveNotification}
                small
              />
            </>
          }
        />
        <MessageDialog
          isOpen={showErrorDialog.showDialog}
          title={translate(showErrorDialog.title)}
          message={translate(showErrorDialog.message)}
          onCancel={handleCloseErrorDialog}
          contentClass={classes.confirmPopupDialog}
          renderControls={
            <Button title={translate('CLOSE')} onClick={handleCloseErrorDialog} smallHeight />
          }
        />
      </Flex>
    </BodyContainer>
  );
};

export default Notification;
