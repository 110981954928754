import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPhone } from '@components/utils';
import { ICellProps } from '@components/LazyTable';
import { useSearchParams } from 'react-router-dom';
import HighlightedText from '@components/HighlightedText';
import Flex from '@shared/components/Flex';
import { useBusinessCardsStyles } from '../SettingsBusinessCards.styles';
import { IBusinessCard, IBusinessCardSource } from '../SettingsBusinessCards.interface';

export const BusinessCardsNumber: FunctionComponent<ICellProps<IBusinessCard>> = ({ item }) => {
  const { sources } = item || {};
  const [translate] = useTranslation();
  const classes = useBusinessCardsStyles();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('search') || null;

  const renderContentContainer = () => {
    if (sources && sources.length) {
      return sources.map((el: IBusinessCardSource, index: number) => {
        const phoneNumber = formatPhone(String(el.number?.phone));
        return (
          <div key={`container_${index}`} className={classes.numberContainer}>
            <HighlightedText
              type={el.allNumbers ? 'text' : 'phone'}
              text={el.allNumbers ? translate('ALL_NUMBERS') : formatPhone(phoneNumber)}
              query={searchText}
            />
          </div>
        );
      });
    }
    return null;
  };

  return <Flex direction={'column'}>{renderContentContainer()}</Flex>;
};
