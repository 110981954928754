import React from 'react';
import { useNumbersStyle } from '@/features/FinancialCabinet/Numbers/Numbers.styles';
import { IDomainNumber, NumberStatus } from '@components/typings/interfaces';
import { INumberDiscountProps } from '@/features/FinancialCabinet/Numbers/Numbers.interfaces';

export const NumberDiscount = ({ discount }: INumberDiscountProps) => {
  const classes = useNumbersStyle();
  const postfix = '%';

  // формируем выводиинформации
  return (
    <span className={classes.numberDiscount}>
      (-{discount}
      {postfix})
    </span>
  );
};

export const deserializeNumber = (data: IDomainNumber) => {
  const { status, statusTill, ...resetProps } = data;

  return {
    ...resetProps,
    statusTill,
    status: status === NumberStatus.Bought && statusTill ? NumberStatus.WillDisabled : status,
  };
};
