import React, { ChangeEvent, FunctionComponent, memo, useEffect, useMemo, useState } from 'react';
import Typography from '@shared/components/Typography';
import Button from '@shared/components/Button';
import ComboBoxField, { ComboBoxFieldReasonType } from '@shared/components/ComboBoxField';
import { formatPhone } from '@components/utils';
import { AlertCircleIcon, TrashIcon } from '@shared/assets/images/icons';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Flex from '@shared/components/Flex';
import {
  IOptionData,
  IScenarioNumbersProps,
  IUsedIntegrationList,
  selectedNumbersType,
} from '../IntegrationForm.interfaces';
import { useIntegrationFormStyles } from '../IntegrationForm.styles';

export const ScenarioNumbersList: FunctionComponent<IScenarioNumbersProps> = ({
  availableDomainNumbers = [],
  selectedNumbers,
  onNumbersChange,
}) => {
  const formMethods = useForm();
  const upperFormContext = useFormContext();
  const { setValue } = formMethods;
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const [isAddButtonVisible, setAddButtonVisible] = useState(true);
  const [shortNumberList, setShortNumberList] = useState(true);
  const selectedNumbersKeys = useMemo(
    () => (selectedNumbers ? Object.keys(selectedNumbers) : []),
    [selectedNumbers]
  );

  useEffect(() => {
    if (availableDomainNumbers.length === 1 && selectedNumbersKeys.length === 0) {
      const singleNumber = availableDomainNumbers[0];
      const newPhoneItem = { [singleNumber.id]: singleNumber };
      onNumbersChange?.(newPhoneItem);
    }
  }, [availableDomainNumbers, onNumbersChange, selectedNumbersKeys]);

  function handleNumberSelect(
    event: ChangeEvent<unknown>,
    item: IOptionData,
    reason: ComboBoxFieldReasonType
  ) {
    if (reason === 'select-option') {
      const newPhoneItem = { [item.id]: item };
      setValue('numberList', '');
      setAddButtonVisible(true);
      onNumbersChange?.({ ...selectedNumbers, ...newPhoneItem });
    }
  }

  function handleDeleteSelectedNumber(numberId: number) {
    const newPhoneItem: selectedNumbersType = { ...selectedNumbers };
    delete newPhoneItem[numberId];
    onNumbersChange?.(newPhoneItem);
  }

  function groupSelectedNumbers(option: IOptionData) {
    if (selectedNumbersKeys.includes(String(option.id))) {
      return translate('SCENARIO_NUMBER_USED') as string;
    }
    return translate('AVAILABLE_NUMBER') as string;
  }

  function getOptionDisabled(option: IOptionData) {
    return selectedNumbersKeys.includes(String(option.id));
  }

  const isNumbersAvailable = availableDomainNumbers.reduce(
    (result: boolean, option: IOptionData) => {
      if (!selectedNumbersKeys.includes(String(option.id))) {
        return true;
      }
      return result;
    },
    false
  );

  const sortNumbersData = (numbersData: IOptionData[]) => {
    const data = [...numbersData];
    return data.sort(({ id: idTypeA }, { id: idTypeB }) => {
      if (
        !selectedNumbersKeys.includes(String(idTypeA)) &&
        selectedNumbersKeys.includes(String(idTypeB))
      ) {
        return -1;
      }
      if (
        selectedNumbersKeys.includes(String(idTypeA)) &&
        !selectedNumbersKeys.includes(String(idTypeB))
      ) {
        return 1;
      }
      return 0;
    });
  };

  const renderSelectOptions = (data: IOptionData) => {
    const { phone, city, name } = data;

    return (
      <Flex justifyContent={'center'} direction={'column'} className={classes.selectOptionLine}>
        <Typography type={'text3'} color={'tertiary900'}>
          {formatPhone(phone)}
        </Typography>
        <Typography type={'text4'} color={'tertiary600'}>
          {name || city}
        </Typography>
      </Flex>
    );
  };

  const renderSelectedNumbers = () => {
    if (selectedNumbers) {
      const numbList: IOptionData[] = Object.values(selectedNumbers);
      if (shortNumberList) {
        numbList.splice(2);
      }
      return numbList.map((item, index) => {
        const isAlertIconVisible = item.integrations.reduce(
          (result: boolean, integration: IUsedIntegrationList) => {
            if (upperFormContext.getValues().integrationName !== integration.name) {
              return false;
            }
            return result;
          },
          false
        );
        return (
          <Flex
            key={`numberItem-${index}`}
            justifyContent={'spaceBetween'}
            alignItems={'center'}
            className={classes.integrationNumberItem}
          >
            <Flex direction={'column'} className={classes.defaultElementWidth14}>
              <Typography type={'text3'} color={'tertiary900'}>
                {formatPhone(item.phone)}
              </Typography>
              <div className={classes.integrationNumberInfoAddress}>
                <Typography type={'text4'} color={'tertiary600'}>
                  {item.name || item.city}
                </Typography>
              </div>
            </Flex>
            <Flex alignItems={'center'}>
              {isAlertIconVisible && <AlertCircleIcon className={classes.alertIcon} />}
              {!(availableDomainNumbers.length === 0 && selectedNumbersKeys.length === 1) && (
                <Button
                  className={classes.integrationNumberDeleteButton}
                  variant={'tertiary'}
                  onClick={() => handleDeleteSelectedNumber(item.id)}
                >
                  <TrashIcon />
                </Button>
              )}
            </Flex>
          </Flex>
        );
      });
    }
    return null;
  };

  const renderShowMoreNumbersButton = () => {
    if (shortNumberList && selectedNumbersKeys.length > 2) {
      return (
        <Button
          title={translate('MORE_ITEMS', { count: selectedNumbersKeys.length - 2 })}
          disableElevation
          disableRipple
          variant={'tertiary'}
          onClick={() => setShortNumberList(false)}
          className={classes.defaultElementWidthFull}
        />
      );
    }
    return null;
  };

  return (
    <FormProvider {...formMethods}>
      <div className={classes.integrationScenarioNumbersList}>
        <Typography type={'text3'} color={'tertiary900'} bold>
          {translate('CHOOSEN_NUMBER')}
        </Typography>
        <div className={classes.integrationNumberItemWrapper}>
          <div className={classes.integrationNumberItemList}>
            {renderSelectedNumbers()}
            {renderShowMoreNumbersButton()}
          </div>
          {isNumbersAvailable ? (
            <>
              {isAddButtonVisible ? (
                <Button
                  className={classes.integrationAddButton}
                  onClick={() => setAddButtonVisible(false)}
                  title={translate('ADD')}
                  variant={'secondary'}
                />
              ) : (
                <ComboBoxField
                  name={'numberList'}
                  placeholder={translate('CHOOSE_NUMBER')}
                  valueKey={'id'}
                  titleKey={'phone'}
                  classes={{ root: classes.chooseNumberRoot }}
                  defaultValue={undefined}
                  getOptionDisabled={getOptionDisabled}
                  data={sortNumbersData(availableDomainNumbers)}
                  onChange={handleNumberSelect}
                  groupBy={groupSelectedNumbers}
                  renderOption={renderSelectOptions}
                />
              )}
              {selectedNumbersKeys.length === 0 && (
                <div className={classes.integrationNumbersNotSelectedWarning}>
                  <Typography type={'text5'} color={'danger600'}>
                    {translate('CHOOSE_NUMBER_REQUIRED_MESSAGE')}
                  </Typography>
                </div>
              )}
            </>
          ) : (
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              className={classes.integrationAvailableNumberMessage}
            >
              <Typography type={'text4'} color={'tertiary600'} bold>
                {translate('NO_AVAILABLE_NUMBERS')}
              </Typography>
            </Flex>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default memo(ScenarioNumbersList);
