import React, { Fragment, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { weekDays } from '@/common/constants';
import {
  SchedulePeriodsTypes,
  ServerSchedulePeriod,
} from '@/features/IncomingNumber/IncomingNumber.interfaces';
import {
  formatMinutesToHHmm,
  initPeriods,
} from '@/features/IncomingNumber/Scheduler/Scheduler.helpers';
import { ISchedulePeriod } from '@/features/IncomingNumber/Scheduler/Scheduler.interfaces';
import Typography from '@shared/components/Typography/Typography';
import { Button } from '@shared/components/Button/Button';
import { ICellProps } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import { AlertCircleIcon } from '@shared/assets/images/icons';
import { IIncomingNumber, IncomingNumberStatusType } from '../IncomingNumbers.interfaces';
import { useIncomingNumbersStyles } from '../IncomingNumbers.styles';

export const IncomingNumberPeriodsCell: FunctionComponent<ICellProps<IIncomingNumber>> = ({
  item,
}) => {
  const classes = useIncomingNumbersStyles();
  const [translate] = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const renderPeriodSchedule = ({ key }: ISchedulePeriod) => {
    const periodSchedule = (item?.scheduler?.[key] || [])
      .reduce<(ServerSchedulePeriod & { time: string })[]>((r, next) => {
        const foundDay = r.find(
          ({ days: rDays }) =>
            rDays.length === next.days.length &&
            JSON.stringify([...rDays].sort()) === JSON.stringify([...next.days].sort())
        );
        const time = `${formatMinutesToHHmm(next.start)}-${formatMinutesToHHmm(next.end)}`;
        if (foundDay) {
          foundDay.time += `, ${time}`;
        } else {
          r.push({ ...next, time });
        }
        return r;
      }, [])
      .map(({ time, days }) => {
        if (days.length > 1) {
          return `${translate(weekDays[days[0]].abbreviationCode)}-${translate(
            weekDays[days[days.length - 1]].abbreviationCode
          )}:\xa0${time}`;
        }
        return `${translate(weekDays[days[0]].abbreviationCode)}:\xa0${time}`;
      })
      .join('; ');
    return <Fragment key={`${item?.id}-${key}`}>{`${periodSchedule};`}</Fragment>;
  };

  const renderPeriodName = ({ name, titleCode }: ISchedulePeriod) => {
    const text = name || translate(titleCode);
    return text || '\xa0';
  };

  const renderPeriod = (period: ISchedulePeriod, index: number) => (
    <div key={`${item?.id}-period-${index}`} className={classes.numberPeriods}>
      <div className={classes.numberPeriodName}>
        <Typography type={'text3'} color={'tertiary900'}>
          {renderPeriodName(period)}
        </Typography>
      </div>
      <Typography type={'text3'} color={'tertiary900'}>
        {period.key === SchedulePeriodsTypes.NonWorkingHours
          ? translate('REST_TIME')
          : renderPeriodSchedule(period)}
      </Typography>
    </div>
  );

  const renderSchedulePeriods = () => {
    if (!item?.hasSchedule) {
      return (
        <Typography type={'text3'} color={'tertiary900'}>
          {translate('ATC')}
        </Typography>
      );
    }

    const periods = initPeriods(item?.periods || []).filter(
      ({ key }) => key !== SchedulePeriodsTypes.ATC
    );
    const nonWorkingPeriodIndex = periods.findIndex(
      ({ key }) => key === SchedulePeriodsTypes.NonWorkingHours
    );
    if (nonWorkingPeriodIndex >= 0) {
      periods.push(periods.splice(nonWorkingPeriodIndex, 1)[0]);
    }
    const expandable = periods.length > 3;
    let collapsed = [];
    if (expandable && !expanded) {
      collapsed = periods.splice(3);
    }

    return (
      <>
        {periods.map(renderPeriod)}
        {expandable && (
          <Button
            clear
            disableElevation
            disableRipple
            onClick={() => setExpanded((prevState) => !prevState)}
            variant={'text'}
          >
            <Typography underline color={'link600'}>
              {expanded ? translate('LESS') : `${translate('MORE')} ${collapsed.length}`}
            </Typography>
          </Button>
        )}
      </>
    );
  };

  if (!item?.status || item.status === IncomingNumberStatusType.Reserved) {
    return (
      <div className={classes.row}>
        <Flex justifyContent={'center'} className={classes.reservedNumberIcon}>
          <AlertCircleIcon className={classes.reservedNumberAlertIcon} />
          <Typography type={'text3'} className={classes.reservedNumberWarning}>
            {translate('NUMBER_AVAILABLE_AFTER_BUY')}
          </Typography>
        </Flex>
      </div>
    );
  }

  return <div>{renderSchedulePeriods()}</div>;
};
