import React, { FunctionComponent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@shared/components/Button';
import Typography from '@shared/components/Typography';
import SelectField from '@shared/components/SelectField';
import Preloader from '@shared/components/Preloader';
import MessageDialog from '@shared/components/MessageDialog';
import ControlButtons from '@shared/components/ControlButtons';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FilterIcon,
  EqualityIcon,
  NotEqualityIcon,
  InstructionIcon,
  AlertCircleIcon,
} from '@shared/assets/images/icons';
import Flex from '@shared/components/Flex';
import { useLazyQuery } from '@apollo/client';
import { GET_RETAIL_SITES_QUERY, INCOMING_NUMBERS_QUERY } from '@/client/queries';
import { useRoutes } from '@components/Routes';
import { formatPhone } from '@components/utils';
import { normalizePhone } from '@shared/utils';
import FormFieldBase from '@shared/components/FormFieldBase';
import CheckboxField from '@shared/components/CheckboxField';
import {
  ALL_EMPLOYEE_FILTERS_ON,
  compareEmployeeFilterList,
  getComparedEmployeeIndex,
} from '../../IntegrationForm.constants';
import {
  crmEmployeeListType,
  compareStatus,
  IEmployeeCompareOptions,
  IOptionData,
  ICRMIntegrationSitesProps,
  comparedSitesListType,
} from '../../IntegrationForm.interfaces';
import { useIntegrationFormStyles } from '../../IntegrationForm.styles';

export const CRMSites: FunctionComponent<ICRMIntegrationSitesProps> = ({
  selectedNumbers,
  rawComparedSites,
  onChange,
}) => {
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const formMethods = useForm();
  const { watch, setValue } = formMethods;
  const selectedPhonesValues = watch('phones');
  const selectAll = watch('selectAll');
  const isCompared = watch(compareStatus.Compared);
  const isNotCompared = watch(compareStatus.NotCompare);
  const isNotSelected = watch(compareStatus.NotSelected);
  const {
    path: { identifier },
  } = useRoutes();
  const filterOption = useMemo(
    () => [isCompared, isNotSelected, isNotCompared],
    [isCompared, isNotCompared, isNotSelected]
  );
  const [filterOptions, setFilterOptions] = useState<IEmployeeCompareOptions>({
    isOpen: false,
    filter: ALL_EMPLOYEE_FILTERS_ON,
  });

  // TODO handle lazy query error
  const [getPhoneList, { data: phonesList }] = useLazyQuery(INCOMING_NUMBERS_QUERY);
  // TODO handle lazy query error
  const [getSitesList, { data: sitesList, error: integrationSitesError }] = useLazyQuery(
    GET_RETAIL_SITES_QUERY,
    {
      variables: {
        data: {
          id: Number(identifier),
        },
      },
    }
  );
  const integrationSitesList = sitesList?.getRetailCrmSitesList || [];

  const integrationPhonesList = useMemo(() => {
    if (phonesList) {
      const selectedPhoneList = Object.values(selectedNumbers);
      const phonesSource = selectedPhoneList.length
        ? selectedPhoneList
        : phonesList?.boughtDomainNumbers;
      const list =
        phonesSource.map((i: IOptionData) => ({ name: formatPhone(i.phone), id: i.id })) || [];
      return [{ name: translate('DO_NOT_COMPARE'), id: '-1' }, ...list];
    }
    return null;
  }, [phonesList, translate, selectedNumbers]);

  const updateIntegrationSiteList = useCallback(() => {
    getPhoneList();
    getSitesList();
  }, [getPhoneList, getSitesList]);

  useEffect(() => {
    if (filterOptions.isOpen) {
      setTimeout(() => {
        filterOptions.filter.forEach((filter) => {
          if (filter !== '') {
            setValue(filter, true);
          }
        });
      });
    }
  }, [filterOptions, setValue]);

  useEffect(() => {
    if (filterOption) {
      let isAllSelected = true;
      filterOption.forEach((filter: boolean) => {
        if (!filter) {
          isAllSelected = false;
        }
      });
      if (isAllSelected && !selectAll) {
        setValue('selectAll', true);
      }
      if (!isAllSelected && selectAll) {
        setValue('selectAll', false);
      }
    }
  }, [filterOption, setValue, selectAll]);

  useEffect(() => {
    if (!sitesList) {
      updateIntegrationSiteList();
    }
  }, [sitesList, updateIntegrationSiteList]);

  const handlePhonesChange = useCallback(
    (e, i) => {
      const selectedPhone = e.target.value;
      if (onChange && selectedPhonesValues && integrationSitesList && integrationPhonesList) {
        const updatedPhonesList = [...selectedPhonesValues];
        updatedPhonesList[i] = selectedPhone;
        const comparedSitesList = updatedPhonesList.reduce(
          (result: comparedSitesListType, ci: string, ind: number) => {
            if (ci === '') {
              return result;
            }
            const { name, id } =
              integrationPhonesList.find((emp) => Number(emp.id) === Number(ci)) || {};
            if (!id) {
              return result;
            }
            if (id === '-1') {
              const prevNotMatch = (result.notmatch as Array<string>) || [];
              return {
                ...result,
                notmatch: [...prevNotMatch, integrationSitesList[ind].id],
              };
            }
            return {
              ...result,
              [id]: {
                id: ci,
                crmId: integrationSitesList[ind].id,
                siteName: integrationSitesList[ind].code,
                name,
              },
            };
          },
          {}
        );
        onChange(comparedSitesList, integrationSitesList);
      }
    },
    [selectedPhonesValues, integrationSitesList, onChange, integrationPhonesList, translate]
  );

  useEffect(() => {
    if (!integrationSitesList.length || !phonesList) {
      return;
    }
    if (!selectedPhonesValues) {
      if (rawComparedSites) {
        setTimeout(() => {
          const aicallPhones = Object.keys(rawComparedSites);
          const integrationSites = Object.values(rawComparedSites);
          const comparedValues: Array<string | number> = [];
          let comparedSitesList = { notmatch: [] };
          integrationSites.forEach((integrationSite, index) => {
            if (Array.isArray(integrationSite)) {
              comparedSitesList.notmatch = integrationSite;
              integrationSite.forEach((rawPhone) => {
                const emIndex = getComparedEmployeeIndex(integrationSitesList, rawPhone);
                comparedValues[emIndex] = '-1';
              });
            } else {
              const siteObject = integrationSitesList.find((s) => s.code === integrationSite) || {};
              const emIndex = getComparedEmployeeIndex(integrationSitesList, siteObject.id);
              const { id: emValue, phone } =
                phonesList?.boughtDomainNumbers.find(
                  (s) => s.phone === normalizePhone(aicallPhones[index])
                ) || {};
              comparedValues[emIndex] = emValue || '';
              comparedSitesList = {
                ...comparedSitesList,
                [emValue]: {
                  id: emValue,
                  crmId: siteObject.id,
                  siteName: siteObject.code,
                  name: phone,
                },
              };
            }
          });
          setValue('phones', comparedValues);
          onChange?.(comparedSitesList, integrationSitesList);
        });
      }
    }
  }, [
    rawComparedSites,
    setValue,
    integrationSitesList,
    integrationPhonesList,
    selectedPhonesValues,
  ]);

  const handleConfirmFilterOptions = useCallback(() => {
    setFilterOptions({
      isOpen: false,
      filter: !selectAll
        ? filterOption.map((filter: boolean, index: number) =>
            filter ? compareEmployeeFilterList[index].value : ''
          )
        : ALL_EMPLOYEE_FILTERS_ON,
    });
  }, [filterOption, selectAll]);

  function handleFilterCheckAll() {
    setValue(compareStatus.Compared, !selectAll);
    setValue(compareStatus.NotCompare, !selectAll);
    setValue(compareStatus.NotSelected, !selectAll);
  }

  function handleOpenFilter() {
    setFilterOptions((prevState) => ({
      ...prevState,
      isOpen: true,
    }));
  }

  function handleCloseFilter() {
    setFilterOptions((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }

  const renderEmployeeHeader = () => {
    if (integrationSitesList && integrationPhonesList) {
      return (
        <Flex justifyContent={'spaceBetween'} alignItems={'center'}>
          <div className={classes.CRMSiteList}>
            <Typography bold color={'tertiary900'} type={'text3'}>
              {translate('SITE_NAME')}
            </Typography>
          </div>
          <Button
            onClick={() => handleOpenFilter()}
            className={classes.CRMEmployeeFilterButton}
            clear
          >
            <FilterIcon />
          </Button>
          <Flex justifyContent={'spaceBetween'} className={classes.CRMSiteList}>
            <Typography bold color={'tertiary900'} type={'text3'}>
              {translate('PBX_NAME')}
            </Typography>
          </Flex>
        </Flex>
      );
    }
    return null;
  };

  const renderComparisonList = () => {
    if (integrationSitesList && integrationPhonesList) {
      const getStatus = (valueIndex: number) => {
        if (selectedPhonesValues) {
          if (selectedPhonesValues[valueIndex] === '-1') {
            return compareStatus.NotCompare;
          }
          if (
            selectedPhonesValues[valueIndex] !== '' &&
            selectedPhonesValues[valueIndex] !== undefined
          ) {
            return compareStatus.Compared;
          }
        }
        return compareStatus.NotSelected;
      };
      const equalIcon = (status: string) => {
        if (selectedPhonesValues) {
          if (status === compareStatus.NotCompare) {
            return <NotEqualityIcon className={classes.CRMEmployeeEqualIcon} />;
          }
          if (status === compareStatus.Compared) {
            return <EqualityIcon className={classes.CRMEmployeeEqualIcon} />;
          }
        }
        return <InstructionIcon className={classes.CRMEmployeeInstructionIcon} />;
      };

      return integrationSitesList.map((item: crmEmployeeListType, index: number) => {
        const { name: employeeName } = item || {};
        const status = getStatus(index);
        const isItemFiltered = filterOptions.filter.includes(status);

        return (
          <Flex
            className={!isItemFiltered ? classes.CRMEmployeeListHide : ''}
            key={`site-${index}`}
            alignItems={'center'}
            justifyContent={'spaceBetween'}
          >
            <div className={classes.CRMSiteList}>
              <FormFieldBase
                name={`sites[${index}]`}
                InputProps={{ readOnly: true }}
                value={employeeName}
              />
            </div>
            <div className={classes.CRMEmployeeIcon}>{equalIcon(status)}</div>
            <div className={classes.CRMSiteList}>
              <SelectField
                name={`phones[${index}]`}
                valueKey={'id'}
                titleKey={'name'}
                defaultValue={''}
                onChange={(e) => handlePhonesChange(e, index)}
                disabledOptions={
                  selectedPhonesValues
                    ? selectedPhonesValues.filter((i: string | number) => i !== '-1' && i !== '')
                    : []
                }
                data={integrationPhonesList}
              />
            </div>
          </Flex>
        );
      });
    }
    if (integrationSitesError) {
      const message = 'SOMETHING_WENT_WRONG';

      return (
        <Flex justifyContent={'center'} alignItems={'center'} className={classes.CRMEmployeeError}>
          <AlertCircleIcon className={classes.CRMEmployeeAlertIcon} />
          <Typography type={'text3'} color={'inherit'}>
            {translate(message)}
          </Typography>
        </Flex>
      );
    }
    return (
      <Flex justifyContent={'center'}>
        <Preloader />
      </Flex>
    );
  };

  const renderFilterContent = () => (
    <>
      <div className={classes.integrationSettingFilter}>
        <CheckboxField
          indeterminate={
            filterOption && filterOption.filter((f: boolean) => f).length !== 0 && !selectAll
          }
          name={'selectAll'}
          label={translate('SELECT_ALL')}
          onChange={handleFilterCheckAll}
        />
      </div>
      {compareEmployeeFilterList.map((filter, filterIndex) => (
        <div key={`filter-${filterIndex}`} className={classes.integrationSettingFilter}>
          <CheckboxField name={filter.value} label={translate(filter.title)} />
        </div>
      ))}
      <div className={classes.integrationSettingFilterButtons}>
        <ControlButtons
          confirmTitle={'APPLY'}
          cancelTitle={'CANCEL'}
          cancelVariant="secondary"
          justifyContent={'start'}
          flexDirection={'row-reverse'}
          onConfirmClick={handleConfirmFilterOptions}
          onCancelClick={handleCloseFilter}
          small
        />
      </div>
    </>
  );

  return (
    <FormProvider {...formMethods}>
      <div>
        {renderEmployeeHeader()}
        {renderComparisonList()}
      </div>
      <MessageDialog
        isOpen={filterOptions.isOpen}
        contentClass={classes.defaultElementWidth21}
        title={translate('FILTER_BY')}
        onCancel={() => handleCloseFilter()}
        renderContent={renderFilterContent()}
      />
    </FormProvider>
  );
};

export default memo(CRMSites);
