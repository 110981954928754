import React, { FunctionComponent } from 'react';
import { LogoBlackIcon } from '@shared/assets/images/icons/logos';
import { ICellProps } from '@components/LazyTable';
import { IIncomingNumber } from '../IncomingNumbers.interfaces';
import { useIncomingNumbersStyles } from '../IncomingNumbers.styles';

export const IncomingNumberTypeCell: FunctionComponent<ICellProps<IIncomingNumber>> = ({
  item,
}) => {
  const { external } = item || {};
  const classes = useIncomingNumbersStyles();
  const Icon = external ? LogoBlackIcon : LogoBlackIcon;
  return (
    <div className={classes.numberTypeBody}>
      <Icon className={classes.icon} width={16} height={16} />
    </div>
  );
};
