import React, { useMemo, useState } from 'react';
import Typography from '@shared/components/Typography';
import Button from '@shared/components/Button';
import { useTranslation } from 'react-i18next';
import { GET_INTEGRATIONS_QUERY, GET_SELECTED_TARIFF_QUERY } from '@/client/queries';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { isFeatureAvailable, useDomainFeatures } from '@/common/hooks';
import Flex from '@shared/components/Flex';
import Preloader from '@shared/components/Preloader';
import BodyContainer from '@/layouts/BodyContainer';
import { DemoDomainStatuses, DomainStatus } from '@components/typings/interfaces';
import MessageDialog from '@shared/components/MessageDialog';
import {
  IntegrationList,
  IntegrationCategory,
  IntegrationStatus,
} from '@components/typings/interfaces/integrations';
import {
  NewApiIcon as OtherIntegrationsIcon,
  OldApiIcon,
} from '@shared/assets/images/icons/integrations';

import { setStringMaxLength } from '@components/utils';
import clsx from 'clsx';
import { useRoutes } from '@components/Routes';
import { DomainIntegrationModel, OptionType } from '@/client/generated/graphql';
import { TextColorsTypes } from '@shared/components/Typography/Typography.interfaces';
import { useIntegrationsStyles } from './Integrations.styles';
import {
  FREE_INTEGRATION_LIST,
  integrationsCards,
  integrationsCardsFilteredCards,
} from './Integrations.constants';
import { IIntegrationCard } from './Integrations.interfaces';

export const Integrations = () => {
  const classes = useIntegrationsStyles();
  const navigate = useNavigate();
  const [translate] = useTranslation();

  const {
    path: { subcategory },
  } = useRoutes();

  const { data: integrationListData, loading: loadingIntegrations } =
    useQuery(GET_INTEGRATIONS_QUERY);
  const { data: selectedTariff } = useQuery(GET_SELECTED_TARIFF_QUERY, {
    fetchPolicy: 'cache-first',
  });
  const { status: domainStatus = DomainStatus.Unknown } = selectedTariff?.getDomain || {};
  const integrationsList = integrationListData?.getIntegrations;
  const [isIntegrationDialogOpen, setIntegrationDialogOpen] = useState(false);

  const paidIntegrationAmount = useMemo(() => {
    if (!integrationsList?.length) return 0;
    return integrationsList.filter(
      (item) => !FREE_INTEGRATION_LIST.includes(item.type as IntegrationList)
    )?.length;
  }, [integrationsList]);

  const sortedIntegrationsList = useMemo(() => {
    if (!integrationsList || !integrationsList.length) return [];
    return integrationsList.reduce(
      (res, integration) => {
        const integrationInfo = integrationsCards.find((item) => item.type === integration.type);
        const getIndex = () => {
          if (integrationInfo?.category === IntegrationCategory.CRM) return 0;
          if (integrationInfo?.category === IntegrationCategory.CALLTRACKING) return 1;
          return 2;
        };
        res[getIndex()].list.push(integration);
        return res;
      },
      [
        {
          title: 'CRM',
          list: [] as DomainIntegrationModel[],
        },
        {
          title: 'CALLTRACKING_TITLE',
          list: [] as DomainIntegrationModel[],
        },
        {
          title: 'EXTERNAL_SERVICES',
          list: [] as DomainIntegrationModel[],
        },
      ]
    );
  }, [integrationsList]);

  const integrationsMaxAmount = useMemo(() => {
    const { tariff } = selectedTariff?.getDomain || {};
    if (!tariff) return 0;
    const baseAccounts = tariff.options.find((opt) => opt.type === OptionType.Accounts);
    const additionalAccounts = tariff.options.find(
      (opt) => opt.type === OptionType.AdditionalAccounts
    );
    const integrationsOption = tariff.options.find((opt) => opt.type === OptionType.Integrations);
    if (!integrationsOption) return 0;
    const isPerEmployee = integrationsOption?.params?.perEmployee || false;
    const integrationDomainCount = integrationsOption?.domainTariffOptionParams?.count || 1;
    const baseAccountsAmount = baseAccounts?.domainTariffOptionParams?.count || 0;
    const additionalAccountsAmount = additionalAccounts?.domainTariffOptionParams?.count || 0;
    const accountAmount = baseAccountsAmount + additionalAccountsAmount;

    const { tariffChangeRequest } = selectedTariff?.getDomain || {};
    if (tariffChangeRequest) {
      const optionInRequest = tariffChangeRequest.options.find(
        (el) => el.tariffOptionId === integrationsOption.id
      );
      if (!optionInRequest) return 0;
      const requestAccounts = tariffChangeRequest.options.reduce((res, opt) => {
        if (
          opt.tariffOptionId === baseAccounts?.id ||
          opt.tariffOptionId === additionalAccounts?.id
        ) {
          return res + (opt.count || 0);
        }
        return res;
      }, 0);

      return (optionInRequest.count || 0) / requestAccounts;
    }
    return isPerEmployee ? integrationDomainCount / accountAmount : integrationDomainCount;
  }, [selectedTariff]);

  function handleInstallClick(type: IntegrationList) {
    const isIntegrationPaid = integrationsCards.find((item) => item.type === type)?.paid || false;
    if (paidIntegrationAmount >= integrationsMaxAmount && isIntegrationPaid) {
      setIntegrationDialogOpen(true);
    } else {
      navigate(`/integrations/preview-integration/${type}`);
    }
  }

  function handleCloseDialog() {
    setIntegrationDialogOpen(false);
  }

  const { features } = useDomainFeatures();

  const renderAvailableIntegrationsCount = () => {
    if (integrationsMaxAmount === 0) {
      return (
        <>
          <Typography type={'text2'} color={'tertiary900'} className={classes.cardsSectionTitle}>
            {translate('UNAVAILABLE_INTEGRATIONS')}
          </Typography>
          {DemoDomainStatuses.includes(domainStatus) ? (
            <Typography
              type={'text3'}
              color={'tertiary900'}
              className={classes.cardsSectionDescription}
            >
              {translate('UNAVAILABLE_INTEGRATIONS_DEMO')}
            </Typography>
          ) : (
            <Typography
              type={'text3'}
              color={'tertiary900'}
              className={classes.cardsSectionDescription}
            >
              {translate('UNAVAILABLE_INTEGRATIONS_COMMERCIAL')}
            </Typography>
          )}
        </>
      );
    }
    return (
      <Typography type={'text2'} color={'tertiary900'} className={classes.cardsSectionTitle}>
        {translate('AVAILABLE_INTEGRATIONS_TO_INSTALL', {
          amount: integrationsMaxAmount,
          ending:
            integrationsMaxAmount === 1
              ? translate('INTEGRATIONS').toLowerCase()
              : translate('LOT_OF_INTEGRATIONS').toLowerCase(),
        })}
      </Typography>
    );
  };

  const renderIntegrationCard = (
    { name, type, status, id }: DomainIntegrationModel,
    index: number
  ) => {
    const { paid, LogoMedium, titleCode } =
      integrationsCards.find((item) => item.type === type) || {};
    const Logo = LogoMedium || OtherIntegrationsIcon;

    const setStatusName = () => {
      if (status === IntegrationStatus.Active) {
        return {
          status: 'WORKED',
          color: 'success600',
        };
      }
      if (status === IntegrationStatus.Draft) {
        return {
          status: 'DRAFT',
          color: 'warning600',
        };
      }
      return {
        status: 'TURNED_OFF',
        color: 'danger600',
      };
    };

    return (
      <Flex
        key={`availableIntegrations-${index}`}
        justifyContent={'spaceBetween'}
        alignItems={'center'}
        className={classes.integrationCard}
      >
        <Flex
          justifyContent={'flexStart'}
          alignItems={'center'}
          className={classes.integrationCardLogoSection}
        >
          <div className={classes.integrationCardLogo}>
            <Logo />
          </div>
          <Flex direction={'column'} className={classes.integrationCardTitle}>
            <Typography
              type={'text3'}
              color={'tertiary900'}
              className={classes.cardsSectionTitleHigh}
              bold
            >
              {translate(titleCode || '')}
            </Typography>
            <Typography type={'text5'} color={paid ? 'link600' : 'success600'} medium>
              {translate(paid ? 'PAID' : 'FOR_FREE')}
            </Typography>
          </Flex>
        </Flex>
        <Flex className={classes.integrationCardName}>
          <Typography type={'text3'} color={'tertiary600'}>
            {name || ''}
          </Typography>
        </Flex>
        <Flex
          alignItems={'center'}
          justifyContent={'flexEnd'}
          className={classes.integrationActionsSection}
        >
          <Typography type={'text3'} color={setStatusName().color as TextColorsTypes}>
            {translate(setStatusName().status)}
          </Typography>
          <Button
            title={translate('SETTINGS')}
            onClick={() => navigate(`/integrations/edit-integration/${id}`)}
            variant={'secondary'}
            className={classes.integrationCardButton}
          />
        </Flex>
      </Flex>
    );
  };

  const renderCard = (
    { type, titleCode, descriptionCode, LogoMedium, paid }: IIntegrationCard,
    index: number
  ) => (
    <Flex
      key={`installedIntegrations-${index}`}
      direction={'column'}
      className={classes.card}
      onClick={() => handleInstallClick(type)}
    >
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        className={clsx(classes.cardPaidStatus, {
          [classes.cardPaidStatusPaid]: paid,
          [classes.cardPaidStatusFree]: !paid,
        })}
      >
        <Typography type={'text5'} color={paid ? 'link600' : 'success600'} medium>
          {translate(paid ? 'PAID' : 'FOR_FREE')}
        </Typography>
      </Flex>
      <Flex className={classes.cardTitleBlock} alignItems={'center'}>
        <div className={classes.cardLogo}>{LogoMedium ? <LogoMedium /> : <OldApiIcon />}</div>
        <div className={classes.cardTitle}>
          <Typography type={'text3'} color={'tertiary900'} bold>
            {translate(titleCode)}
          </Typography>
        </div>
      </Flex>
      <Flex className={classes.cardDescription}>
        <Typography type={'text4'} color={'tertiary900'}>
          {setStringMaxLength(translate(descriptionCode), 75)}
        </Typography>
      </Flex>
    </Flex>
  );

  return (
    <BodyContainer>
      <Flex direction={'column'} className={classes.content}>
        {loadingIntegrations ? (
          <div className={classes.integrationsListPreloader}>
            <Preloader />
          </div>
        ) : (
          integrationsCardsFilteredCards.length !== 0 && (
            <>
              <Flex
                className={classes.integrationTabs}
                alignItems={'center'}
                justifyContent={'spaceBetween'}
              >
                <div>
                  {integrationsList?.length !== 0 ? (
                    <Button onClick={() => navigate('/integrations')} clear>
                      <div
                        className={clsx(classes.integrationTab, {
                          [classes.integrationTabActive]: !subcategory,
                        })}
                      >
                        <Typography type={'text2'} color={'tertiary900'}>
                          {translate('INSTALLED_INTEGRATION')}
                        </Typography>
                      </div>
                    </Button>
                  ) : null}
                  <Button onClick={() => navigate('/integrations/available')} clear>
                    <div
                      className={clsx(classes.integrationTab, {
                        [classes.integrationTabActive]:
                          subcategory === 'available' ||
                          (!subcategory && !integrationsList?.length),
                      })}
                    >
                      <Typography type={'text2'} color={'tertiary900'}>
                        {translate('AVAILABLE_INTEGRATIONS')}
                      </Typography>
                    </div>
                  </Button>
                </div>
                <div>
                  {!subcategory ? (
                    <Typography
                      type={'text3'}
                      color={'tertiary600'}
                      className={classes.cardsSectionTitle}
                    >
                      {translate('INSTALLED_INTEGRATIONS', {
                        amount: paidIntegrationAmount,
                        max: integrationsMaxAmount,
                      })}
                    </Typography>
                  ) : null}
                  {/* //TODO: Возможно он еще понадобиться */}
                  {false && renderAvailableIntegrationsCount()}
                </div>
              </Flex>
              {!subcategory && (loadingIntegrations || integrationsList?.length !== 0) ? (
                <>
                  {sortedIntegrationsList.map((integration, index) => {
                    return (
                      <div key={`${integration.title}-1-${index}`}>
                        {integration.list.length ? (
                          <>
                            <div className={classes.cardsSectionCategory}>
                              <Typography type={'text2'} color={'tertiary900'} medium>
                                {translate(integration.title)}
                              </Typography>
                            </div>
                            <Flex className={classes.cardsSection}>
                              {integration.list.map(renderIntegrationCard)}
                            </Flex>
                          </>
                        ) : null}
                      </div>
                    );
                  })}
                </>
              ) : null}
              {subcategory === 'available' ||
              (subcategory !== 'available' && !integrationsList?.length) ? (
                <>
                  {integrationsCardsFilteredCards.map((integration, index) => {
                    return (
                      <div key={`${integration.title}-${index}`}>
                        <div className={classes.cardsSectionCategory}>
                          <Typography type={'text2'} color={'tertiary900'} medium>
                            {translate(integration.title)}
                          </Typography>
                        </div>
                        <Flex className={classes.cardsSection}>
                          {integration.list
                            .filter((i) => i.visible && isFeatureAvailable(i.feature, features))
                            .map(renderCard)}
                        </Flex>
                      </div>
                    );
                  })}
                </>
              ) : null}
            </>
          )
        )}
      </Flex>
      <MessageDialog
        isOpen={isIntegrationDialogOpen}
        contentClass={classes.integrationDialog}
        title={translate('UNABLE_TO_ADD')}
        message={translate('UNABLE_TO_ADD_INTEGRATION')}
        onCancel={handleCloseDialog}
        renderControls={
          <Button title={translate('CLOSE')} onClick={handleCloseDialog} smallHeight />
        }
      />
    </BodyContainer>
  );
};

export default Integrations;
