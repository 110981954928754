import { useState } from 'react';
import { IScenarioSettingState } from '@/features/Integrations/IntegrationForm/IntegrationForm.interfaces';

export function useIntegrationDialogs() {
  const [isConfirmStatusSwitchShow, setConfirmStatusSwitchShow] = useState(false);
  const [isResponsibleSettingError, setResponsibleSettingError] = useState(false);
  const [isBackCallDialogShow, setBackCallDialogShow] = useState(false);
  const [confirmDeleteIntegrationDialog, setConfirmDeleteIntegrationDialog] = useState(false);
  const [employeesError, setEmployeesError] = useState(false);
  const [callsDisabledDialogShow, setCallsDisabledDialogShow] = useState(false);
  const [scenarioSettingDialog, setScenarioSettingDialog] = useState<IScenarioSettingState>({
    isOpen: false,
    scenarioType: null,
    data: null,
  });
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    title: '',
    message: '',
  });
  const [deleteScenarioDialog, setDeleteScenarioDialog] = useState({
    isOpen: false,
    name: '',
    deletedIndex: 0,
  });

  return {
    deleteScenarioDialog,
    setDeleteScenarioDialog,
    isConfirmStatusSwitchShow,
    setConfirmStatusSwitchShow,
    isResponsibleSettingError,
    setResponsibleSettingError,
    isBackCallDialogShow,
    setBackCallDialogShow,
    confirmDeleteIntegrationDialog,
    setConfirmDeleteIntegrationDialog,
    employeesError,
    setEmployeesError,
    scenarioSettingDialog,
    setScenarioSettingDialog,
    dialogState,
    setDialogState,
    callsDisabledDialogShow,
    setCallsDisabledDialogShow,
  };
}
