import { gql } from '@apollo/client';

export const SIGNIN = gql`
  mutation Signin($domain: String, $email: String, $password: String!) {
    signin(user: { domain: $domain, email: $email, password: $password }) {
      user {
        id
        email
        domains {
          id
          domain
          current
        }
      }
      token
      refreshToken
    }
  }
`;

export const SIGNOUT = gql`
  mutation Signout {
    signout {
      message
    }
  }
`;

export const PARTNERS_REFRESH_TOKEN_MUTATION = gql`
  mutation PartnersRefreshToken {
    partnersRefreshToken {
      accessToken
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshToken {
      user {
        id
      }
      refreshToken
      token
    }
  }
`;

export const GET_CONTRACT = gql`
  mutation GetContract {
    getContract
  }
`;
