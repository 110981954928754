import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FilterIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import {
  IOutgoingCall,
  IOutgoingCallsFilterParams,
  OutgoingCallEntity,
} from '@components/typings/interfaces';
import CheckboxField from '@shared/components/CheckboxField';
import { useOutgoingCallsStyles } from '@/features/OutgoingCalls/OutgoingCalls.styles';
import ControlButtons from '@shared/components/ControlButtons';
import MessageDialog from '@shared/components/MessageDialog';
import { ICellProps } from '@components/LazyTable';

export const OutgoingCallTypeHeaderCell: FunctionComponent<ICellProps<IOutgoingCall>> = (
  { column },
  disabled: boolean
) => {
  const classes = useOutgoingCallsStyles();
  const [translate] = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const entityFilter = searchParams.getAll('filter');
  const computedClass = clsx(classes.headFilterIcon, {
    [classes.primary]: entityFilter.length && !entityFilter.includes('all'),
  });

  const formMethods = useForm<IOutgoingCallsFilterParams>({
    defaultValues: {
      selectAll: !entityFilter?.length || entityFilter.includes('all'),
      department: entityFilter.includes('department'),
      employee: entityFilter.includes('employee'),
    },
  });
  const { reset, handleSubmit, watch, setValue } = formMethods;
  const departmentChecked = watch('department');
  const employeeChecked = watch('employee');

  const resetForm = useCallback(() => {
    reset({
      selectAll: true,
      department: true,
      employee: true,
    });
  }, [reset]);

  const handleFilterClose = () => {
    setOpen(false);
  };

  const handleFilterClick = () => {
    if (disabled) return;
    setOpen(true);
    if (entityFilter) {
      const formParams: IOutgoingCallsFilterParams = {
        selectAll: false,
        department: false,
        employee: false,
      };
      entityFilter.forEach((el: string) => {
        formParams[el as keyof IOutgoingCallsFilterParams] = true;
      });
      reset(formParams);
    }
  };

  const handleSubmitForm = useCallback(
    ({ department, employee, selectAll }: IOutgoingCallsFilterParams) => {
      searchParams.delete('filter');
      if (selectAll) {
        searchParams.set('filter', 'all');
      } else {
        if (department) {
          searchParams.append('filter', OutgoingCallEntity.Department);
        }
        if (employee) {
          searchParams.append('filter', OutgoingCallEntity.Employee);
        }
      }
      setSearchParams(searchParams);
      setOpen(false);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if ((!departmentChecked && !employeeChecked) || (departmentChecked && employeeChecked)) {
      resetForm();
    } else {
      setValue('selectAll', false);
    }
  }, [employeeChecked, departmentChecked, resetForm, setValue]);

  const renderIcon = () => (
    <FilterIcon
      width={16}
      height={16}
      style={!disabled ? { cursor: 'pointer' } : {}}
      onClick={() => handleFilterClick()}
    />
  );

  return (
    <div className={computedClass}>
      {renderIcon()}
      <MessageDialog
        onCancel={handleFilterClose}
        isOpen={open}
        contentClass={classes.defaultElementWidth21}
        title={translate('FILTER_BY_TYPE')}
        renderContent={
          <FormProvider {...formMethods}>
            <form
              id={'outgoing-calls-filter'}
              action={''}
              onSubmit={handleSubmit(handleSubmitForm)}
              className={classes.form}
            >
              <CheckboxField
                className={classes.checkboxControl}
                name={'selectAll'}
                label={translate('SELECT_ALL')}
                onClick={resetForm}
              />
              <CheckboxField
                className={classes.checkboxControl}
                name={'department'}
                label={translate('DEPARTMENTS')}
              />
              <CheckboxField
                className={classes.checkboxControl}
                name={'employee'}
                label={translate('EMPLOYEES')}
              />
              <ControlButtons
                confirmTitle={'APPLY'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                form={'outgoing-calls-filter'}
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                rootClass={classes.dialogFilterActions}
                onCancelClick={() => setOpen(false)}
                small
              />
            </form>
          </FormProvider>
        }
      />
    </div>
  );
};
