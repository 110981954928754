import React, { FunctionComponent, useCallback, useState } from 'react';
import Button from '@shared/components/Button';
import { useNavigate } from 'react-router-dom';
import { EditIcon, TrashIcon } from '@shared/assets/images/icons';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import MessageDialog from '@shared/components/MessageDialog';
import { useMutation } from '@apollo/client';
import { ACTIVATE_BUSINESS_CARD_MUTATION, DELETE_BUSINESS_CARD_MUTATION } from '@/client/mutations';
import { GET_BUSINESS_CARDS_QUERY } from '@/client/queries';
import SwitchField from '@shared/components/SwitchField';
import { FormProvider, useForm } from 'react-hook-form';
import ControlButtons from '@shared/components/ControlButtons';
import { ICellProps } from '@components/LazyTable';
import { IBusinessCard } from '../SettingsBusinessCards.interface';
import { useBusinessCardsStyles } from '../SettingsBusinessCards.styles';

export const BusinessCardsActionsCell: FunctionComponent<ICellProps<IBusinessCard>> = ({
  item,
}) => {
  const { active, id } = item || {};
  const navigate = useNavigate();
  const classes = useBusinessCardsStyles();
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [translate] = useTranslation();
  const formMethods = useForm<Partial<IBusinessCard>>({
    defaultValues: {
      active: Boolean(active),
    },
  });

  const [activateBusinessCard] = useMutation(ACTIVATE_BUSINESS_CARD_MUTATION, {
    refetchQueries: [{ query: GET_BUSINESS_CARDS_QUERY }],
  });
  const [removeBusinessCard, { loading: loadingRemoveBC }] = useMutation(
    DELETE_BUSINESS_CARD_MUTATION,
    {
      refetchQueries: [{ query: GET_BUSINESS_CARDS_QUERY }],
    }
  );

  const handleActivateBusinessCard = useCallback(
    (checked: boolean) => {
      activateBusinessCard({
        variables: {
          data: {
            businessCardSettingId: Number(item?.id),
            activation: checked,
          },
        },
      });
    },
    [activateBusinessCard, item?.id]
  );

  const handleRemoveBusinessCard = () => {
    removeBusinessCard({
      variables: {
        data: {
          businessCardSettingId: Number(item?.id),
        },
      },
    });
    setIsRemoveDialogOpen(false);
  };

  const handleClosePopupDialog = () => {
    setIsRemoveDialogOpen(false);
  };

  return (
    <FormProvider {...formMethods}>
      <form className={classes.actionsFormWrapper} id={'sms-actions'}>
        <div className={classes.actionsContainer}>
          <SwitchField
            name={'active'}
            onChanges={(checked) => handleActivateBusinessCard(checked)}
          />
          <Button
            variant={'secondary'}
            className={classes.actionButton}
            onClick={() => navigate(`/business-cards/greetings/${id}`)}
          >
            <EditIcon />
          </Button>
          <Button
            className={classes.marginLeft1}
            variant={'secondary'}
            color={'error'}
            classes={{ root: classes.actionButton }}
            onClick={() => setIsRemoveDialogOpen(true)}
          >
            <TrashIcon />
          </Button>
        </div>
        <MessageDialog
          contentClass={classes.removeNumberDialog}
          isOpen={isRemoveDialogOpen}
          title={translate('ARE_YOU_SURE')}
          onCancel={handleClosePopupDialog}
          renderContent={
            <>
              <div className={classes.removePopupText}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('SMS_REMOVE_DESCRIPTION')}
                </Typography>
              </div>
              <ControlButtons
                confirmColor={'error'}
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                cancelVariant="secondary"
                confirmTitle={'REMOVE'}
                cancelTitle={'CANCEL'}
                onConfirmClick={() => handleRemoveBusinessCard()}
                onCancelClick={handleClosePopupDialog}
                loading={loadingRemoveBC}
                small
              />
            </>
          }
        />
      </form>
    </FormProvider>
  );
};
