import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import Flex from '@shared/components/Flex';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import { useSearchParams } from 'react-router-dom';
import { IBusinessCard } from '../SettingsBusinessCards.interface';
import { useBusinessCardsStyles } from '../SettingsBusinessCards.styles';

export const BusinessCardsMessage: FunctionComponent<ICellProps<IBusinessCard>> = ({ item }) => {
  const { smsTemplate = '', addContacts, active } = item || {};
  const [translate] = useTranslation();
  const classes = useBusinessCardsStyles();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('search') || null;

  return (
    <Flex direction={'column'} className={classes.defaultElementWidthFull}>
      <HighlightedText
        type={'text'}
        text={smsTemplate}
        query={searchText}
        typographyColor={active ? 'tertiary900' : 'tertiary300'}
        whiteSpaceProp={'break-spaces'}
      />
      {addContacts && (
        <Typography type={'text3'} color={active ? 'tertiary900' : 'tertiary300'} bold>
          &lt;{translate('ADDED_CONTACTS')}&gt;
        </Typography>
      )}
    </Flex>
  );
};
