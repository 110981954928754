import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IDomainNumber } from '@components/typings/interfaces';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';

export const NumberTypeCell: FunctionComponent<ICellProps<IDomainNumber>> = ({ item }) => {
  const [translate] = useTranslation();

  if (!item) {
    return <div />;
  }

  return (
    <Typography type={'text3'} color={'tertiary900'}>
      {translate(`NUMBER_${item.category}`)}
    </Typography>
  );
};
